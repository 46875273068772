import { gql } from '@apollo/client';

export const GET_ACTS = gql`
  query ActsAdmin($filter: ListActsFilter, $sort: [ListActsSort!]) {
    actsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        category
        id
        description
        createdAt
        updatedAt
        issuePeriod
        pdfUrl
        region
        slug
        status
        title
        startDate
        endDate
      }
    }
  }
`;

export const GET_ACT = gql`
  query ActAdmin($where: ActIdWhereUniqueInput!) {
    actAdmin(where: $where) {
      category
      createdAt
      description
      id
      issuePeriod
      pdfUrl
      region
      slug
      status
      title
      startDate
      endDate
    }
  }
`;

export const ACT_PDF_SIGNED_URL = gql`
  query GetActPdfFileUploadSignedUrl($data: GetPdfFileUploadSignedUrlInput!) {
    getActPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
