import React, { useContext, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROLE_KEYS, ROUTES } from '../../common/constants';
import ImportBatch from './importBatch';
import ImportCSV from './importcsv';
import ImportJudgement from './ImportJudgement';

const ImportCSVWrapper = () => {
  const {
    state: { currentUser },
  } = useContext(AppContext);

  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);

  return (
    <Routes>
      <Route path={ROUTES.MAIN} exact element={<ImportCSV />} />
      <Route path={ROUTES.CREATE} exact element={<ImportBatch />} />
      {process.env.REACT_APP_ALLOW_IMPORT_JUDGEMENT === 'true' && !isStaff && (
        <Route
          path={ROUTES.IMPORT_JUDGEMENT}
          exact
          element={<ImportJudgement />}
        />
      )}
    </Routes>
  );
};
export default ImportCSVWrapper;
