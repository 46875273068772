import { useLocation, useNavigate, useParams } from 'react-router-dom';

// Custom hook for common routing operations
function useRouter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const navigateWithBack = (route) => {
    // eslint-disable-next-line no-undef
    if (window.history.length > 2) {
      navigate(-1);
    } else {
      navigate(route);
    }
  };

  return {
    location,
    params,
    navigate,
    navigateWithBack,
  };
}

export default useRouter;
