import {
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  SORT_OPTION,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import MergeDataModal from './components/MergeDataModal';
import { CREATE_LAWYER, UPDATE_LAWYER } from './graphql/mutations';
import GET_LAWYERS from './graphql/queries';

const { Title } = Typography;
const initialFormValue = {
  name: '',
  noSpaceName: '',
  slug: '',
  mergedNoSpaceNames: [],
};
const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  name: COMMON_QUERY_PARAMS.SORT_NAME,
  slug: COMMON_QUERY_PARAMS.SORT_SLUG,
};
function LawyersList() {
  const location = useLocation();
  const { category } = useParams();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const [form] = Form?.useForm();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const [isMergeData, setIsMergeData] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: {
        queryString: COMMON_QUERY_PARAMS.LIMIT,
        defaultValue: LIMIT,
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
        value: CATEGORIES_KEYS[category],
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const fetchData = async () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_LAWYERS,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      });
      setData(listData?.data?.lawyersAdmin?.data);
      setCount(listData?.data?.lawyersAdmin?.count);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setSearch(searchParamsValue?.toString());
  }, [location.search, category]);

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const handleTableChange = (paginationValue, filters, sorter) => {
    const pageSizeValue = paginationValue?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    const prepareSorter = {};
    Object?.keys(fieldToParams)?.forEach((item) => {
      sorterAry?.forEach((filterItem) => {
        if (filterItem?.columnKey && item === filterItem?.columnKey) {
          // eslint-disable-next-line no-nested-ternary
          prepareSorter[
            fieldToParams[filterItem?.columnKey]
            // eslint-disable-next-line no-nested-ternary
          ] = filterItem?.order
            ? filterItem?.order === 'descend'
              ? SORT_OPTION.DESC
              : SORT_OPTION.ASC
            : null;
        }
      });
      prepareSorter[fieldToParams?.[item]] =
        prepareSorter[fieldToParams?.[item]] ?? null;
    });

    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: paginationValue?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
      ...prepareSorter,
    });
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
    form?.resetFields();
  };

  const handleForm = async (values) => {
    setButtonLoader(true);
    const prepareValue = {
      ...values,
      mergedNoSpaceNames: values?.mergedNoSpaceNames?.map((item) => item.reg),
      category: CATEGORIES_KEYS[category],
    };
    try {
      if (formPopup?.id === 'new') {
        setQueryParams(null);
        await mongoClient?.mutate({
          mutation: CREATE_LAWYER,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
          },
        });
      } else {
        await mongoClient?.mutate({
          mutation: UPDATE_LAWYER,
          fetchPolicy: 'network-only',
          variables: {
            data: prepareValue,
            where: {
              id: formPopup?.id,
            },
          },
        });
      }
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
      setButtonLoader(false);
    }
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.LAWYERS}
          {count ? ` (${count})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            key={category}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />

          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            {UI_LABELS.MERGE_DATA}
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              setFormPopup({
                isOpen: true,
                id: 'new',
              });
              form.setFieldsValue(initialFormValue);
            }}
          >
            {UI_LABELS.ADD_LAWYER}
          </Button>
        </div>
      </div>
      <Table
        columns={[
          {
            title: UI_LABELS.NAME,
            width: '40%',
            key: 'name',
            dataIndex: 'name',
            sorter: { multiple: 1 },
            sortOrder: getSorter.sorters.name,
          },
          {
            title: UI_LABELS.SLUG,
            width: '40%',
            key: 'slug',
            dataIndex: 'slug',
            sorter: { multiple: 2 },
            sortOrder: getSorter.sorters.slug,
          },
        ]}
        rowKey={(record) => record?.id}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                // eslint-disable-next-line no-underscore-dangle
                id: record?._id,
              });

              form.setFieldsValue({
                name: record?.name,
                noSpaceName: record?.noSpaceName,
                slug: record?.slug,
                mergedNoSpaceNames: record?.mergedNoSpaceNames.length
                  ? record?.mergedNoSpaceNames?.map((item) => ({
                      reg: item,
                    }))
                  : [],
              });
            },
          };
        }}
        dataSource={data}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: count,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <Modal
        destroyOnClose
        title={`${
          formPopup?.id === 'new' ? UI_LABELS.ADD_LAWYER : UI_LABELS.EDIT_LAWYER
        }`}
        open={formPopup?.isOpen}
        onCancel={handleClose}
        width={650}
        footer={[
          <Button key="submit" onClick={handleClose}>
            {UI_LABELS.CANCEL}
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => form?.submit()}
          >
            {UI_LABELS.SAVE}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={form}
            onFinish={handleForm}
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
          >
            <Form.Item
              name="name"
              label="Lawyer Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter Lawyer Name',
                },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
              ]}
            >
              <Input placeholder="Enter Lawyer Name" />
            </Form.Item>
            <Form.Item name="noSpaceName" label="No Space Name">
              <Input placeholder="Enter No Space Name" />
            </Form.Item>
            <Form.Item name="slug" label="Slug">
              <Input placeholder="Enter Slug" />
            </Form.Item>
            <Form.Item label="Merged No Space Names">
              <Form.List
                name="mergedNoSpaceNames"
                label="Merged No Space Names"
              >
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        className="dynamic-cat-input"
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          key={key}
                          name={[name, 'reg']}
                          rules={[
                            {
                              required: true,
                              message: 'Please enter Space Name',
                            },
                            {
                              whitespace: true,
                              message: 'Invalid input',
                            },
                          ]}
                        >
                          <Input placeholder="Enter Space Name" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        {UI_LABELS.ADD_SPACE_NAME}
                      </Button>
                    </Form.Item>
                    <Form.ErrorList
                      className="category-error"
                      errors={errors}
                    />
                  </>
                )}
              </Form.List>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
          fetchData={fetchData}
          category={category}
        />
      )}
    </>
  );
}

export default LawyersList;
