import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  DatePicker,
  Radio,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  AI_CREDIT_PLANS,
  AI_PLAN_NAME,
  COMMON_QUERY_PARAMS,
  CONSULTATION_CALL_CATEGORY_FILTER,
  CONSULTATION_CALL_FILTER,
  dateTimeFormat,
  defaultDateFormat,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  LIMIT,
  MEETING_TYPE_FILTER,
  MODULES,
  NOTICE_BOARD_NAME,
  PAGE_SIZE_OPTIONS,
  PAYMENT_TAB_KEYS,
  PLAN_TYPE_FILTER,
  ROUTES,
  SORT_OPTION,
  SUBSCRIPTION_DOWNLOADABLE,
  TABS,
  TAX_CATEGORIES,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  disableFutureDate,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import {
  FETCH_CONSULTATION_CALL_PURCHASED_LIST,
  FETCH_PLAN_PURCHASED_LIST,
  FETCH_TEMPLATES_PURCHASED_LIST,
} from './graphql/queries';
import './payment.less';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
};

function PaymentList() {
  const { navigate, params } = useRouter();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());
  const { tab } = params;
  const getPlansFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      categories: {
        queryString: COMMON_QUERY_PARAMS.CATEGORIES,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.CATEGORIES] ?? null,
      },
      userId: { queryString: COMMON_QUERY_PARAMS.USER_ID, defaultValue: null },
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.START] ?? null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.END] ?? null,
      },
    },
    getQueryParams,
  );
  const getTemplatesFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      categoryId: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY_ID,
        defaultValue: null,
        value: '',
      },
      subCategoryId: {
        queryString: COMMON_QUERY_PARAMS.SUB_CATEGORY_ID,
        defaultValue: null,
        value: '',
      },
      userId: { queryString: COMMON_QUERY_PARAMS.USER_ID, defaultValue: null },
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.START] ?? null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.END] ?? null,
      },
    },
    getQueryParams,
  );

  const getConsultationCallFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
      },
      meetingType: {
        queryString: COMMON_QUERY_PARAMS.MEETING_TYPE,
        defaultValue: null,
      },
      type: {
        queryString: COMMON_QUERY_PARAMS.TYPE,
        defaultValue: null,
      },
      userId: { queryString: COMMON_QUERY_PARAMS.USER_ID, defaultValue: null },
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.START] ?? null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.END] ?? null,
      },
    },
    getQueryParams,
  );

  const getPlansSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const getTemplatesSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const getConsultationCallSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [fetchSubscriptionPurchasedList, { data: plansData }] = useLazyQuery(
    FETCH_PLAN_PURCHASED_LIST,
  );

  const [fetchTemplatePurchasedList, { data: templatesData }] = useLazyQuery(
    FETCH_TEMPLATES_PURCHASED_LIST,
  );

  const [
    fetchConsultationCallList,
    { data: consultationCallData },
  ] = useLazyQuery(FETCH_CONSULTATION_CALL_PURCHASED_LIST);

  let totalCounts;
  if (tab === PAYMENT_TAB_KEYS.TEMPLATES) {
    totalCounts = templatesData?.templatePurchasesAdmin?.count;
  } else if (tab === PAYMENT_TAB_KEYS.CALLS) {
    totalCounts = consultationCallData?.bookingsPurchaseAdmin?.count;
  } else {
    totalCounts = plansData?.subscriptionPlanPurchasesAdmin?.count;
  }

  function fetchSubscriptionPurchasedListCall() {
    setLoading(true);
    fetchSubscriptionPurchasedList({
      variables: {
        filter: getPlansFilter,
        sort: getPlansSorter.querySorters,
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }
  function fetchTemplatePurchasedListCall() {
    setLoading(true);
    fetchTemplatePurchasedList({
      variables: {
        filter: getTemplatesFilter,
        sort: getTemplatesSorter.querySorters,
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  function fetchConsultationCall() {
    setLoading(true);
    fetchConsultationCallList({
      variables: {
        filter: getConsultationCallFilter,
        sort: getConsultationCallSorter.querySorters,
      },
      fetchPolicy: 'network-only',
      onCompleted: () => {
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    if (tab === PAYMENT_TAB_KEYS.TEMPLATES) {
      fetchTemplatePurchasedListCall();
    } else if (tab === PAYMENT_TAB_KEYS.CALLS) {
      fetchConsultationCall();
    } else {
      fetchSubscriptionPurchasedListCall();
    }
    setSearch(searchParamsValue?.toString());
  }, [location.search, tab]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;

    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }

    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.CATEGORIES]: filters.categories,
        [COMMON_QUERY_PARAMS.TYPE]: filters?.type,
        [COMMON_QUERY_PARAMS.CATEGORY]: filters?.category,
        [COMMON_QUERY_PARAMS.MEETING_TYPE]: filters?.meetingType,
        [COMMON_QUERY_PARAMS.PAGE]: 1,
        [COMMON_QUERY_PARAMS.SKIP]: 0,
      });
    } else {
      const prepareSorter = {};
      Object.keys(fieldToParams).forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams[item]] =
          prepareSorter[fieldToParams[item]] ?? null;
      });

      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      Boolean(getQueryParams[COMMON_QUERY_PARAMS.SEARCH]),
    );
  };

  const getPlanFilter = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {PLAN_TYPE_FILTER.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys?.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option?.value]
                  : selectedKeys?.filter((key) => key !== option?.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                [COMMON_QUERY_PARAMS.PAGE]: 1,
                [COMMON_QUERY_PARAMS.LIMIT]: LIMIT,
                [COMMON_QUERY_PARAMS.SKIP]: 0,
              }));
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              setQueryParams((prev) => ({
                ...prev,
                [COMMON_QUERY_PARAMS.PAGE]: 1,
                [COMMON_QUERY_PARAMS.LIMIT]: LIMIT,
                [COMMON_QUERY_PARAMS.SKIP]: 0,
              }));
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  /* this code can be used in future */
  /*  const getCategoryFilter = () => ({
    filterMultiple: true,
    filterSearch: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div className="mt-6 mb-6">
          <CommonSelect
            value={selectedKeys}
            placeholder="Select Category"
            className="full-width provider-select-filter"
            showSearch
            allowClear
            onChange={(item) => {
              setSelectedKeys(item);
            }}
            query={GET_CATEGORIES}
            fetchPolicy="network-only"
            responsePath="templateCategoriesAdmin.data"
            valuePath="id"
            labelPath="name"
            variables={{
              filter: {
                limit: 50,
                search: '',
                skip: 0,
              },
              sort: [
                {
                  sortBy: 'DESC',
                  sortOn: 'order',
                },
              ],
            }}
          />
        </div>
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  }); */

  /* this code can be used in future */
  /*  const getSubCategoryFilter = () => ({
    filterMultiple: true,
    filterSearch: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div className="mt-6 mb-6">
          <CommonSelect
            value={selectedKeys}
            placeholder="Select Sub Category"
            className="full-width provider-select-filter"
            showSearch
            allowClear
            onChange={(item) => {
              setSelectedKeys(item);
            }}
            query={GET_CATEGORIES}
            fetchPolicy="network-only"
            responsePath="templateCategoriesAdmin.data"
            valuePath="id"
            labelPath="name"
            variables={{
              where: {
                id: params?.filter?.categoryId
                  ? params?.filter?.categoryId
                  : null,
              },
              filter: {
                limit: 50,
                search: '',
                skip: 0,
              },
              sort: [
                {
                  sortBy: 'DESC',
                  sortOn: 'order',
                },
              ],
            }}
          />
        </div>
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            Apply
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            Reset
          </Button>
        </div>
      </div>
    ),
  }); */

  const handleDatePicker = (date) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.START]: date && dayjs(date?.[0])?.startOf('day'),
      [COMMON_QUERY_PARAMS.END]: date && dayjs(date?.[1])?.endOf('day'),
    });
  };

  const onTabChanges = (key) => {
    setLoading(true);
    navigate(`${ROUTES.PAYMENTS}/${key}`);
  };

  const getTypeCallFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getMeetingTypeFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {MEETING_TYPE_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getCategoryFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_CATEGORY_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {MODULES.PAYMENTS}
          {totalCounts ? ` (${totalCounts})` : ''}
        </Title>
        <div className="d-flex gap-12 filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <div className="d-flex gap-12">
            <RangePicker
              onChange={handleDatePicker}
              disabledDate={disableFutureDate}
              value={[
                getQueryParams?.[COMMON_QUERY_PARAMS.START]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.START])
                  : '',
                getQueryParams?.[COMMON_QUERY_PARAMS.END]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.END])
                  : '',
              ]}
              placeholder={['Start Date', 'End Date']}
              allowClear
            />
          </div>
        </div>
      </div>
      <Tabs defaultActiveKey={tab} items={TABS} onChange={onTabChanges} />
      {tab === PAYMENT_TAB_KEYS.PLANS && (
        <Table
          columns={[
            {
              title: UI_LABELS.PURCHASER,
              render: (item) =>
                `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`,
            },
            {
              title: UI_LABELS.PHONE_NUMBER,
              width: '10%',
              render: (item) => item?.purchaser?.phoneNumber ?? '-',
            },
            {
              title: UI_LABELS.PLAN,
              width: '15%',
              render: (item) => {
                const indirectTax = item?.features?.includes(
                  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
                )
                  ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.INDIRECT_TAX} - ${item?.subscriptionPlan?.title}`
                  : item?.subscriptionPlan?.title;
                const aiPlan = item?.features?.includes(AI_CREDIT_PLANS)
                  ? `${AI_PLAN_NAME} - ${item?.subscriptionPlan?.title}`
                  : indirectTax;
                return item?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX)
                  ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.DIRECT_TAX} - ${item?.subscriptionPlan?.title}`
                  : aiPlan;
              },
              key: 'categories',
              filteredValue:
                getQueryParams[COMMON_QUERY_PARAMS.CATEGORIES] ?? null,
              ...getPlanFilter(),
            },
            {
              title: UI_LABELS.CREATED_ON,
              dataIndex: 'createdAt',
              key: 'createdAt',
              sorter: true,
              width: '15%',
              sortOrder: getPlansSorter.sorters.createdAt,
              sortDirections: ['ascend', 'descend', 'ascend'],
              render: (value) => (
                <span>
                  {value ? dayjs(value)?.format(dateTimeFormat) : '-'}
                </span>
              ),
            },
            {
              title: UI_LABELS.PURCHASE_DATE,
              render: (item) =>
                item?.createdAt
                  ? dayjs(item?.createdAt).format(defaultDateFormat)
                  : '-',
            },
            {
              title: UI_LABELS.EXPIRY_DATE,
              render: (item) =>
                item?.expireDate
                  ? dayjs(item?.expireDate)?.format(defaultDateFormat)
                  : '-',
            },
            {
              title: UI_LABELS.DOWNLOADS_USED,
              render: (item) => (item?.usageCount ? item?.usageCount : '-'),
            },
            {
              title: UI_LABELS.COUNT_CREDIT,
              width: '7%',
              render: (item) => {
                const featureKey = item?.features?.[0];
                const subscriptionKey = featureKey
                  ? SUBSCRIPTION_DOWNLOADABLE?.[featureKey]
                  : undefined;

                // eslint-disable-next-line no-nested-ternary
                const isSinglePlan = item?.features?.includes(AI_CREDIT_PLANS)
                  ? item?.subscriptionPlan?.credit
                  : subscriptionKey && item?.featureConfig
                  ? item?.featureConfig[subscriptionKey]
                  : undefined;

                return item?.features?.length === 1
                  ? isSinglePlan ?? '-'
                  : item?.featureConfig?.[
                      SUBSCRIPTION_DOWNLOADABLE.ALL_IN_ONE
                    ] ?? '-';
              },
            },
            {
              title: `${UI_LABELS.PRICE} (Inr/year)`,
              render: (item) =>
                item?.billing?.price ? item?.billing?.price?.toFixed(2) : '-',
            },
            {
              title: 'Action',
              width: '10%',
              render: (item) => (
                <>
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${ROUTES.EDIT_TRANSACTION}/${item?.billing?.transactionLog?.id}/${item?.purchaser?.id}`,
                      );
                    }}
                  >
                    {UI_LABELS.TRANSACTION_LOG}
                  </Button>
                </>
              ),
            },
          ]}
          rowKey={(record) => record?.id}
          dataSource={plansData?.subscriptionPlanPurchasesAdmin?.data}
          pagination={{
            current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
            pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
            total: plansData?.subscriptionPlanPurchasesAdmin?.count,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
          scroll={{ y: `calc(100vh - 365px)` }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
      {tab === PAYMENT_TAB_KEYS.TEMPLATES && (
        <Table
          columns={[
            {
              title: UI_LABELS.PURCHASER,
              render: (item) =>
                item?.purchaser
                  ? `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`
                  : '-',
            },
            {
              title: UI_LABELS.PHONE_NUMBER,
              render: (item) => item?.purchaser?.phoneNumber ?? '-',
            },
            {
              title: UI_LABELS.TEMPLATE_TITLE,
              width: '18%',
              render: (item) => item?.template?.title ?? '-',
            },
            {
              title: UI_LABELS.CREATED_ON,
              dataIndex: 'createdAt',
              key: 'createdAt',
              sorter: true,
              sortOrder: getPlansSorter.sorters.createdAt,
              sortDirections: ['ascend', 'descend', 'ascend'],
              render: (value) => (
                <span>
                  {value ? dayjs(value)?.format(dateTimeFormat) : '-'}
                </span>
              ),
            },
            {
              title: UI_LABELS.PURCHASE_DATE,
              render: (item) =>
                item?.createdAt
                  ? dayjs(item?.createdAt).format(defaultDateFormat)
                  : '-',
            },
            {
              title: UI_LABELS.EXPIRY_DATE,
              render: (item) =>
                item?.expireDate
                  ? dayjs(item?.expireDate)?.format(defaultDateFormat)
                  : '-',
            },
            //   /* this code can be used in future */
            // {
            //   title: 'Category',
            //   filterSearch: true,
            //   render: (item) => item?.template?.category?.name,
            //   key: 'categoryId',
            //   // ...getCategoryFilter(),
            // },
            // {
            //   title: 'Sub Category',
            //   render: (item) => item?.template?.subCategory?.name,
            //   filterSearch: true,
            //   key: 'subCategoryId',
            //   // ...getSubCategoryFilter(),
            // },
            {
              title: `${UI_LABELS.PRICE} (Inr/year)`,
              render: (item) =>
                item?.billing?.price
                  ? item?.billing?.price
                  : item?.template?.price ?? '-',
            },
            {
              title: 'Action',
              width: '10%',
              render: (item) => (
                <>
                  <Button
                    type="link"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${ROUTES.EDIT_TRANSACTION}/${item?.billing?.transactionLog?.id}/${item?.purchaser?.id}`,
                      );
                    }}
                  >
                    {UI_LABELS.TRANSACTION_LOG}
                  </Button>
                </>
              ),
            },
          ]}
          rowKey={(record) => record?.id}
          dataSource={templatesData?.templatePurchasesAdmin?.data}
          pagination={{
            current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
            pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
            total: templatesData?.templatePurchasesAdmin?.count,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
          scroll={{ y: `calc(100vh - 365px)` }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
      {tab === PAYMENT_TAB_KEYS.CALLS && (
        <Table
          columns={[
            {
              title: UI_LABELS.PURCHASER,
              render: (item) =>
                item?.purchaser?.firstName
                  ? `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`
                  : `${item?.billing?.firstName} ${item?.billing?.lastName}`,
            },
            {
              title: UI_LABELS.PHONE_NUMBER,
              width: '10%',
              render: (item) =>
                // eslint-disable-next-line no-nested-ternary
                item?.purchaser?.phoneNumber
                  ? item?.purchaser?.phoneNumber
                  : item?.billing?.phone
                  ? item?.billing?.phone
                  : '-',
            },
            {
              title: UI_LABELS.CALL_TITLE,
              width: '10%',
              render: (item) => item?.consultationCall?.title ?? '-',
            },
            {
              title: UI_LABELS.CREATOR,
              render: (item) =>
                `${item?.consultationCall?.creator?.firstName} ${item?.consultationCall?.creator?.lastName}`,
            },
            {
              title: UI_LABELS.TYPE,
              render: (item) => (
                <Tag color="processing">
                  {item?.consultationCall?.type === 'ONE_TO_ONE'
                    ? '1-to-1'
                    : 'Webinar'}
                </Tag>
              ),
              key: 'type',
              filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.TYPE] ?? null,
              ...getTypeCallFilterProps(),
            },
            {
              title: UI_LABELS.CATEGORY,
              render: (item) =>
                item?.consultationCall?.category
                  ? item?.consultationCall?.category
                  : '-',
              key: 'category',
              filteredValue:
                getQueryParams?.[COMMON_QUERY_PARAMS.CATEGORY] ?? null,
              ...getCategoryFilterProps(),
            },
            {
              title: UI_LABELS.MEETING_TYPE,
              dataIndex: 'meetingType',
              key: 'meetingType',
              filteredValue:
                getQueryParams?.[COMMON_QUERY_PARAMS.MEETING_TYPE] ?? null,
              ...getMeetingTypeFilterProps(),
            },
            {
              title: UI_LABELS.DURATION,
              render: (item) => item?.consultationCall?.duration,
            },
            {
              title: UI_LABELS.CREATED_ON,
              key: 'createdAt',
              sorter: true,
              width: '15%',
              sortOrder: getPlansSorter.sorters.createdAt,
              sortDirections: ['ascend', 'descend', 'ascend'],
              render: (item) => (
                <span>
                  {item?.consultationCall?.createdAt
                    ? dayjs(item?.consultationCall?.createdAt)?.format(
                        dateTimeFormat,
                      )
                    : '-'}
                </span>
              ),
            },
            {
              title: UI_LABELS.PURCHASE_DATE,
              render: (item) =>
                item?.createdAt
                  ? dayjs(item?.createdAt).format(defaultDateFormat)
                  : '-',
            },
            {
              title: `${UI_LABELS.PRICE} (Inr)`,
              render: (item) =>
                item?.billing?.price ? item?.billing?.price?.toFixed(2) : '-',
            },
            {
              title: 'Action',
              width: '10%',
              render: (item) => (
                <>
                  <Button
                    type="link"
                    disabled={!item?.billing?.transactionLog}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `${ROUTES.EDIT_TRANSACTION}/${item?.billing?.transactionLog?.id}/${item?.purchaser?.id}`,
                      );
                    }}
                  >
                    {UI_LABELS.TRANSACTION_LOG}
                  </Button>
                </>
              ),
            },
          ]}
          rowKey={(record) => record?.id}
          dataSource={consultationCallData?.bookingsPurchaseAdmin.data}
          pagination={{
            current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
            pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
            total: consultationCallData?.bookingsPurchaseAdmin?.count,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
          }}
          scroll={{ y: `calc(100vh - 365px)` }}
          loading={loading}
          onChange={handleTableChange}
        />
      )}
    </>
  );
}

export default PaymentList;
