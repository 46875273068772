import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import {
  COMMON_QUERY_PARAMS,
  dateTimeFormat,
  IMPORT_CSV_STATUS,
  IMPORT_CSV_VISIBILITY,
  JUDGEMENT_AI_STATUS_SELECTION,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import {
  DELETE_BATCH,
  RETRY_BATCH_PROCESS,
  UPDATE_CSV_STATUS,
} from './graphql/mutations';
import { IMPORT_APPEALS_HISTORIES } from './graphql/queries';
import './importcsv.less';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const IMPORT_CSV_STATUS_COLORS = {
  COMPLETED: {
    color: 'success',
    icon: <CheckCircleOutlined style={{ fontSize: '12px' }} />,
  },
  PENDING: {
    color: 'warning',
    icon: <ClockCircleOutlined style={{ fontSize: '12px' }} />,
  },
  IN_PROCESS: {
    color: 'yellow',
    icon: <ClockCircleOutlined style={{ fontSize: '12px' }} />,
  },
  ERROR: {
    color: 'error',
    icon: <CloseCircleOutlined style={{ fontSize: '12px' }} />,
  },
  FAILED: {
    color: 'error',
    icon: <CloseCircleOutlined style={{ fontSize: '12px' }} />,
  },
};

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  batchId: COMMON_QUERY_PARAMS.SORT_BATCH_ID,
};

function ImportCSV() {
  const { navigate } = useRouter();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const [importList, setImportList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      status: {
        queryString: COMMON_QUERY_PARAMS.STATUS,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.STATUS] ?? null,
      },
      visibility: {
        queryString: COMMON_QUERY_PARAMS.VISIBILITY,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.VISIBILITY] ?? null,
      },
      isDeleted: {
        queryString: COMMON_QUERY_PARAMS.CREATED_BY,
        defaultValue: false,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const prepareFilterValue = {
    filter: getFilter,
    sort: getSorter.querySorters,
  };

  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);

  const listImportAppealsList = async ({ variables }) => {
    setLoading(true);
    await mongoClient
      ?.query({
        query: IMPORT_APPEALS_HISTORIES,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        setImportList(res?.data?.importAppealsHistories?.data);
        setTotalCount(res?.data?.importAppealsHistories?.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRetry = async (type, batchId) => {
    await mongoClient
      ?.mutate({
        mutation: RETRY_BATCH_PROCESS,
        variables: {
          where: {
            batchId,
            type,
          },
        },
      })
      .then((res) => {
        if (res) {
          listImportAppealsList({
            variables: prepareFilterValue,
          });
        }
      })
      .finally(() => {});
  };

  const fetchImportCsv = () => {
    listImportAppealsList({
      variables: prepareFilterValue,
    });
  };

  useEffect(() => {
    fetchImportCsv();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const filterStatusPopup = (options) => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {Object?.keys(options)?.map((item) => (
          <div key={item}>
            <Radio
              checked={selectedKeys === item}
              value={item}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? item : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {options[item]}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              setQueryParams((prev) => ({
                ...prev,
                [COMMON_QUERY_PARAMS.PAGE]: 1,
                [COMMON_QUERY_PARAMS.LIMIT]: LIMIT,
                [COMMON_QUERY_PARAMS.SKIP]: 0,
              }));
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              setQueryParams((prev) => ({
                ...prev,
                [COMMON_QUERY_PARAMS.PAGE]: 1,
                [COMMON_QUERY_PARAMS.LIMIT]: LIMIT,
                [COMMON_QUERY_PARAMS.SKIP]: 0,
              }));
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure you want to delete this Batch?',
      icon: <ExclamationCircleOutlined />,
      okText: UI_LABELS.YES,
      okType: 'danger',
      cancelText: UI_LABELS.NO,
      async onOk() {
        await mongoClient?.mutate({
          mutation: DELETE_BATCH,
          fetchPolicy: 'network-only',
          variables: {
            where: {
              batchId: id,
            },
          },
        });
        listImportAppealsList({
          variables: prepareFilterValue,
        });
      },
      onCancel() {},
    });
  };

  const renderFailedCount = (field, retryType) => (
    item,
    { batchId, status },
  ) => (
    <div className="d-flex align-center gap-8 justify-center">
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {item?.[field]}
      {/* eslint-disable-next-line react/destructuring-assignment */}
      {item?.[field] > 0 && !['IN_PROCESS', 'PENDING'].includes(status) ? (
        <Tooltip title="Manual Retry">
          <ReloadOutlined
            className="pointer"
            onClick={() => handleRetry(retryType, batchId)}
          />
        </Tooltip>
      ) : null}
    </div>
  );

  const columns = [
    {
      title: UI_LABELS.BATCH_ID,
      dataIndex: 'batchId',
      key: 'batchId',
      sorter: { multiple: 2 },
      sortOrder: getSorter.sorters.batchId,
      render(_, { batchId, status }) {
        return status !== 'COMPLETED' ? (
          <span>{batchId}</span>
        ) : (
          <Link
            to={`${ROUTES.REVIEW_APPEALS}?batchId=${batchId}`}
            target="_blank"
          >
            {batchId}
          </Link>
        );
      },
    },
    {
      title: UI_LABELS.FILE_SIZE,
      dataIndex: 'fileSize',
      align: 'center',
      render: (value) => value ?? '-',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '180px',
      render(value) {
        return (
          <span>{value ? dayjs(value)?.format(dateTimeFormat) : '-'}</span>
        );
      },
      sorter: { multiple: 1 },
      sortOrder: getSorter.sorters.createdAt,
      sortDirections: ['ascend', 'descend', 'ascend'],
      align: 'center',
    },
    {
      title: 'Total Records',
      dataIndex: 'totalRecords',
      width: '120px',
      align: 'center',
    },
    {
      title: UI_LABELS.DUPLICATE_COUNT,
      dataIndex: 'duplicateCount',
      width: '120px',
      align: 'center',
      render: (item) => item ?? 0,
    },
    {
      title: (
        <Tooltip title="Judgment text with less than 500 words">
          {UI_LABELS.SKIP_JUDGEMENT_COUNT}
        </Tooltip>
      ),
      dataIndex: 'skipJudgementCount',
      width: '140px',
      align: 'center',
      render: (item) => item ?? 0,
    },
    {
      title: UI_LABELS.PROCESSING_COUNT,
      dataIndex: 'failedJudgementsCount',
      width: '140px',
      align: 'center',
      render: (item) => item?.processing ?? 0,
    },
    {
      title: UI_LABELS.EXTRACT_JUDGEMENT_TEXT_FAILED_COUNT,
      dataIndex: 'failedJudgementsCount',
      width: '160px',
      align: 'center',
      render: renderFailedCount(
        'extractJudgementText',
        'EXTRACT_JUDGEMENT_TEXT',
      ),
    },
    {
      title: UI_LABELS.PDF_PROCESS_FAILED_COUNT,
      dataIndex: 'failedJudgementsCount',
      width: '140px',
      align: 'center',
      render: renderFailedCount('pdfProcess', 'PDF_PROCESS'),
    },
    {
      title: UI_LABELS.AI_PROCESS_FAILED_COUNT,
      dataIndex: 'failedJudgementsCount',
      width: '150px',
      align: 'center',
      render: renderFailedCount('aiProcess', 'AI_PROCESS'),
    },
    {
      title: UI_LABELS.COMPLETED_COUNT,
      dataIndex: 'failedJudgementsCount',
      width: '150px',
      align: 'center',
      render: (item) => item?.completed ?? 0,
    },
    {
      title: UI_LABELS.ERROR_LOG,
      dataIndex: 'errorLog',
      width: '130px',
      align: 'center',
      render: (item) =>
        item ? (
          <a target="_blank" href={item} rel="noreferrer">
            {UI_LABELS.DOWNLOAD}
          </a>
        ) : (
          '-'
        ),
    },
    {
      title: UI_LABELS.BATCH_STATUS,
      dataIndex: 'status',
      align: 'center',
      filteredValue: getQueryParams[COMMON_QUERY_PARAMS.STATUS] ?? null,
      ...filterStatusPopup(IMPORT_CSV_STATUS),
      render: (value) => (
        <Tag
          icon={IMPORT_CSV_STATUS_COLORS?.[value]?.icon}
          color={IMPORT_CSV_STATUS_COLORS?.[value]?.color}
        >
          {value === 'IN_PROCESS' ? 'PROCESSING' : value}
        </Tag>
      ),
    },
    {
      title: UI_LABELS.JUDGEMENT_STATUS,
      dataIndex: 'visibility',
      width: '170px',
      align: 'center',
      filteredValue: getQueryParams[COMMON_QUERY_PARAMS.VISIBILITY] ?? null,
      ...filterStatusPopup(IMPORT_CSV_VISIBILITY),
      render: (value, { batchId, status }) => {
        const onChange = async (event) => {
          await mongoClient?.mutate({
            mutation: UPDATE_CSV_STATUS,
            fetchPolicy: 'network-only',
            variables: {
              data: {
                visibility: event,
              },
              where: {
                batchId,
              },
            },
          });
          listImportAppealsList({
            variables: prepareFilterValue,
          });
        };
        return (
          <Select
            style={{ width: 120 }}
            value={value === 'PROCESSING' ? 'Processing' : value}
            onChange={onChange}
            disabled={value !== 'DRAFT' || status !== 'COMPLETED'}
            options={JUDGEMENT_AI_STATUS_SELECTION}
          />
        );
      },
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: 'action',
      align: 'center',
      fixed: 'right',
      render: (_, { batchId, visibility, status }) => (
        <div className="category-action">
          <Link
            to={`${ROUTES.REVIEW_APPEALS}?batchId=${batchId}`}
            target="_blank"
          >
            <Button
              type="link"
              className="action-btn"
              disabled={status !== 'COMPLETED'}
            >
              {UI_LABELS.REVIEW}
            </Button>
          </Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(batchId)}
            danger
            disabled={visibility === 'PUBLISHED' || status !== 'COMPLETED'}
          >
            {UI_LABELS.DELETE}
          </Button>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.STATUS]: filters.status,
        [COMMON_QUERY_PARAMS.VISIBILITY]: filters.visibility,
        [COMMON_QUERY_PARAMS.PAGE]: 1,
        [COMMON_QUERY_PARAMS.SKIP]: 0,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (e) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
      [COMMON_QUERY_PARAMS.PAGE]: null,
    });
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.IMPORT_CSV}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />

          <Button onClick={fetchImportCsv} title="Refresh" loading={loading}>
            <ReloadOutlined />
          </Button>
          {process.env.REACT_APP_ALLOW_IMPORT_JUDGEMENT === 'true' && !isStaff && (
            <Link to={`${ROUTES.IMPORT_CSV}${ROUTES.IMPORT_JUDGEMENT}`}>
              <Button type="primary">{UI_LABELS.IMPORT_JUDGEMENT}</Button>
            </Link>
          )}
          <Button
            type="primary"
            onClick={() => {
              navigate(ROUTES.CREATE_CSV);
            }}
            icon={<PlusCircleOutlined />}
          >
            {UI_LABELS.IMPORT_CSV}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.batchId}
        dataSource={importList}
        pagination={{
          total: totalCount,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ x: 'max-content', y: `calc(100vh - 386px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default ImportCSV;
