import { ExclamationCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Modal, Popover, Radio, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  JUDGEMENT_VISIBILITY_FILTER,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_COLOR,
  USE_AI_FIELD_FILTER,
  dateTimeFormat,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  getCourtList,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import AdvanceSearchDetails from './components/AdvanceSearchDetails';
import { DELETE_JUDGEMENT } from './graphql/mutations';
import { LIST_JUDGEMENTS, SEND_TEST_NEWSLETTER } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
};
function JudgementsList() {
  const { category: categoryType } = useParams();
  const {
    state: { currentUser },
  } = useContext(AppContext);
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const location = useLocation();

  const [judgements, setJudgements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(
    decodeURIComponent(searchParamsValue?.toString()),
  );

  const isStaff = useMemo(() => currentUser?.roles.includes(ROLE_KEYS.STAFF), [
    currentUser?.roles,
  ]);
  const [courtList, setCourtList] = useState([]);

  const [sendNewsletter, { loading: newsletterLoading }] = useLazyQuery(
    SEND_TEST_NEWSLETTER,
    {
      fetchPolicy: 'network-only',
    },
  );

  const getCourts = async (category) => {
    const data = await getCourtList({
      variables: { category: CATEGORIES_KEYS[category] },
    });
    setCourtList(data);
  };

  useEffect(() => {
    getCourts(categoryType);
    setErrorMessage('');
  }, [categoryType]);

  const activeStatus = getQueryParams?.[COMMON_QUERY_PARAMS.IS_AI_DATA];
  const isActive = activeStatus ? activeStatus === 'Yes' : null;
  const getFilter = prepareFilterFromUrl(
    {
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY_TYPE,
        defaultValue: [],
        value: CATEGORIES_KEYS[categoryType],
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: decodeURIComponent(searchParamsValue?.toString()) ?? '',
      },
      court: {
        queryString: COMMON_QUERY_PARAMS.COURT,
        defaultValue: null,
      },
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      status: { queryString: COMMON_QUERY_PARAMS.STATUS, defaultValue: null },
      useAiData: {
        queryString: COMMON_QUERY_PARAMS.IS_AI_DATA,
        defaultValue: null,
        value: isActive ?? null,
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );
  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const listJudgments = async () => {
    setLoading(true);
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    await mongoClient
      ?.query({
        query: LIST_JUDGEMENTS,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      })
      .then((res) => {
        setLoading(false);
        setJudgements(res?.data);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listJudgments();
    setSearch(decodeURIComponent(searchParamsValue?.toString()));
  }, [location.search, location.pathname]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.COURT]: filters?.court ?? null,
        [COMMON_QUERY_PARAMS.IS_AI_DATA]: filters.useAiData,
        [COMMON_QUERY_PARAMS.STATUS]: filters.status,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });

      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (value) => {
    const wordCount = value?.split(/\s+/)?.filter(Boolean)?.length;
    const charCount = value?.length;
    if (charCount > 100) {
      setErrorMessage('Maximum 100 characters allowed');
    } else if (wordCount > 10) {
      setErrorMessage('Maximum 10 words allowed');
    } else {
      setErrorMessage('');
      setQueryParams(
        {
          [COMMON_QUERY_PARAMS.SEARCH]: encodeURIComponent(value?.trim()),
          [COMMON_QUERY_PARAMS.PAGE]: null,
        },
        true,
      );
    }
  };

  const getTypeUseAiFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USE_AI_FIELD_FILTER?.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option.label}
              checked={selectedKeys === option?.label}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.label : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getTypeVisibilityStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {JUDGEMENT_VISIBILITY_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {courtList?.map((court) => (
          <div key={court?.value}>
            <Radio
              checked={selectedKeys === court?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? court?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {court?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const deleteJudgement = async (id) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGEMENT,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        listJudgments();
      });
  };

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure you want to delete?',
      icon: <ExclamationCircleFilled />,
      okText: UI_LABELS.YES,
      okType: 'danger',
      cancelText: UI_LABELS.NO,
      okButtonProps: { loading },
      onOk() {
        deleteJudgement(id);
      },
      onCancel() {},
    });
  };

  const renderBadge = (item) => {
    switch (item) {
      case TEMPLATE_STATUS.DRAFT:
        return <Badge count="Draft" color={TEMPLATE_STATUS_COLOR.DRAFT} />;
      case TEMPLATE_STATUS.PUBLISHED:
        return (
          <Badge count="Published" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title: UI_LABELS.APPEAL_NUMBER,
      dataIndex: 'appealNumber',
      width: '15%',
    },
    {
      title: UI_LABELS.APPELLANT,
      dataIndex: 'appellant',
      width: '20%',
      render(appellant) {
        return appellant ?? '-';
      },
    },
    {
      title: UI_LABELS.COURT,
      dataIndex: 'court',
      render(court) {
        return court?.name ?? '-';
      },
      key: 'court',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.COURT] ?? null,
      ...filterCourtPopup(),
    },
    {
      title: UI_LABELS.AI_FIELD,
      key: 'useAiData',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.IS_AI_DATA] ?? null,
      ...getTypeUseAiFilterProps(),
      render: (item) =>
        // eslint-disable-next-line no-nested-ternary
        item.useAiData === null ? (
          '-'
        ) : item?.useAiData ? (
          <Badge count="Yes" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
        ) : (
          <Badge count="No" color={TEMPLATE_STATUS_COLOR.UNPUBLISHED} />
        ),
    },
    {
      title: UI_LABELS.VISIBILITY_STATUS,
      dataIndex: 'status',
      key: 'status',
      render: (item) => renderBadge(item),
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.STATUS] ?? null,
      ...getTypeVisibilityStatusFilterProps(),
    },
    // kept for future use
    /* {
      title: UI_LABELS.CATEGORIES,
      dataIndex: 'category',
      render(category) {
        return TAX_CATEGORIES[category];
      },
    }, */
    /*  {
      title: UI_LABELS.JUDGEMENT_TEXT,
      dataIndex: 'judgmentText',
      ellipsis: true,
      render(judgmentText) {
        return judgmentText ?? '-';
      },
    }, */
    // {
    //   title: 'PDF',
    //   dataIndex: 'pdfUrl',
    //   ellipsis: true,
    //   render(url) {
    //     return (
    //       <Link to={url} target="_blank" rel="noreferrer">
    //         {url}
    //       </Link>
    //     );
    //   },
    // },
    {
      title: UI_LABELS.CREATED_ON,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: { multiple: 1 },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: getSorter.sorters.createdAt,
      render(date) {
        return dayjs(date).format(dateTimeFormat);
      },
    },
    {
      title: UI_LABELS.UPDATED_ON,
      dataIndex: 'updatedAt',
      render(date) {
        return dayjs(date).format(dateTimeFormat);
      },
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      render: (_, { id } = {}) => (
        <div className="category-action">
          <Link to={`/${categoryType}${ROUTES.EDIT_JUDGEMENTS}${id}`}>
            {isStaff ? UI_LABELS.VIEW : UI_LABELS.EDIT}
          </Link>
          {!isStaff && (
            <Button
              type="link"
              className="action-btn"
              onClick={() => handleDelete(id)}
              danger
            >
              {UI_LABELS.DELETE}
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.JUDGEMENTS}
          {judgements?.judgmentsAdmin?.judgementsCount
            ? ` (${judgements?.judgmentsAdmin?.judgementsCount})`
            : ''}
        </Title>
        <div className="d-flex filter-input gap-16 mb-12">
          <div className="search-error-wrapper d-flex align-center justify-center gap-8">
            <SearchComponent
              key={categoryType}
              className="list-search"
              getData={handleSearchChange}
              disabled={
                getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] === '' && loading
              }
              status={errorMessage ? 'error' : ''}
              value={search}
              handleLiveChange={(e) => setSearch(e)}
            />
            {errorMessage && (
              <div className="search-error-block">{errorMessage}</div>
            )}
            <div className="advance-search-info-btn">
              <Popover
                content={AdvanceSearchDetails}
                trigger="hover"
                placement="bottom"
              >
                <InfoCircleOutlined />
              </Popover>
            </div>
          </div>

          <Button
            type="primary"
            loading={newsletterLoading}
            onClick={sendNewsletter}
          >
            {UI_LABELS.TEST_NEWSLETTER}
          </Button>

          {/* Commented for future use */}
          {/* <Button
          icon={<PlusCircleOutlined />}
          className="ml-8"
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.ADD_JUDGEMENT);
          }}

        >
          Add judgement
        </Button> */}
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={judgements?.judgmentsAdmin?.data}
        pagination={{
          total: judgements?.judgmentsAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default JudgementsList;
