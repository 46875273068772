import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Col, Form, Input, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import {
  MAX_PRICE_LIMIT,
  PAGE_TYPE,
  PLAN_STATUS,
  ROUTES,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { formValidatorRules } from '../../common/utils';
import ShowOnWeb from '../../components/ShowOnWeb';
import useRouter from '../../hooks/useRouter';
import { EDIT_SUBSCRIPTION_PLAN } from './graphql/mutations';
import { GET_SUBSCRIPTION_PLAN } from './graphql/queries';

const defaultFormValue = {
  title: '',
  description: '',
  price: '',
  featureList: [],
  type: '',
};
const { required, isEnglish } = formValidatorRules;

export default function EditSubscriptionPlan() {
  const { params, navigate, navigateWithBack } = useRouter();
  const { id } = params;
  const [form] = Form.useForm();
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [showOnWeb, setShowOnWeb] = useState({});
  const [planStatus, setPlanStatus] = useState('');
  const [description, setDescription] = useState('');

  const [subscriptionPlan] = useLazyQuery(GET_SUBSCRIPTION_PLAN, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      form.setFieldsValue(res?.subscriptionPlanAdmin);
      setShowOnWeb(res?.subscriptionPlanAdmin?.showOnWeb);
      setPlanStatus(res?.subscriptionPlanAdmin?.status);
    },
  });

  const [updatePlan, { loading }] = useMutation(EDIT_SUBSCRIPTION_PLAN, {
    onCompleted: () => navigateWithBack(ROUTES.SUBSCRIPTION_PLANS),
    onError() {},
  });

  useEffect(() => {
    if (id) {
      subscriptionPlan({ variables: { where: { id } } });
    }
  }, [id]);

  const onFinish = (values) => {
    if (id) {
      updatePlan({
        variables: {
          where: { id },
          data: { ...values, price: JSON.parse(values?.price) },
        },
      });
    }
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigateWithBack(ROUTES.SUBSCRIPTION_PLANS)}
          icon={<ArrowLeftOutlined />}
        />
        {UI_LABELS.EDIT_SUBSCRIPTION_PLAN}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={defaultFormValue}
            onValuesChange={() => setIsFieldsChanged(true)}
            layout="vertical"
          >
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="title"
                  label="Title"
                  rules={[
                    { required, message: 'Please enter Title' },
                    isEnglish,
                  ]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={12}>
                <Form.Item
                  name="type"
                  label="Type"
                  className="user-type-input"
                  rules={[{ required, message: 'Please select Type' }]}
                >
                  <Input disabled value="YEARLY" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={12} span={12}>
                <Form.Item
                  name="price"
                  label="Price"
                  rules={[
                    { required, message: 'Please enter Price' },
                    () => ({
                      validator(_, value) {
                        if (value) {
                          if (
                            Number(value) < 1 ||
                            Number(value) > Number(MAX_PRICE_LIMIT)
                          ) {
                            return Promise.reject(
                              new Error(
                                `Price should be between Rs. 1 to ${MAX_PRICE_LIMIT}`,
                              ),
                            );
                          }
                          return Promise.resolve();
                        }
                        return Promise.reject();
                      },
                    }),
                  ]}
                >
                  <Input type="number" placeholder="Enter Price" />
                </Form.Item>
              </Col>
              {planStatus === PLAN_STATUS.PUBLISHED && (
                <Col xs={24} sm={24} md={12} span={12}>
                  <ShowOnWeb
                    showOnWeb={showOnWeb}
                    pageType={PAGE_TYPE.PLAN}
                    id={id}
                    placeholder="Select pages to show this plan"
                  />
                </Col>
              )}
              <Col xs={24} sm={24} md={24}>
                <Row gutter={16}>
                  <Col xs={24} sm={24} md={24}>
                    <p className="mt-0 mb-8 feature-label">
                      {UI_LABELS.FEATURES}
                    </p>
                    <Form.List
                      name="featureList"
                      rules={[
                        {
                          validator: async (_, names) => {
                            if (!names || names?.length < 1) {
                              return Promise.reject(
                                new Error(
                                  'At least 1 feature must be provided',
                                ),
                              );
                            }
                          },
                        },
                      ]}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields?.map(({ key, name, ...restField }) => (
                            <Space
                              className="dynamic-cat-input"
                              key={key}
                              align="baseline"
                            >
                              <Form.Item
                                {...restField}
                                name={[name]}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please enter Feature',
                                  },
                                ]}
                              >
                                <Input
                                  className="input-cat"
                                  placeholder="Enter Feature"
                                />
                              </Form.Item>
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            </Space>
                          ))}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => add()}
                              block
                              icon={<PlusOutlined />}
                            >
                              {UI_LABELS.ADD_MORE_FEATURE}
                            </Button>
                          </Form.Item>
                          <Form.ErrorList
                            className="category-error"
                            errors={errors}
                          />
                        </>
                      )}
                    </Form.List>
                  </Col>
                </Row>
              </Col>

              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[
                    { required, message: 'Please enter Description' },
                    isEnglish,
                  ]}
                >
                  <ReactQuill
                    theme="snow"
                    value={description}
                    onChange={setDescription}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item>
              <Space size={8}>
                <Button onClick={() => navigate(-1)}>{UI_LABELS.CANCEL}</Button>
                <Button
                  type="primary"
                  disabled={!isFieldsChanged}
                  htmlType="submit"
                  loading={loading}
                >
                  {UI_LABELS.SAVE}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
}
