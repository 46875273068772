import { gql } from '@apollo/client';

export const GET_SUBMISSIONS = gql`
  query SubmissionsAdmin(
    $filter: ListSubmissionsFilter
    $sort: [ListSubmissionsSort!]
  ) {
    submissionsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        category
        id
        description
        createdAt
        updatedAt
        issuePeriod
        pdfUrl
        region
        slug
        status
        title
      }
    }
  }
`;

export const GET_SUBMISSION = gql`
  query SubmissionAdmin($where: SubmissionIdWhereUniqueInput!) {
    submissionAdmin(where: $where) {
      category
      createdAt
      description
      id
      issuePeriod
      pdfUrl
      region
      slug
      status
      title
      startDate
      endDate
    }
  }
`;

export const SUBMISSION_PDF_SIGNED_URL = gql`
  query GetSubmissionPdfFileUploadSignedUrl(
    $data: GetPdfFileUploadSignedUrlInput!
  ) {
    getSubmissionPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
