import { useLazyQuery } from '@apollo/client';
import { Drawer } from 'antd';
import AppContext from 'antd/es/app/context';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { GET_NOTIFICATIONS } from '../../../components/graphql/queries';
import NotificationDrawer from '../header/NotificationDrawer';

const sort = [
  {
    sortBy: 'DESC',
    sortOn: 'createdAt',
  },
];

const NotificationDrawerDesktop = ({ open, onClose }) => {
  const { fetchCurrentUser, currentUserData } = useContext(AppContext);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const copyUserData = useMemo(() => ({ ...currentUserData }), []);

  const [filter, setFilter] = useState({
    limit: 10,
    skip: 0,
  });

  const [hasMore, setHasMore] = useState(true);
  const drawerBodyRef = useRef(null);
  const notificationListRef = useRef(null);

  const [
    fetchNotifications,
    { data: notificationList, loading, fetchMore },
  ] = useLazyQuery(GET_NOTIFICATIONS, {
    fetchPolicy: 'network-only',
    onCompleted(data) {
      copyUserData.hasUnseenNotification = false;
      const totalCount = data?.notifications?.count || 0;

      if (data?.notifications?.data?.length >= totalCount) {
        setHasMore(false);
      }
      fetchCurrentUser();
    },
    onError() {},
  });

  // Update ref when notificationList changes
  useEffect(() => {
    notificationListRef.current = notificationList;
  }, [notificationList]);

  const getMoreNotifications = useCallback(() => {
    const notifications = notificationListRef.current;
    if (loading || !hasMore || !notifications?.notifications?.data) return;

    const currentCount = notifications?.notifications?.data?.length || 0;
    const totalCount = notifications?.notifications?.count || 0;
    if (currentCount >= totalCount) {
      setHasMore(false);
      return;
    }
    fetchMore({
      variables: {
        filter: {
          ...filter,
          skip: currentCount,
        },
        sort,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (
          !fetchMoreResult ||
          fetchMoreResult?.notifications?.data?.length === 0
        ) {
          setHasMore(false);
          return previousResult;
        }

        return {
          ...previousResult,
          notifications: {
            ...previousResult.notifications,
            data: [
              ...previousResult.notifications.data,
              ...fetchMoreResult.notifications.data,
            ],
            count: fetchMoreResult.notifications.count,
          },
        };
      },
    });
  }, [fetchMore, hasMore, loading, filter]);

  const handleScroll = useCallback(
    (event) => {
      if (!event.target) return;

      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const isBottom = scrollHeight - scrollTop <= clientHeight + 1;

      if (isBottom && hasMore && !loading) {
        getMoreNotifications();
      }
    },
    [hasMore, loading, getMoreNotifications],
  );

  useEffect(() => {
    if (open) {
      fetchNotifications({
        variables: {
          filter,
          sort,
        },
      });

      // Attach scroll event to the drawer body
      setTimeout(() => {
        // eslint-disable-next-line no-undef
        const drawerBody = document?.querySelector('.ant-drawer-body');
        if (drawerBody) {
          drawerBody.addEventListener('scroll', handleScroll);
          drawerBodyRef.current = drawerBody;
        }
      }, 100);
    } else {
      setHasMore(true);
      setFilter((prev) => ({ ...prev, skip: 0 }));

      // Remove scroll event when drawer closes
      if (drawerBodyRef.current) {
        drawerBodyRef.current.removeEventListener('scroll', handleScroll);
      }
    }

    return () => {
      if (drawerBodyRef.current) {
        drawerBodyRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [fetchNotifications, open]);

  return (
    <>
      <Drawer
        title="Notification"
        className="desktop-notification-drawer"
        onClose={onClose}
        open={open}
      >
        <div onScroll={handleScroll}>
          {notificationList?.notifications?.data?.length > 0 ? (
            <NotificationDrawer
              notifications={notificationList?.notifications?.data}
              toggleDrawer={onClose}
            />
          ) : (
            <div className="d-flex justify-center">No new notification</div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default NotificationDrawerDesktop;
