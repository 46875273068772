import { gql } from '@apollo/client';

export const CREATE_TRANSACTION = gql`
  mutation CreateTransactionLog($data: CreateTransactionInput!) {
    createTransactionLog(data: $data) {
      message
    }
  }
`;

export const EDIT_TRANSACTION = gql`
  mutation UpdateTransactionLogStatus(
    $data: updateTransactionLogStatusInput!
    $where: TransactionLogWhereUniqueInput!
  ) {
    updateTransactionLogStatus(data: $data, where: $where) {
      message
    }
  }
`;

export const TRANSACTION_PAYOUT = gql`
  mutation TransactionPayout($filter: TransactionPayoutFilter!) {
    transactionPayout(filter: $filter) {
      message
    }
  }
`;

export const REGENERATE_INVOICE = gql`
  mutation CreateTransactionLogInvoice(
    $where: TransactionLogWhereUniqueInput!
  ) {
    createTransactionLogInvoice(where: $where) {
      message
    }
  }
`;
