import dayjs from 'dayjs';
import client, { mongoClient } from '../apollo';
import { messageContext } from '../components/AppContextHolder';
import { GET_SIGNED_URL } from '../components/graphql/mutations';
import { GET_COURT_BY_CATEGORY } from '../modules/import-csv/graphql/queries';
import api from './api';
import {
  AI_CREDIT_PLANS,
  AI_PLAN_NAME,
  COMMON_QUERY_PARAMS,
  defaultDateFormat,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  NOTICE_BOARD_NAME,
  REGEX,
  SORT_OPTION,
  TAX_CATEGORIES,
} from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isPortalIdExists = (portalId) => !!injectUsingPortal(portalId);

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) =>
  // eslint-disable-next-line no-undef
  document?.getElementById(portalId);

export const isDocumentIdExist = (portalId) =>
  !!getElementFromDocumentId(portalId);
// Check for document Id's exists end

export const formatDate = (
  dateTime,
  format = `${defaultDateFormat} hh:mm A`,
) => {
  if (dateTime && dayjs && format) {
    return dayjs(dateTime)?.format(format);
  }

  return dateTime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required',
  },
  email: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.EMAIL?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('The input is not valid E-mail');
      }
      return Promise?.resolve();
    },
  }),
  name: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.NAME?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Please enter valid name');
      }
      return Promise?.resolve();
    },
  }),
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Number');
      }
      return Promise?.resolve();
    },
  }),
  phone: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.PHONE?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Phone number');
      }
      return Promise?.resolve();
    },
  }),
  mobileNumber: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!Number(value) || !REGEX?.MOBILE_NUMBER?.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Phone number');
      }
      return Promise?.resolve();
    },
  }),
  pancard: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.PANCARD?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject('Should be a valid Pancard detail');
      }
      return Promise?.resolve();
    },
  }),
  isEnglish: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise?.resolve();
      }
      if (!REGEX?.ENGLISH?.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise?.reject(
          'Please enter only standard keyboard characters (A-Z, a-z, 0-9, punctuation).',
        );
      }
      return Promise?.resolve();
    },
  }),
  password: [
    {
      pattern: REGEX?.PASSWORD,
      message:
        'Password must be at least 8 characters long and contain at least 1 uppercase 1 lowercase 1 number and 1 special char.',
    },
  ],
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj?.setUTCHours(timeObj?.getUTCHours());
  formattedDateTime = new Date(formattedDateTime)?.setUTCMinutes(
    timeObj?.getUTCMinutes(),
  );
  formattedDateTime = new Date(formattedDateTime)?.toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned?.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`?.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formattedPrice = price || 0;

  return Number(formattedPrice)?.toLocaleString('en', {
    style: 'currency',
    currency: 'USD',
  });
};

export const formItemProps = { normalize: (value) => value?.trim() };

// Note : Function to upload on s3 bucket
export async function fileUpload(signedUrl, image, onUploadProgress) {
  try {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr?.open('PUT', signedUrl);
      xhr?.setRequestHeader('Content-Type', image?.type);
      xhr?.setRequestHeader('x-amz-acl', 'public-read');
      xhr?.addEventListener('readystatechange', function () {
        if (this?.readyState === 4) {
          resolve(xhr?.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math?.ceil((e?.loaded / e?.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr?.send(image);
    });
  } catch (error) {
    messageContext?.error(error?.message);
  }
}

export const getSignedUrl = async (fileObj) => {
  const fileName = fileObj?.name;

  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'write',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const uploadImage = async (signedRequest, fileObj) => {
  await api(signedRequest, {
    method: 'PUT',
    data: fileObj?.originFileObj || fileObj,
    headers: {
      'Content-Type': fileObj?.type,
    },
  });
};

export const fetchImage = async (fileObj) => {
  const fileName = fileObj?.name;
  const extension = fileName?.slice(fileName?.lastIndexOf('.') + 1);
  const key = `${fileName}`;

  const response = await client?.mutate({
    mutation: GET_SIGNED_URL,
    variables: {
      action: 'read',
      data: {
        extension: `.${extension}`,
        contentType: fileObj?.type,
        key,
      },
    },
  });
  if (response) {
    return response?.data;
  }
  return null;
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result);
    reader.onerror = (error) => reject(error);
  });

export const getTimeFromMins = (mins) => {
  const hours = Math?.floor(mins / 60);
  const minutes = mins % 60;
  return `${hours}h ${minutes}m`;
};

export const getBase64File = (img, callback) => {
  // eslint-disable-next-line no-undef
  const reader = new FileReader();
  reader?.addEventListener('load', () => callback(reader?.result));
  reader?.readAsDataURL(img);
};

export const beforeUpload = (file, fileLimit) => {
  const isJpgOrPng = file?.type === 'image/jpeg' || file?.type === 'image/png';
  if (!isJpgOrPng) {
    messageContext?.error('You can only upload JPG/PNG file');
  }
  const isLt2M = file?.size / 1024 / 1024 < fileLimit;
  if (!isLt2M) {
    messageContext?.error('Image must smaller than 2MB');
  }
  return isJpgOrPng && isLt2M;
};

export const beforeUploadDocFile = (file) => {
  const extension = file.name.split('.').pop(); // Extract file extension
  const mimeTypes = {
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    // Add more mappings as needed
  };

  const mimeType = mimeTypes[extension.toLowerCase()];
  if (!mimeType) {
    messageContext?.error('Unsupported file format or invalid file.');
    return false;
  }
};

export const handleProtectedNavigation = (allow, callback, path) =>
  allow ? callback(path) : false;

export function removeTypenameFromObject(obj) {
  if (!obj) return obj;
  if (Array.isArray(obj)) {
    return obj.map((value) => removeTypenameFromObject(value));
  }
  if (typeof obj === 'object') {
    const newObj = {};
    Object.entries(obj).forEach(([key, value]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypenameFromObject(value);
      }
    });
    return newObj;
  }
  return obj;
}

export const disableFutureDate = (current) =>
  current && current > dayjs().endOf('day');

export const extractCsvHeaders = (file) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.onload = (event) => {
      const text = event.target.result;
      const lines = text.split(/\r\n|\n|\r/);
      const headers = lines[0].split(',').map((header) => header.trim());
      resolve(headers);
    };
    reader.onerror = reject;
    reader.readAsText(file);
  });

export const noticeBoardPlanTitle = (planData) => {
  const indirectTax = planData?.features?.includes(
    FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  )
    ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.INDIRECT_TAX} - ${planData?.title}`
    : planData?.title;
  const aiPlan = planData?.features?.includes(AI_CREDIT_PLANS)
    ? `${AI_PLAN_NAME} - ${planData?.title}`
    : indirectTax;
  return planData?.features?.includes(FEATURE_NOTICE_BOARD_DIRECT_TAX)
    ? `${NOTICE_BOARD_NAME} - ${TAX_CATEGORIES.DIRECT_TAX} - ${planData?.title}`
    : aiPlan;
};

export const getCourtList = async ({ variables }) => {
  try {
    const data = await mongoClient?.query({
      query: GET_COURT_BY_CATEGORY,
      fetchPolicy: 'network-only',
      variables,
    });
    const prepareData =
      data?.data?.getCourtsByCategory?.map((item) => ({
        label: item?.name,
        value: item?.courtType,
        key: item?.courtType,
      })) ?? [];
    return prepareData;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('court list error', error);
  }
};

export const resetPagination = (searchParams) => {
  searchParams.delete(COMMON_QUERY_PARAMS.PAGE);
  searchParams.delete(COMMON_QUERY_PARAMS.LIMIT);
};

export const prepareFilterFromUrl = (filterModal, urlData) => {
  const queryObject = {};
  Object.keys(filterModal)?.forEach((key) => {
    if (filterModal[key]?.queryString) {
      let value;
      if (
        filterModal[key]?.value ||
        typeof filterModal[key]?.value === 'boolean'
      ) {
        value = filterModal[key]?.value;
      } else if (
        urlData[filterModal[key]?.queryString] ||
        typeof urlData[filterModal[key]?.queryString] === 'boolean'
      ) {
        value = urlData[filterModal[key]?.queryString];
      } else {
        value = filterModal[key]?.defaultValue;
      }
      queryObject[key] = !Number.isNaN(value) ? value : Number(value);
    } else {
      queryObject[key] = prepareFilterFromUrl(filterModal[key], urlData);
    }
  });
  return queryObject;
};

export const prepareSorterFromUrl = (fieldToParamsObj, defaultSort) => {
  let isDefaultSortApplied = false;
  // eslint-disable-next-line no-undef
  const searchParams = new URLSearchParams(window?.location?.search);
  // eslint-disable-next-line prefer-const
  let querySorters = [];
  const sorters = {};

  Object.keys(fieldToParamsObj)?.forEach((item) => {
    if (searchParams.has(fieldToParamsObj?.[item])) {
      const value = searchParams.get(fieldToParamsObj?.[item]);
      // eslint-disable-next-line no-nested-ternary
      sorters[item] = value
        ? value === SORT_OPTION.ASC
          ? 'ascend'
          : 'descend'
        : null;
      querySorters.push({
        sortBy: value,
        sortOn: item,
      });
    }
  });

  if (!querySorters.length && defaultSort) {
    isDefaultSortApplied = true;
    querySorters.push(defaultSort);
    sorters[defaultSort?.sortOn] =
      defaultSort.sortBy === SORT_OPTION.ASC ? 'ascend' : 'descend';
  }

  return { querySorters, sorters, isDefaultSortApplied };
};

export const avtarPlaceholder = (firstName, lastName) =>
  `${firstName?.charAt(0)?.toUpperCase()}${lastName?.charAt(0)?.toUpperCase()}`;

export const defaultRangePickerURL = () => {
  const monthAgo = dayjs().subtract(1, 'month').startOf('day').toISOString();
  const today = dayjs().toISOString();
  return `?${COMMON_QUERY_PARAMS.START}=${monthAgo}&${COMMON_QUERY_PARAMS.END}=${today}`;
};
