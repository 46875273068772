import { useLazyQuery } from '@apollo/client';
import { Button, Table } from 'antd';
import Title from 'antd/es/typography/Title';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useEffect, useState } from 'react';
import {
  CALL_TYPE,
  LIMIT,
  TIME_FORMAT,
  defaultDateFormat,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import CallRecordingViewer from './CallRecordingViewer';
import { COMPLETED_CONSULTATION_CALLS } from './graphql/queries';

dayjs.extend(utc);
dayjs().utcOffset();
function CallPurchaserList({ callId }) {
  const [fetchCalls] = useLazyQuery(COMPLETED_CONSULTATION_CALLS);
  const [videoUrl, setVideoUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: LIMIT,
    },
  });

  const fetchCallList = () => {
    const { pageSize, current } = tableParams.pagination;
    setLoading(true);
    fetchCalls({
      variables: {
        filter: {
          status: 'COMPLETED',
          platform: 'WEB',
          skip: (current - 1) * pageSize,
          limit: pageSize,
          where: {
            id: callId,
          },
        },
        sort: [
          {
            sortBy: 'DESC',
            sortOn: 'createdAt',
          },
        ],
      },
      fetchPolicy: 'network-only',
      onCompleted: ({ bookingOrders }) => {
        setListData(bookingOrders?.data);
        setTotalCount(bookingOrders?.count);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    fetchCallList();
  }, [tableParams]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination: {
        current: pagination?.current,
        pageSize: pagination?.pageSize || LIMIT,
      },
    });
  };

  const columnsView = [
    {
      title: UI_LABELS.PURCHASER_NAME,
      width: '20%',
      render: (item) =>
        `${item?.purchaser?.firstName} ${item?.purchaser?.lastName}`,
    },
    {
      title: UI_LABELS.TIME_SLOT,
      width: '40',
      render: (item) =>
        item?.consultationCall?.type === CALL_TYPE.WEBINAR
          ? `${dayjs(item?.startTime)?.format(defaultDateFormat)} ${dayjs(
              item?.startTime,
            )?.format(TIME_FORMAT)} to ${dayjs(item?.endTime)?.format(
              TIME_FORMAT,
            )}`
          : `${dayjs
              ?.utc(item?.startTime)
              ?.utcOffset(5.5, true)
              ?.format(defaultDateFormat)} ${dayjs
              ?.utc(item?.startTime)
              ?.utcOffset(5.5, true)
              ?.format(TIME_FORMAT)} to ${dayjs
              ?.utc(item?.endTime)
              ?.utcOffset(5.5, true)
              ?.format(TIME_FORMAT)}`,
    },
    {
      title: UI_LABELS.ACTION,
      key: 'action',
      width: '20%',
      render: (item) => (
        <Button
          type="link"
          className="action-btn"
          onClick={() => setVideoUrl(item?.url)}
        >
          {UI_LABELS.VIEW_RECORDING}
        </Button>
      ),
    },
  ];

  return (
    <div className="pt-16">
      <Title level={4}>{UI_LABELS.BOOKING_DETAILS}</Title>
      <Table
        columns={columnsView}
        rowKey={(record) => record?.id}
        dataSource={listData}
        loading={loading}
        pagination={{
          total: totalCount,
          current: tableParams?.pagination?.current,
          pageSize: tableParams?.pagination?.pageSize,
        }}
        onChange={handleTableChange}
      />
      {videoUrl && (
        <CallRecordingViewer
          videoUrl={videoUrl}
          handleClose={() => setVideoUrl(null)}
        />
      )}
    </div>
  );
}

export default CallPurchaserList;
