import { Button, Col, Modal, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React from 'react';
import {
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_LABELS,
  TRANSACTION_TYPE,
  transactionDate,
} from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { noticeBoardPlanTitle } from '../../../common/utils';

export default function TransactionDetails({
  setShowModal,
  showModal,
  orderDetails,
  getTransactionType,
  handleRegenerateInvoice,
  loading,
}) {
  return (
    <>
      <Modal
        title={UI_LABELS.ORDER_DETAILS}
        centered
        closable
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          orderDetails?.invoiceUrl || orderDetails?.amount === 0 ? (
            <Button
              key="submit"
              type="primary"
              onClick={() =>
                orderDetails?.invoiceUrl &&
                // eslint-disable-next-line no-undef
                window.open(orderDetails.invoiceUrl)
              }
              disabled={!orderDetails?.invoiceUrl || orderDetails?.amount === 0}
            >
              {UI_LABELS.DOWNLOAD_INVOICE}
            </Button>
          ) : (
            <Button
              onClick={handleRegenerateInvoice}
              loading={loading}
              disabled={loading}
            >
              {UI_LABELS.REGENERATE_INVOICE}
            </Button>
          ),
        ]}
      >
        <Row gutter={[16, 16]} className="mt-24">
          <Col span={12}>{UI_LABELS.PURCHASER_NAME}: </Col>
          <Col span={12}>
            {orderDetails?.user
              ? `${orderDetails?.user?.firstName} ${orderDetails?.user?.lastName}`
              : `${orderDetails?.billing?.firstName} ${orderDetails?.billing?.lastName}`}
          </Col>
          <Col span={12}>{UI_LABELS.TYPE}:</Col>
          <Col span={12}>{capitalize(orderDetails?.type)}</Col>
          <Col span={12}>{UI_LABELS.ITEM}:</Col>
          <Col span={12}>
            {orderDetails?.type === TRANSACTION_TYPE.SUBSCRIPTION
              ? noticeBoardPlanTitle(
                  orderDetails?.[getTransactionType(orderDetails?.type)],
                )
              : orderDetails?.[getTransactionType(orderDetails?.type)]?.title}
          </Col>
          <Col span={12}>{UI_LABELS.STATUS}:</Col>
          <Col span={12}>
            <Tag color={TRANSACTION_STATUS_COLOR?.[orderDetails?.status]}>
              {TRANSACTION_STATUS_LABELS?.[orderDetails?.status]}
            </Tag>
          </Col>
          <Col span={12}>{UI_LABELS.PRICE}:</Col>
          <Col span={12}>₹ {orderDetails?.amount?.toFixed(2)}</Col>
          <Col span={12}>{UI_LABELS.ORDER_PLACED_ON}:</Col>
          <Col span={12}>
            {dayjs(orderDetails?.createdAt)?.format(transactionDate)}
          </Col>
        </Row>
      </Modal>
    </>
  );
}
