import { PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Checkbox, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import {
  COMMON_QUERY_PARAMS,
  dateTimeFormat,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS,
  TEMPLATE_STATUS_COLOR,
  TEMPLATE_TYPE,
  USER_TYPE,
  USER_TYPE_LABEL,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import { GET_CATEGORIES } from '../category/graphql/queries';
import { FETCH_TEMPLATE_LIST } from './graphql/queries';
import './template.less';

const USER_TYPE_FILTER = [
  {
    text: UI_LABELS.SUPER_ADMIN,
    value: USER_TYPE.SUPER_ADMIN,
  },
  {
    text: UI_LABELS.ADMIN,
    value: USER_TYPE.ADMIN,
  },
  {
    text: UI_LABELS.STAFF,
    value: USER_TYPE.STAFF,
  },
  {
    text: UI_LABELS.PREMIUM_CONSULTANT,
    value: USER_TYPE.PREMIUM_CONSULTANT,
  },
  {
    text: UI_LABELS.ADVISOR,
    value: USER_TYPE.ADVISER,
  },
];

const TEMPLATE_TYPE_FILTER = [
  {
    text: UI_LABELS.FREE,
    value: TEMPLATE_TYPE.FREE,
  },
  {
    text: UI_LABELS.PAID,
    value: TEMPLATE_TYPE.PAID,
  },
  {
    text: UI_LABELS.PREMIUM,
    value: TEMPLATE_TYPE.PREMIUM,
  },
];

const TEMPLATE_STATUS_FILTER = [
  {
    text: UI_LABELS.DRAFT,
    value: TEMPLATE_STATUS.DRAFT,
  },
  {
    text: UI_LABELS.PUBLISHED,
    value: TEMPLATE_STATUS.PUBLISHED,
  },
  {
    text: UI_LABELS.UNPUBLISHED,
    value: TEMPLATE_STATUS.UNPUBLISHED,
  },
  {
    text: UI_LABELS.REJECTED,
    value: TEMPLATE_STATUS.REJECTED,
  },
];
const { Title } = Typography;

const fieldToParams = {
  title: COMMON_QUERY_PARAMS.SORT_TITLE,
  creatorRoles: COMMON_QUERY_PARAMS.SORT_CREATOR,
  price: COMMON_QUERY_PARAMS.PRICE,
};

function TemplateList() {
  const { getCurrentUserRole } = useContext(AppContext);
  const { getQueryParams, setQueryParams } = useUrlQuery();

  const roles = getCurrentUserRole() || {};
  const { navigate } = useRouter();
  const location = useLocation();

  const [userList, setUserList] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      categoryId: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY_ID,
        defaultValue: null,
      },
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      status: {
        queryString: COMMON_QUERY_PARAMS.STATUS,
        defaultValue: [],
      },
      subCategoryId: {
        queryString: COMMON_QUERY_PARAMS.SUB_CATEGORY_ID,
        defaultValue: null,
      },
      types: {
        queryString: COMMON_QUERY_PARAMS.TYPES,
        defaultValue: [],
      },
      userId: {
        queryString: COMMON_QUERY_PARAMS.USER_ID,
        defaultValue: null,
      },
      creatorRoles: {
        queryString: COMMON_QUERY_PARAMS.ROLES,
        defaultValue: [],
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [fetchTemplates] = useLazyQuery(FETCH_TEMPLATE_LIST);

  function fetchTemplatesCall() {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoading(true);
    fetchTemplates({
      variables: prepareVariable,
      fetchPolicy: 'network-only',
      onCompleted: ({ templatesAdmin }) => {
        setUserList(templatesAdmin?.data);
        setCount(templatesAdmin?.count);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchTemplatesCall();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);
  /* keep for future use */
  // const [deleteTemplateCall] = useMutation(DELETE_TEMPLATE);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.STATUS]:
          filters.status && Object.values(filters.status)?.length
            ? filters.status
            : null,
        [COMMON_QUERY_PARAMS.ROLES]:
          filters?.creatorRoles && filters?.creatorRoles?.length
            ? filters.creatorRoles
            : null,
        [COMMON_QUERY_PARAMS.TYPES]:
          filters.types && filters.types.length ? filters.types : null,
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.CATEGORY_ID]: filters.categoryId
          ? filters.categoryId
          : null,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  /* keep for future use */
  /* const handleDelete = (id) => {
    confirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        deleteTemplateCall({
          variables: {
            where: {
              id,
            },
          },
          onCompleted: () => {
            fetchTemplatesCall(params);
          },
          onError: () => {},
        });
      },
      onCancel() {},
    });
  }; */

  const renderBadge = (item) => {
    switch (item) {
      case TEMPLATE_STATUS.DRAFT:
        return <Badge count="Draft" color={TEMPLATE_STATUS_COLOR.DRAFT} />;
      case TEMPLATE_STATUS.PUBLISHED:
        return (
          <Badge count="Published" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
        );
      case TEMPLATE_STATUS.UNPUBLISHED:
        return (
          <Badge
            count="Unpublished"
            color={TEMPLATE_STATUS_COLOR.UNPUBLISHED}
          />
        );
      default:
        return <Badge count="Rejected" />;
    }
  };

  const getUserTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USER_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getTemplateTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TEMPLATE_TYPE_FILTER?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getStatusFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TEMPLATE_STATUS_FILTER.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.text}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getProviderFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div>
          <CommonSelect
            placeholder="Select Category"
            className="full-width"
            showSearch
            allowClear
            variant="borderless"
            value={selectedKeys}
            onChange={(value) => {
              setSelectedKeys(value);
              confirm();
            }}
            query={GET_CATEGORIES}
            fetchPolicy="network-only"
            responsePath="templateCategoriesAdmin.data"
            valuePath="id"
            labelPath="name"
            optionalLabelPath="lastName"
            variables={{
              filter: {},
              sort: [
                {
                  sortBy: SORT_OPTION.DESC,
                  sortOn: 'createdAt',
                },
              ],
            }}
          />
        </div>
      </div>
    ),
  });

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.TEMPLATES}
          {count ? ` (${count})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          {roles !== USER_TYPE.ADMIN && (
            <Button
              className="ml-8"
              key="1"
              type="primary"
              icon={<PlusCircleOutlined />}
              onClick={() => {
                navigate(ROUTES.ADD_TEMPLATE);
              }}
            >
              {UI_LABELS.ADD_TEMPLATE}
            </Button>
          )}
        </div>
      </div>
      <Table
        columns={[
          {
            title: UI_LABELS.TITLE,
            dataIndex: 'title',
            key: 'title',
            sorter: { multiple: 1 },
            width: '15%',
            sortOrder: getSorter.sorters.title,
          },
          {
            title: UI_LABELS.CATEGORY,
            key: 'categoryId',
            width: '10%',
            render: (item) => item?.category?.name,
            filteredValue:
              getQueryParams?.[COMMON_QUERY_PARAMS.CATEGORY_ID] ?? null,
            ...getProviderFilterProps(),
          },
          {
            title: UI_LABELS.TEMPLATE_TYPE,
            render: (item) => {
              switch (item.type) {
                case TEMPLATE_TYPE.FREE:
                  return UI_LABELS.FREE;
                case TEMPLATE_TYPE.PAID:
                  return UI_LABELS.PAID;
                case TEMPLATE_TYPE.PREMIUM:
                  return UI_LABELS.PREMIUM;
                default:
                  break;
              }
            },
            key: 'types',
            filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.TYPES] ?? null,
            ...getTemplateTypeFilterProps(),
            width: '10%',
          },
          {
            title: `${UI_LABELS.PRICE} (INR)`,
            width: '8%',
            dataIndex: 'price',
            key: 'price',
            sorter: { multiple: 3 },
            sortOrder: getSorter.sorters.price,
          },
          {
            title: UI_LABELS.CREATED_BY,
            render: (value) =>
              value?.creator?.firstName
                ? `${value?.creator?.firstName} ${value?.creator?.lastName} (${
                    USER_TYPE_LABEL[value?.creator?.roles?.[0]] ?? '-'
                  })`
                : '-',
            key: 'creatorRoles',
            width: '15%',
            filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.ROLES] ?? null,
            ...getUserTypeFilterProps(),
          },
          {
            title: UI_LABELS.CREATED_ON,
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: '10%',
            render(date) {
              return dayjs(date).format(dateTimeFormat);
            },
          },
          {
            title: UI_LABELS.UPDATED_ON,
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '10%',
            render(date) {
              return date ? dayjs(date).format(dateTimeFormat) : '-';
            },
          },
          {
            title: UI_LABELS.STATUS,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: '10%',
            render: (item) => renderBadge(item),
            filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.STATUS] ?? null,
            ...getStatusFilterProps(),
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`${ROUTES?.TEMPLATE}/edit/${record?.id}`);
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={userList}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: count,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        scroll={{ y: `calc(100vh - 365px)` }}
        loading={loading}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
    </>
  );
}

export default TemplateList;
