/* eslint-disable no-underscore-dangle */
import {
  Card,
  Col,
  DatePicker,
  List,
  Row,
  Select,
  Spin,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import { AppContext } from '../../AppContext';
import {
  AI_GENERATIVE_OPTIONS,
  CATEGORY_OPTIONS,
  COMMON_QUERY_PARAMS,
  OTHER_FILTER_DROPDOWN,
  SORT_OPTION,
  STANDARD_DATE_FORMAT,
  USER_TYPE,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  getCourtList,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import BenchListSelect from '../../components/BenchesListSelect';
import CommonSelect from '../../components/CommonSelect';
import MongoCommonSelect from '../../components/MongoCommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import { LIST_JUDGEMENTS } from '../judgements/graphql/queries';
import AppealDetails from './components/AppealDetails';
import PDFViewer from './components/PDFViewer';
import { LIST_ASSESSMENT_YEARS, LIST_CURRENT_BENCH } from './graphql/queries';
import './review-appeals.less';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const JUDGEMENT_LIMIT = 50;

function ReviewAppeals() {
  const containerRef = useRef(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const batchId = searchParams?.get('batchId');
  const { getCurrentUserRole } = useContext(AppContext);
  const role = getCurrentUserRole() || {};
  const [initLoading, setInitLoading] = useState(true);
  const [judgements, setJudgements] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedAppeal, setSelectedAppeal] = useState(null);
  const [formData, setFormData] = useState(null);
  const [loadingJudgementDetails, setLoadingJudgementDetails] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(
    decodeURIComponent(searchParamsValue?.toString()),
  );

  const [updateJudgementAfterFilter, setUpdateJudgementAfterFilter] = useState(
    false,
  );
  const [pronounceDate, setPronounceDate] = useState(
    batchId ? null : [dayjs().subtract(1, 'month').startOf('day'), dayjs()],
  );
  const [errorMessage, setErrorMessage] = useState('');

  const category = getQueryParams?.[COMMON_QUERY_PARAMS.CATEGORY];
  const assessmentYear = getQueryParams?.[COMMON_QUERY_PARAMS.YEAR];
  const court = getQueryParams?.[COMMON_QUERY_PARAMS.COURT];
  const otherValue = getQueryParams?.[COMMON_QUERY_PARAMS.OTHER];
  const selectedBench = getQueryParams?.[COMMON_QUERY_PARAMS.BENCH];
  const selectedBenchName =
    getQueryParams?.[COMMON_QUERY_PARAMS.BENCH_NAME] ?? '';
  const startDate = getQueryParams?.[COMMON_QUERY_PARAMS.START];
  const endDate = getQueryParams?.[COMMON_QUERY_PARAMS.END];
  const selectedAiGenerative =
    getQueryParams?.[COMMON_QUERY_PARAMS.IS_AI_GENERATIVE];

  const listJudgements = async ({ variables, isNewData = false }) => {
    await mongoClient
      ?.query({
        query: LIST_JUDGEMENTS,
        variables,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        setInitLoading(false);
        setDataLoading(false);
        setLoading(false);
        setUpdateJudgementAfterFilter(false);
        const newJudgementData = [
          ...judgements,
          ...res?.data?.judgmentsAdmin?.data,
        ];
        setJudgements(
          isNewData ? res?.data?.judgmentsAdmin?.data : newJudgementData,
        );
        setHasMore(newJudgementData?.length < res?.data?.judgmentsAdmin?.count);
      });
    setDataLoading(false);
  };

  const rangeValue =
    startDate && endDate
      ? [dayjs(startDate), dayjs(endDate)]
      : pronounceDate ?? null;

  const aiFilters = {};
  const otherFilters = {};

  if (otherValue) {
    otherFilters.withOutAssessmentYear = {
      queryString: COMMON_QUERY_PARAMS.WITHOUT_ASSESSMENT_YEAR,
      defaultValue: false,
      value: otherValue === 'without_assessment_year',
    };

    otherFilters.withOutPancard = {
      queryString: COMMON_QUERY_PARAMS.WITHOUT_PANCARD,
      defaultValue: false,
      value: otherValue === 'without_pancard',
    };
  }

  if (selectedAiGenerative) {
    aiFilters[
      selectedAiGenerative === 'ai_generated'
        ? 'isAiGenerative'
        : 'withOutAIGenBatch'
    ] = {
      queryString:
        COMMON_QUERY_PARAMS[
          selectedAiGenerative === 'ai_generated'
            ? 'IS_AI_GENERATIVE'
            : 'WITHOUT_AI_GEN_BATCH'
        ],
      defaultValue: false,
      value: true,
    };
  }

  const getFilter = prepareFilterFromUrl(
    {
      limit: {
        queryString: COMMON_QUERY_PARAMS.LIMIT,
        defaultValue: JUDGEMENT_LIMIT,
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: decodeURIComponent(searchParamsValue?.toString()) ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value: JUDGEMENT_LIMIT * (currentPage - 1),
      },
      bench: {
        queryString: COMMON_QUERY_PARAMS.BENCH,
        defaultValue: [],
      },
      ...(batchId
        ? {
            batchId: {
              queryString: COMMON_QUERY_PARAMS.BATCH_ID,
              defaultValue: null,
            },
          }
        : {}),
      ...(pronounceDate
        ? {
            startDate: {
              queryString: COMMON_QUERY_PARAMS.START,
              value: getQueryParams[COMMON_QUERY_PARAMS?.START]
                ? getQueryParams[COMMON_QUERY_PARAMS?.START]
                : null,
            },
            endDate: {
              queryString: COMMON_QUERY_PARAMS.END,
              value: getQueryParams[COMMON_QUERY_PARAMS?.END]
                ? getQueryParams[COMMON_QUERY_PARAMS?.END]
                : null,
            },
          }
        : {}),
      ...(category
        ? {
            category: {
              queryString: COMMON_QUERY_PARAMS.CATEGORY,
              defaultValue: null,
            },
          }
        : {}),
      ...(assessmentYear
        ? {
            assessmentYear: {
              queryString: COMMON_QUERY_PARAMS.YEAR,
              defaultValue: null,
            },
          }
        : {}),
      ...(court
        ? {
            court: {
              queryString: COMMON_QUERY_PARAMS.COURT,
              defaultValue: null,
            },
          }
        : {}),
      ...otherFilters,
      ...aiFilters,
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(
    {},
    {
      sortOn: 'createdAt',
      sortBy: SORT_OPTION.DESC,
    },
  );
  const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };

  useEffect(() => {
    setSearch(decodeURIComponent(searchParamsValue?.toString()));
    if (updateJudgementAfterFilter) {
      setCurrentPage(1);
      return;
    }
    listJudgements({
      variables: prepareVariable,
    });
  }, [location.search, currentPage, pronounceDate]);

  const fetchInitialList = () => {
    setJudgements([]);
    setLoading(true);
    setSelectedAppeal(null);
    setFormData(null);
    listJudgements({
      variables: prepareVariable,
      isNewData: true,
    });
  };

  const onLoadMore = () => {
    setLoading(true);
    setCurrentPage((prev) => prev + 1);
  };
  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef?.current;
      if (container) {
        const bottom =
          container.scrollHeight ===
          container.scrollTop + container.clientHeight;
        if (bottom && hasMore && !loading) {
          onLoadMore();
        }
      }
    };

    const container = containerRef?.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [hasMore, loading]);

  const resetReviewAppealList = () => {
    setHasMore(false);
    setJudgements([]);
    setSelectedAppeal(null);
    setFormData(null);
    setCurrentPage(1);
  };

  const handleStaffBench = (value, record) => {
    setDataLoading(true);
    resetReviewAppealList();
    setQueryParams({
      [COMMON_QUERY_PARAMS.BENCH]: value,
      [COMMON_QUERY_PARAMS.BENCH_NAME]: record?.label,
    });
  };

  const handleAdminBench = (selectedValue, record) => {
    setDataLoading(true);
    resetReviewAppealList();
    setQueryParams({
      [COMMON_QUERY_PARAMS.BENCH]: selectedValue,
      [COMMON_QUERY_PARAMS.BENCH_NAME]: record?.label,
    });
  };

  const handleSearchChange = debounce((value) => {
    const wordCount = value?.split(/\s+/)?.filter(Boolean)?.length;
    const charCount = value?.length;
    if (charCount > 100) {
      setErrorMessage('Maximum 100 characters allowed');
    } else if (wordCount > 10) {
      setErrorMessage('Maximum 10 words allowed');
    } else {
      setErrorMessage('');
      setDataLoading(true);
      resetReviewAppealList();
      setQueryParams(
        {
          [COMMON_QUERY_PARAMS.SEARCH]: encodeURIComponent(value.trim()),
          [COMMON_QUERY_PARAMS.PAGE]: null,
        },
        true,
      );
    }
  }, 1000);

  const handleChange = (e) => {
    setDataLoading(true);
    resetReviewAppealList();
    if (otherValue === 'without_assessment_year') {
      setQueryParams({ [COMMON_QUERY_PARAMS.OTHER]: null });
    }
    setQueryParams({ [COMMON_QUERY_PARAMS.YEAR]: e });
  };

  const handleOtherChanges = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    if (value === 'without_assessment_year') {
      setQueryParams({ [COMMON_QUERY_PARAMS.YEAR]: null });
    }
    setQueryParams({ [COMMON_QUERY_PARAMS.OTHER]: value });
  };

  const handleChangePronounceDate = (date) => {
    setDataLoading(true);
    resetReviewAppealList();
    setPronounceDate(date);
    setQueryParams({
      [COMMON_QUERY_PARAMS.START]:
        date && dayjs(date?.[0])?.startOf('day').toISOString(),
      [COMMON_QUERY_PARAMS.END]:
        date && dayjs(date?.[1])?.endOf('day').toISOString(),
    });
  };

  useEffect(() => {
    setCurrentPage(1);
    if (updateJudgementAfterFilter) {
      listJudgements({
        variables: prepareVariable,
      });
    }
  }, [updateJudgementAfterFilter]);

  const handleUpdateJudgementForm = () => {
    if (
      otherValue === 'without_assessment_year' ||
      otherValue === 'without_pancard'
    ) {
      setDataLoading(true);
      setJudgements([]);
      setSelectedAppeal(null);
      setFormData(null);
      setCurrentPage(0);
      setUpdateJudgementAfterFilter(true);
    }
  };

  const handleCourtChange = (value) => {
    setDataLoading(true);
    resetReviewAppealList();
    let finalValue = null;
    if (value) {
      finalValue = value;
    }
    setQueryParams({ [COMMON_QUERY_PARAMS.COURT]: finalValue });
  };

  const handleCategoryChange = (value) => {
    setDataLoading(true);
    setQueryParams({ [COMMON_QUERY_PARAMS.COURT]: null });
    resetReviewAppealList();
    let finalValue = null;
    if (value) {
      finalValue = value;
    }
    setQueryParams({ [COMMON_QUERY_PARAMS.CATEGORY]: finalValue });
  };

  const handleAiGenerateChange = (value) => {
    resetReviewAppealList();
    setDataLoading(true);
    setQueryParams({ [COMMON_QUERY_PARAMS.IS_AI_GENERATIVE]: value });
  };

  const [courtList, setCourtList] = useState([]);

  const getCourts = async () => {
    const data = await getCourtList({
      variables: { category },
    });
    setCourtList(data);
  };

  useEffect(() => {
    if (category) {
      getCourts();
    }
  }, [category]);

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {UI_LABELS.REVIEW_APPEALS}
      </Title>
      <div className="justify-start mb-12 d-flex flex-wrap gap-12">
        <div className="d-flex gap-12">
          <div className="d-flex gap-12 align-center review-appeals-filters">
            <MongoCommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Assessment Year"
              className="role-select-in-calls ml-8"
              showSearch
              allowClear
              query={LIST_ASSESSMENT_YEARS}
              fetchPolicy="network-only"
              responsePath="data.assessmentYearList"
              valuePath="assessmentYear"
              labelPath="assessmentYear"
              onChange={(e) => handleChange(e)}
              variables={{}}
              value={assessmentYear}
            />
          </div>
        </div>
        <div className="d-flex gap-12 align-center review-appeals-filters">
          <RangePicker
            allowClear
            className="role-select-in-calls ml-8"
            onChange={handleChangePronounceDate}
            format={STANDARD_DATE_FORMAT}
            value={rangeValue}
            placeholder={['Start Date', 'End Date']}
          />
        </div>
        <div className="d-flex gap-12 align-center flex-wrap">
          <Select
            allowClear
            placeholder="Select Category"
            onChange={handleCategoryChange}
            value={category}
            className="role-select-in-calls ml-8"
          >
            {CATEGORY_OPTIONS?.map((e) => (
              <Select.Option value={e.value} key={e.value}>
                {e.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            allowClear
            placeholder="Select Court"
            title="Please Select Category to Select Court"
            onChange={handleCourtChange}
            value={court}
            disabled={!category}
            className="role-select-in-calls ml-8"
          >
            {courtList?.map((e) => (
              <Select.Option value={e?.value} key={e?.value}>
                {e?.label}
              </Select.Option>
            ))}
          </Select>
          <Select
            allowClear
            placeholder="Select AI Generative"
            options={AI_GENERATIVE_OPTIONS}
            value={selectedAiGenerative}
            onChange={handleAiGenerateChange}
          />
          {role === USER_TYPE.STAFF && (
            <CommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Benches"
              className="role-select-in-calls ml-8"
              showSearch
              allowClear
              query={LIST_CURRENT_BENCH}
              fetchPolicy="network-only"
              responsePath="currentUserBenches.data"
              valuePath="benchId"
              labelPath="benchSlug"
              onChange={handleStaffBench}
              variables={{
                filter: {
                  [COMMON_QUERY_PARAMS.LIMIT]: 10,
                  [COMMON_QUERY_PARAMS.SKIP]: 0,
                },
                sort: [
                  {
                    sortBy: 'ASC',
                    sortOn: 'createdAt',
                  },
                ],
              }}
              value={selectedBench}
              provider={
                selectedBench
                  ? {
                      benchId: selectedBench,
                      benchSlug: selectedBenchName,
                    }
                  : null
              }
              isReviewAppealStaff
            />
          )}
          {(role === USER_TYPE.SUPER_ADMIN || role === USER_TYPE.ADMIN) && (
            <BenchListSelect
              onChange={handleAdminBench}
              values={selectedBench}
              provider={
                selectedBench
                  ? {
                      _id: selectedBench,
                      value: selectedBenchName,
                    }
                  : null
              }
            />
          )}
          <Select
            allowClear
            placeholder="Other"
            onChange={handleOtherChanges}
            value={otherValue}
            className="role-select-in-calls ml-8"
          >
            {OTHER_FILTER_DROPDOWN?.map((e) => (
              <Select.Option
                value={e.value}
                key={e.value}
                disabled={
                  (assessmentYear && e.value === 'without_assessment_year') ||
                  (assessmentYear && e.value === 'misc')
                }
              >
                {e.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <Row className="review-appeal-card-wrapper">
        <Col xs={24} sm={24} md={12} lg={7}>
          <Card className="full-height review-appeal-card">
            <div className="review-appeal-header">
              <span className="review-appeal-header-text">
                {UI_LABELS.APPEAL_LIST}
              </span>
            </div>
            <div className="review-appeal-search-bar">
              <SearchComponent
                className="list-search"
                getData={handleSearchChange}
                disabled={search === '' && (initLoading || dataLoading)}
                placeholder="Search Appeal"
                status={errorMessage ? 'error' : ''}
                handleLiveChange={(e) => setSearch(e)}
                value={search}
              />
              {errorMessage && (
                <div className="search-error-block">{errorMessage}</div>
              )}
            </div>
            <div className="review-appeal-content " ref={containerRef}>
              {initLoading || dataLoading ? (
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              ) : (
                <>
                  <List
                    dataSource={judgements}
                    renderItem={(item) => (
                      <List.Item
                        className={`review-appeal-content-list-item ${
                          selectedAppeal === item?.id
                            ? 'review-appeal-content-list-item-selected'
                            : null
                        }`}
                        key={item?.id}
                        onClick={() => {
                          if (selectedAppeal === item?.id) return;
                          setSelectedAppeal(item?.id);
                          setLoadingJudgementDetails(true);
                        }}
                      >
                        {`${item?.appellant} V. ${item?.respondent}`}
                      </List.Item>
                    )}
                  />
                  {loading && (
                    <div className="review-appeal-content-list-spin">
                      <Spin />
                    </div>
                  )}
                </>
              )}
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8}>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? null : 'review-appeal-card-loading'
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  {UI_LABELS.APPEAL_DETAILS}
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                <div className="review-appeal-loader">
                  <Spin size="large" />
                </div>
              </div>
            </Card>
          </>
          <>
            <Card
              className={`full-height review-appeal-card ${
                loadingJudgementDetails ? 'review-appeal-card-loading' : null
              }`}
            >
              <div className="review-appeal-header">
                <span className="review-appeal-header-text">
                  {UI_LABELS.APPEAL_DETAILS}
                </span>
              </div>
              <div className="review-appeal-content mt-8">
                {selectedAppeal && (
                  <>
                    <AppealDetails
                      key={selectedAppeal}
                      id={selectedAppeal}
                      getFormData={(val) => {
                        setFormData(val);
                        setLoadingJudgementDetails(false);
                      }}
                      getJudgementLoadingValues={(val) =>
                        setLoadingJudgementDetails(val)
                      }
                      onUpdateJudgement={handleUpdateJudgementForm}
                      onAssesseeUpdate={handleUpdateJudgementForm}
                      fetchInitialList={fetchInitialList}
                    />
                  </>
                )}
              </div>
            </Card>
          </>
        </Col>
        <Col xs={24} sm={24} md={24} lg={9}>
          <Card className="full-height review-appeal-card-pdf">
            {/* eslint-disable-next-line no-nested-ternary */}
            {loadingJudgementDetails ? (
              <div className="review-appeal-loader-pdf">
                <Spin size="large" />
              </div>
            ) : (
              formData?.pdfUrl && (
                <PDFViewer fileUrl={formData?.pdfUrl ?? null} />
              )
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
export default ReviewAppeals;
