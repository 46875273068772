import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  dateTimeFormat,
  JUDGES_TYPE,
  JUDGES_TYPE_OPTIONS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT_OPTION,
  TAX_CATEGORIES,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  getCourtList,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import MergeDataModal from './components/MergeDataModal';
import { DELETE_JUDGE } from './graphql/mutations';
import { LIST_JUDGES } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const visibilityOptions = [
  {
    label: UI_LABELS.VISIBLE,
    value: true,
  },
  {
    label: UI_LABELS.NOT_VISIBLE,
    value: false,
  },
];

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  name: COMMON_QUERY_PARAMS.SORT_NAME,
};

function JudgesList() {
  const { navigate } = useRouter();
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const { category } = useParams();

  const [judgesData, setJudgesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMergeData, setIsMergeData] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());
  const visibleStatus = getQueryParams?.[COMMON_QUERY_PARAMS.IS_VISIBLE];

  const isVisible = visibleStatus ? visibleStatus === UI_LABELS.VISIBLE : null;

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      isVisible: {
        queryString: COMMON_QUERY_PARAMS.IS_VISIBLE,
        defaultValue: true,
        value: isVisible ?? null,
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
        value: CATEGORIES_KEYS[category],
      },
      court: {
        queryString: COMMON_QUERY_PARAMS.COURT,
        defaultValue: null,
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      type: {
        queryString: COMMON_QUERY_PARAMS.JUDGE_TYPE,
        defaultValue: null,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const getCourts = async (categoryType) => {
    const data = await getCourtList({
      variables: { category: CATEGORIES_KEYS[categoryType] },
    });
    setCourtList(data);
  };

  useEffect(() => {
    getCourts(category);
  }, [category]);

  const listJudges = async () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    await mongoClient
      ?.query({
        query: LIST_JUDGES,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      })
      .then((res) => {
        setJudgesData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listJudges();
    setSearch(searchParamsValue?.toString());
  }, [location.search, location.pathname]);

  const fetchJudgeCall = () => {
    setLoading(true);
    const prepareFilterValue = {
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.SEARCH]: '',
    };
    if (typeof getQueryParams[COMMON_QUERY_PARAMS.IS_VISIBLE] === 'boolean') {
      prepareFilterValue[COMMON_QUERY_PARAMS.IS_VISIBLE] =
        getQueryParams[COMMON_QUERY_PARAMS.IS_VISIBLE];
    }
    setQueryParams(prepareFilterValue);
  };

  const deleteCall = async (id) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_JUDGE,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        const prepareFilterValue = {
          [COMMON_QUERY_PARAMS.PAGE]: null,
          [COMMON_QUERY_PARAMS.SEARCH]: '',
          [COMMON_QUERY_PARAMS.CATEGORY]: CATEGORIES_KEYS[category],
        };
        if (
          typeof getQueryParams[COMMON_QUERY_PARAMS.IS_VISIBLE] === 'boolean'
        ) {
          prepareFilterValue[COMMON_QUERY_PARAMS.IS_VISIBLE] =
            getQueryParams[COMMON_QUERY_PARAMS.IS_VISIBLE];
        }
        setQueryParams(prepareFilterValue);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.COURT]: filters?.court ?? null,
        [COMMON_QUERY_PARAMS.IS_VISIBLE]: filters?.isVisible ?? null,
        [COMMON_QUERY_PARAMS.JUDGE_TYPE]: filters?.type ?? null,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });

      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {courtList?.map((court) => (
          <div key={court?.value}>
            <Radio
              checked={selectedKeys === court?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? court?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {court?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const filterVisibilityPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {visibilityOptions?.map((item) => (
          <div key={item?.label}>
            <Radio
              value={item?.label}
              checked={selectedKeys === item?.label}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? item?.label : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {item.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getJudgeTypeFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {JUDGES_TYPE_OPTIONS?.map((option) => (
          <div key={option?.key} className="mt-6 mb-6">
            <Radio
              value={option?.key}
              checked={selectedKeys === option?.key}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.key : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure to delete?',
      icon: <ExclamationCircleFilled />,
      okText: UI_LABELS.YES,
      okType: 'danger',
      cancelText: UI_LABELS.NO,
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: UI_LABELS.NAME,
      dataIndex: 'name',
      key: 'name',
      sorter: { multiple: 1 },
      sortOrder: getSorter.sorters.name,
    },
    // kept for future use
    /* {
      title: UI_LABELS.DESCRIPTION,
      dataIndex: 'description',
      render(description) {
        return description ?? '-';
      },
    }, */
    {
      title: UI_LABELS.CATEGORIES,
      dataIndex: 'category',
      render(categories) {
        return categories?.map((item) => TAX_CATEGORIES[item]).join(', ');
      },
    },
    {
      title: UI_LABELS.COURT,
      dataIndex: 'court',
      render(court) {
        return court?.length > 0
          ? court?.map((item) => item.name).join(', ')
          : '-';
      },
      ellipsis: true,
      key: 'court',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.COURT] ?? null,
      ...filterCourtPopup(),
    },
    {
      title: UI_LABELS.TYPE,
      dataIndex: 'type',
      className: 'judge-type',
      render(type) {
        return type ? JUDGES_TYPE[type] : '-';
      },
      key: 'type',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.JUDGE_TYPE] ?? null,
      ...getJudgeTypeFilterProps(),
    },
    {
      title: UI_LABELS.IS_VISIBLE,
      dataIndex: 'isVisible',
      filterMultiple: false,
      render(value) {
        return value ? 'True' : 'False';
      },
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.IS_VISIBLE] ?? null,
      ...filterVisibilityPopup(),
    },
    {
      title: UI_LABELS.CREATED_ON,
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      width: '10%',
      render(date) {
        return dayjs(date).format(dateTimeFormat);
      },
    },
    {
      title: UI_LABELS.UPDATED_ON,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '10%',
      render(date) {
        return date ? dayjs(date).format(dateTimeFormat) : '-';
      },
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      render: (_, { _id: id } = {}) => (
        <div className="category-action">
          <Link to={`/${category}${ROUTES.EDIT_JUDGE}${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            {UI_LABELS.DELETE}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.JUDGES}
          {judgesData?.judgesAdmin?.count
            ? ` (${judgesData?.judgesAdmin?.count})`
            : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            key={category}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />

          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            {UI_LABELS.MERGE_DATA}
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              navigate(`/${category}${ROUTES?.ADD_JUDGE}`);
            }}
          >
            {UI_LABELS.ADD_JUDGE}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={judgesData?.judgesAdmin?.data}
        pagination={{
          total: judgesData?.judgesAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
          fetchJudgeCall={fetchJudgeCall}
        />
      )}
    </>
  );
}

export default JudgesList;
