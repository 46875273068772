import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT_OPTION,
  TAX_CATEGORIES,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  getCourtList,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import MergeDataModal from './components/MergeDataModal';
import { DELETE_ASSESSEE } from './graphql/mutations';
import { LIST_ASSESSEESS } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  name: COMMON_QUERY_PARAMS.SORT_NAME,
};
function AssessesList() {
  const { navigate } = useRouter();
  const location = useLocation();
  const { category } = useParams();

  const [assesses, setAsseesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isMergeData, setIsMergeData] = useState(false);
  const [courtList, setCourtList] = useState([]);
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY_TYPE,
        defaultValue: [],
        value: CATEGORIES_KEYS[category],
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      court: {
        queryString: COMMON_QUERY_PARAMS.COURT,
        defaultValue: null,
      },
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );
  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const getCourts = async (categoryType) => {
    const data = await getCourtList({
      variables: { category: CATEGORIES_KEYS[categoryType] },
    });
    setCourtList(data);
  };

  useEffect(() => {
    getCourts(category);
  }, [category]);

  const listAssessees = async () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };

    await mongoClient
      ?.query({
        query: LIST_ASSESSEESS,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      })
      .then((res) => {
        setLoading(false);
        setAsseesses(res?.data);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    listAssessees();
    setSearch(searchParamsValue?.toString());
  }, [location.search, location.pathname]);

  const deleteCall = async (id) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_ASSESSEE,
        variables: {
          where: {
            id,
          },
        },
      })
      ?.then(() => {
        setQueryParams({
          [COMMON_QUERY_PARAMS.PAGE]: null,
          [COMMON_QUERY_PARAMS.SEARCH]: '',
          [COMMON_QUERY_PARAMS.CATEGORY]: CATEGORIES_KEYS[category],
        });
        setLoading(false);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.COURT]: filters?.court?.[0] ?? null,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });

      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const filterCourtPopup = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {courtList?.map((court) => (
          <div key={court?.value}>
            <Radio
              checked={selectedKeys.includes(court?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? [court?.value] : [];
                setSelectedKeys(updatedKeys);
              }}
            >
              {court?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => confirm()}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure you want to delete?',
      icon: <ExclamationCircleFilled />,
      okText: UI_LABELS.YES,
      okType: 'danger',
      cancelText: UI_LABELS.NO,
      onOk() {
        deleteCall(id);
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: UI_LABELS.NAME,
      key: 'name',
      sorter: { multiple: 1 },
      sortOrder: getSorter.sorters.name,
      dataIndex: 'name',
    },
    {
      title: UI_LABELS.CATEGORIES,
      dataIndex: 'category',
      render(categories) {
        return categories?.map((item) => TAX_CATEGORIES?.[item])?.join(', ');
      },
    },
    {
      title: UI_LABELS.COURT,
      dataIndex: 'court',
      render(court) {
        return court?.length > 0
          ? court?.map((item) => item.name)?.join(', ')
          : '-';
      },
      ellipsis: true,
      key: 'court',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.COURT] ?? null,
      ...filterCourtPopup(),
    },
    {
      title: UI_LABELS.TYPE,
      dataIndex: 'type',
      render(type) {
        return type ?? '-';
      },
    },
    {
      title: UI_LABELS.INITIAL_OF_SURNAME_OR_TITLE,
      dataIndex: 'initialOfSurnameOrTitle',
      render(name) {
        return name || '-';
      },
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '_id',
      fixed: 'right',
      render: (_, { _id: id } = {}) => (
        <div className="category-action">
          <Link to={`/${category}${ROUTES.EDIT_ASSESSES}/${id}`}>Edit</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            {UI_LABELS.DELETE}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.ASSESSEES}
          {assesses?.assesseesAdmin?.count
            ? ` (${assesses?.assesseesAdmin?.count})`
            : ''}
        </Title>
        <div className="filter-input  mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            key={category}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />

          <Button
            className="ml-8"
            type="primary"
            onClick={() => setIsMergeData(true)}
          >
            {UI_LABELS.EDIT_ASSESSEE}
          </Button>
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            type="primary"
            onClick={() => {
              navigate(`/${category}${ROUTES?.ADD_ASSESSES}`);
            }}
          >
            {UI_LABELS.ADD_ASSESSEE}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={assesses?.assesseesAdmin?.data}
        pagination={{
          total: assesses?.assesseesAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
      {isMergeData && (
        <MergeDataModal
          open={isMergeData}
          onCancel={() => setIsMergeData(false)}
        />
      )}
    </>
  );
}

export default AssessesList;
