import { gql } from '@apollo/client';

const LIST_USER_QUERY = gql`
  query ListUsers($filter: QuerySearchInput!, $sort: [ListUserSort]) {
    listUsers(filter: $filter, sort: $sort) {
      count
      user {
        id
        email
        firstName
        lastName
        isActive
        address
        roles
        username
        city
        companyName
        phoneNumber
        pinCode
        profession
        profileImage
        state
        tempEmail
        phoneNumberVerifiedAt
        createdAt
        migratedFrom
        userCreator {
          firstName
          lastName
          roles
        }
        activeSubscriptions {
          id
          status
        }
      }
    }
  }
`;

const FETCH_USER_BY_ID_QUERY = gql`
  query user($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      email
      firstName
      lastName
      phoneNumber
      roles
      phoneNumberVerifiedAt
      tempEmail
      isActive
      city
      state
      profession
      subscribeToNewsLettersAt
      companyName
      migratedFrom
      userBenches {
        benchKey
        benchSlug
        benchId
        createdAt
        updatedAt
        id
        userId
      }
    }
  }
`;

const FETCH_BENCH_LIST = gql`
  query Benches($filter: BenchesFilter!, $sort: [BenchesSort]) {
    benches(filter: $filter, sort: $sort) {
      _id
      createdAt
      id
      key
      slug
      updatedAt
      value
    }
  }
`;
export { FETCH_BENCH_LIST, FETCH_USER_BY_ID_QUERY, LIST_USER_QUERY };
