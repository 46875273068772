import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Select } from 'antd';
import React, { useEffect, useState } from 'react';

const { Option } = Select;

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 50 }, (_, i) => {
  const startYear = currentYear - i - 1;
  const endYear = currentYear - i;
  return `FY ${startYear}-${endYear}`;
});

const CustomYearDropdown = ({
  onChange,
  selectedValue,
  placeholder = 'Select Year',
}) => {
  const [selectedYears, setSelectedYears] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    setSelectedYears(selectedValue);
  }, [selectedValue]);

  const handleSelectChange = (value) => {
    setSelectedYears(value);
    onChange?.(value);
    setSelectAll(value?.length === years?.length + 1);
  };

  const handleSelectAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      setSelectedYears([...years.map(String)]);
      onChange?.([...years.map(String)]);
    } else {
      setSelectedYears([]);
      onChange?.([]);
    }
    setSelectAll(checked);
  };

  const handleYearChange = (year) => {
    const yearString = String(year);
    const newSelectedYears = selectedYears?.includes(yearString)
      ? selectedYears?.filter((item) => item !== yearString)
      : [...selectedYears, yearString];

    if (newSelectedYears?.length === years?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }

    setSelectedYears(newSelectedYears);
    onChange?.(newSelectedYears);
  };

  const tagRender = (props) => (
    <div className="custom-tags">
      <span className="body-meta">{props.value}</span>
      <span onClick={props.onClose} className="close-button">
        <CloseOutlined className="close-icon" />
      </span>
    </div>
  );

  return (
    <Select
      mode="multiple"
      value={selectedYears}
      onChange={handleSelectChange}
      placeholder={placeholder}
      allowClear
      showSearch
      maxTagCount="responsive"
      dropdownRender={(menu) => (
        <>
          <div className="custom-option pt-8 pb-8 pr-12 pl-12">
            <Checkbox checked={selectAll} onChange={handleSelectAllChange}>
              Select All
            </Checkbox>
          </div>
          {menu}
        </>
      )}
      tagRender={tagRender}
    >
      {years.map((year) => (
        <Option
          key={year}
          value={String(year)}
          className="custom-option pt-8 pb-8 pr-12 pl-12"
        >
          <div className="d-flex align-center gap-8">
            <Checkbox
              checked={selectedYears?.includes(String(year))}
              onChange={() => handleYearChange(year)}
            />
            <span>{year}</span>
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default CustomYearDropdown;
