import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import { ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import useRouter from '../../hooks/useRouter';
import JudgeForm from './components/JudgeForm';
import { CREATE_JUDGE } from './graphql/mutations';

function CreateJudge({
  isModalView = false,
  styling,
  handleCancel,
  isReviewAppeal = false,
}) {
  const defaultStyling = { sm: 12, md: 8, lg: 6 };
  const { navigate, navigateWithBack } = useRouter();
  const [loading, setLoading] = useState(false);
  const { category: categoryType } = useParams();
  const [form] = Form.useForm();
  const createJudge = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: CREATE_JUDGE,
        variables,
      })
      ?.then(() => {
        if (!isReviewAppeal) navigate(-1);
        setLoading(false);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    createJudge({
      variables: {
        data: { ...values },
      },
    });
    if (isModalView) handleCancel?.();
  };
  return (
    <>
      {!isModalView && (
        <div className="title">
          <Button
            type="text"
            shape="circle"
            onClick={() => navigateWithBack(`/${categoryType}${ROUTES.JUDGES}`)}
            icon={<ArrowLeftOutlined />}
          />
          {UI_LABELS.ADD_JUDGE}
        </div>
      )}
      <JudgeForm
        form={form}
        onFinish={onFinish}
        loading={loading}
        loadingJudgeDetails={false}
        styling={styling ?? defaultStyling}
        isModalView={isModalView}
        handleCancel={() => handleCancel?.()}
      />
    </>
  );
}

export default CreateJudge;
