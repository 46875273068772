import { LockOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Logo } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { formValidatorRules } from '../../common/utils';
import AuthFooter from '../../components/AuthFooter';
import useRouter from '../../hooks/useRouter';
import { SET_PASSWORD } from './graphql/mutations';
import { VALIDATE_TOKEN } from './graphql/queries';

function SetPassword() {
  const [searchParams] = useSearchParams();
  const userId = searchParams?.get('uid');
  const token = searchParams?.get('token');
  const { navigate } = useRouter();
  const [form] = Form.useForm();
  const { password, required } = formValidatorRules;
  const [setPasswordMutation, { loading: loginLoading }] = useMutation(
    SET_PASSWORD,
    {
      onError() {}, // Always write this method for error handling in all mutation.
    },
  );

  const [validateToken] = useLazyQuery(VALIDATE_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      if (res?.validateToken && !res?.validateToken?.isValid) {
        navigate(ROUTES?.LOGIN);
      }
    },
  });

  useEffect(() => {
    validateToken({ variables: { data: { token } } });
  }, []);

  useEffect(() => {
    if (!userId && !token) {
      navigate(ROUTES?.LOGOUT);
    }
  }, []);

  const onFinish = async (values) => {
    try {
      const formValues = {
        newPassword: values?.newPassword,
        resetToken: token,
        uid: userId,
      };
      const response = await setPasswordMutation({
        variables: { data: formValues },
      });
      if (response?.data) {
        navigate(ROUTES?.LOGOUT);
      } else {
        form?.setFieldsValue(values);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console?.error(error);
    }
  };

  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-start">
        <Card className="full-width">
          <Spin spinning={loginLoading} wrapperClassName="full-width">
            <div className="text-center mb-8">
              <Logo />
              <h4>{UI_LABELS.SET_PASSWORD}</h4>
            </div>
            <Form
              name="Login"
              onFinish={onFinish}
              size="large"
              form={form}
              layout="vertical"
            >
              <Form.Item
                name="newPassword"
                rules={[
                  { required, message: 'Please enter Password' },
                  ...password,
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter Password"
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                className="mb-8"
                rules={[
                  { required, message: 'Please enter Confirm Password' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value !== getFieldValue('newPassword')) {
                        return Promise?.reject(
                          new Error(
                            'New Passwords and Confirm Password is not match',
                          ),
                        );
                      }
                      return Promise?.resolve();
                    },
                  }),
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Enter Confirm Password"
                />
              </Form.Item>
              <Form.Item className=" full-width mb-8">
                <Button type="primary" className="full-width" htmlType="submit">
                  {UI_LABELS.SET_NEW_PASSWORD}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <AuthFooter />
      </div>
    </div>
  );
}

export default SetPassword;
