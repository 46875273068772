import { UserOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Card, Form, Input, Spin } from 'antd';
import React from 'react';
import { Logo } from '../../assets/svg';
import { ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { formValidatorRules } from '../../common/utils';
import AuthFooter from '../../components/AuthFooter';
import useRouter from '../../hooks/useRouter';
import { FORGOT_PASSWORD } from './graphql/mutations';

const { required, email } = formValidatorRules;

const ForgetPassword = () => {
  const { navigate } = useRouter();
  const [forgetPasswordMutating, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted() {
      navigate(ROUTES?.LOGIN);
    },
    onError() {},
  });

  const onFinish = async (values) => {
    try {
      const formValues = {
        email: values?.email?.trim()?.toLowerCase(),
      };
      await forgetPasswordMutating({
        variables: { data: { ...formValues } },
      });
    } catch (error) {
      return error;
    }
  };
  return (
    <div className="auth-bg">
      <div className="login-wrap d-flex align-center justify-center">
        <Card className="full-width">
          <div className="text-center mb-8">
            <Logo />
            <h4>{UI_LABELS.FORGOT_YOUR_PASSWORD}</h4>
            <p>
              Don't worry. Recovering the password is easy. Just tell us the
              email.
            </p>
          </div>
          <Spin spinning={false}>
            <Form layout="vertical" onFinish={onFinish} size="large">
              <Form.Item
                name="email"
                rules={[{ required, message: 'Please enter email!' }, email]}
              >
                <Input prefix={<UserOutlined />} placeholder="Enter Email" />
              </Form.Item>
              <Form.Item className="full-width mb-8">
                <Button
                  type="primary"
                  loading={loading}
                  className="full-width"
                  htmlType="submit"
                >
                  {UI_LABELS.RESET_PASSWORD}
                </Button>
              </Form.Item>
              <Form.Item className="text-center mb-0">
                <Button
                  type="link"
                  onClick={() => {
                    navigate(ROUTES?.LOGIN);
                  }}
                >
                  {UI_LABELS.CANCEL}
                </Button>
              </Form.Item>
            </Form>
          </Spin>
        </Card>
        <AuthFooter />
      </div>
    </div>
  );
};

export default ForgetPassword;
