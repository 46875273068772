import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Form, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import useRouter from '../../hooks/useRouter';
import TransactionForm from './components/TransactionForm';
import { CREATE_TRANSACTION } from './graphql/mutations';

export default function CreateTransaction() {
  const { navigate, navigateWithBack } = useRouter();
  const [form] = Form.useForm();
  const [bookingData, setBookingData] = useState({});
  const [showModal, setShowModal] = useState(false);

  const [createTransaction, { loading }] = useMutation(CREATE_TRANSACTION, {
    fetchPolicy: 'network-only',
    onCompleted: () => {
      navigate(ROUTES?.TRANSACTION_LOGS);
    },
    onError() {},
  });

  const handleSuccessBooking = (values, userSlug) => {
    setBookingData({ callData: values, userSlug });
  };

  useEffect(() => {
    if (bookingData?.callData?.error) {
      message.error(bookingData?.callData?.message);
    }
    if (bookingData?.callData?.error === false) {
      setShowModal(false);
      message.success(bookingData?.callData?.message);
    }
  }, [bookingData]);

  const onFinish = (values) => {
    let formValues = { ...values };
    if (bookingData) {
      formValues = {
        ...formValues,
        bookedFrom: 'WEB',
        calendarBookingId: bookingData?.callData?.id,
        startTime: bookingData?.callData?.startTime,
        endTime: bookingData?.callData?.endTime,
        calendarUserSlug: bookingData?.userSlug,
      };
    }
    createTransaction({
      variables: {
        data: formValues,
      },
    });
  };

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigateWithBack(ROUTES.TRANSACTION_LOGS)}
          icon={<ArrowLeftOutlined />}
        />
        {UI_LABELS.ADD_TRANSACTION}
      </div>
      <TransactionForm
        form={form}
        onFinish={onFinish}
        loading={loading}
        handleSuccessBooking={handleSuccessBooking}
        isBooked={bookingData?.callData?.error === false}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </>
  );
}
