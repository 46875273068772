import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Radio,
  Select,
  Table,
  Tag,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  CONSULTATION_CALL_CATEGORY_FILTER,
  CONSULTATION_CALL_FEATURED_FILTER,
  CONSULTATION_CALL_FILTER,
  LIMIT,
  MEETING_TYPE_FILTER,
  PAGE_SIZE_OPTIONS,
  ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
  USER_TYPE,
  USER_TYPE_LABEL,
  dateTimeFormat,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import {
  FETCH_USER_BY_ID_QUERY,
  LIST_USER_QUERY,
} from '../users/graphql/queries';
import { CONSULTATION_CALLS } from './graphql/queries';

const { Title } = Typography;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  price: COMMON_QUERY_PARAMS.SORT_PRICE,
};
function ConsultationCallsList() {
  const { navigate } = useRouter();
  const location = useLocation();

  const [defaultUserOption, setDefaultUserOption] = useState(null);
  const [variable, setVariable] = useState({
    filter: {
      limit: LIMIT,
      skip: 0,
    },
    sort: [{ sortBy: 'DESC', sortOn: 'createdAt' }],
  });
  const [consultationCallsData, setConsultationCallsData] = useState([]);
  const CallsOwnerType = ROLES_SELECTION_FOR_SUPER_ADMIN_AND_ADMIN?.filter(
    (roles) =>
      roles?.value === ROLE_KEYS.PREMIUM_CONSULTANT ||
      roles?.value === ROLE_KEYS.ADVISER,
  );
  const [totalCount, setTotalCount] = useState(null);
  const [getConsultationCalls, { loading }] = useLazyQuery(CONSULTATION_CALLS, {
    fetchPolicy: 'network-only',
    onError() {},
  });
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const userId = getQueryParams[COMMON_QUERY_PARAMS.USER_ID];
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());
  const isFeaturedValue = getQueryParams?.[COMMON_QUERY_PARAMS.FEATURED];
  const isValue = isFeaturedValue ? isFeaturedValue === UI_LABELS.TRUE : null;

  const [fetchUserDataCall] = useLazyQuery(FETCH_USER_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const userData = res?.user;
      setDefaultUserOption({
        label: `${userData?.firstName} ${userData?.lastName}`,
        value: userData?.id,
      });
    },
  });

  useEffect(() => {
    if (userId && !defaultUserOption) {
      fetchUserDataCall({ variables: { where: { id: userId } } });
    }
  }, [userId]);

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      roles: { queryString: COMMON_QUERY_PARAMS.ROLES, defaultValue: null },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      userId: {
        queryString: COMMON_QUERY_PARAMS.USER_ID,
        defaultValue: null,
      },
      types: { queryString: COMMON_QUERY_PARAMS.TYPES, defaultValue: null },
      meetingType: {
        queryString: COMMON_QUERY_PARAMS.MEETING_TYPE,
        defaultValue: null,
      },
      isFeatured: {
        queryString: COMMON_QUERY_PARAMS.FEATURED,
        defaultValue: null,
        value: isValue ?? null,
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const handleSearchChange = debounce((value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  }, 1000);

  const handleRoleChange = (roles) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.ROLES]: roles,
      [COMMON_QUERY_PARAMS.PAGE]: null,
    });
  };

  useEffect(() => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    getConsultationCalls({
      variables: prepareVariable,
      onCompleted: (res) => {
        setTotalCount(res?.consultationCallsAdmin?.count);
        setConsultationCallsData(res?.consultationCallsAdmin?.data);
      },
    });
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }

    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.TYPES]: filters?.types,
        [COMMON_QUERY_PARAMS.CATEGORY]: filters?.category,
        [COMMON_QUERY_PARAMS.MEETING_TYPE]: filters?.meetingType,
        [COMMON_QUERY_PARAMS.FEATURED]: filters?.isFeatured,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
    setVariable({
      ...variable,
      filter: {
        ...variable.filter,
        types: filters?.types?.[0],
        category: filters?.category?.[0],
        meetingType: filters?.meetingType?.[0],
        isFeatured: filters?.isFeatured?.[0],
      },
      sort: [
        {
          sortBy: sorter?.order === 'ascend' ? 'ASC' : 'DESC',
          sortOn: sorter?.field && sorter?.order ? sorter?.field : 'createdAt',
        },
      ],
    });
  };

  const getTypeCallFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getCategoryFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_CATEGORY_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getMeetingTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <Checkbox.Group
          options={MEETING_TYPE_FILTER}
          value={selectedKeys}
          onChange={(val) => {
            setSelectedKeys(val);
          }}
          className="flex-vertical gap-8"
        />
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getFeaturedFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CONSULTATION_CALL_FEATURED_FILTER.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.label}
              checked={selectedKeys.includes(option?.label)}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.label : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });
  const columns = [
    {
      title: UI_LABELS.TITLE,
      dataIndex: 'title',
      width: '20%',
    },
    {
      title: UI_LABELS.CREATOR,
      render: (callDetail) =>
        callDetail?.creator?.firstName
          ? `${callDetail?.creator?.firstName} ${
              callDetail?.creator?.lastName
            } (${USER_TYPE_LABEL[callDetail?.creator?.roles?.[0]] ?? '-'})`
          : '-',
      width: '20%',
    },
    {
      title: UI_LABELS.TYPE,
      render: (callDetail) => (
        <Tag color="processing">
          {callDetail?.type === 'ONE_TO_ONE' ? '1-to-1' : 'Webinar'}
        </Tag>
      ),
      key: 'types',
      width: '10%',
      filterMultiple: false,
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.TYPES] ?? null,
      ...getTypeCallFilterProps(),
    },
    {
      title: UI_LABELS.NO_OF_BOOKINGS,
      dataIndex: 'bookedCount',
      width: '10%',
    },
    {
      title: UI_LABELS.CATEGORY,
      dataIndex: 'category',
      width: '15%',
      render: (category, record) =>
        record?.type === 'ONE_TO_ONE' ? category : '-',
      filterMultiple: false,
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.CATEGORY] ?? null,
      ...getCategoryFilterProps(),
    },
    {
      title: UI_LABELS.MEETING_TYPE,
      dataIndex: 'meetingType',
      width: '15%',
      render: (meetingType, record) =>
        record?.type === 'ONE_TO_ONE' ? meetingType?.join(',') ?? '-' : '-',
      filterMultiple: true,
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.MEETING_TYPE] ?? null,
      ...getMeetingTypeFilterProps(),
    },
    {
      title: `${UI_LABELS.PRICE} (INR)`,
      dataIndex: 'price',
      width: '10%',
      sorter: true,
      key: 'price',
      sortOrder: getSorter.sorters.price,
    },
    {
      title: `${UI_LABELS.DURATION} (In Minutes)`,
      dataIndex: 'duration',
      width: '10%',
    },
    {
      title: UI_LABELS.FEATURED,
      width: '10%',
      dataIndex: 'isFeatured',
      render: (isFeatured, item) =>
        // eslint-disable-next-line no-nested-ternary
        item?.creator?.roles?.[0] === USER_TYPE.PREMIUM_CONSULTANT ? (
          isFeatured === true ? (
            <Badge count="True" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
          ) : (
            <Badge count="False" color={TEMPLATE_STATUS_COLOR.UNPUBLISHED} />
          )
        ) : (
          '-'
        ),
      filterMultiple: true,
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.FEATURED] ?? null,
      ...getFeaturedFilterProps(),
    },
    {
      title: UI_LABELS.UPDATED_ON,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: '10%',
      render(date) {
        return date ? dayjs(date).format(dateTimeFormat) : '-';
      },
    },
  ];

  const handleUser = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.USER_ID]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.CONSULTATION_CALLS}
          {totalCount ? ` (${totalCount})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <div className="d-flex flex-wrap gap-12">
            <SearchComponent
              className="list-search"
              getData={handleSearchChange}
              value={search}
              handleLiveChange={(e) => setSearch(e)}
            />

            <div className="d-flex flex-wrap gap-12">
              <Select
                allowClear
                placeholder="Select User Role"
                onChange={handleRoleChange}
                className="role-select-in-calls"
                value={getQueryParams[COMMON_QUERY_PARAMS.ROLES]}
              >
                {map(CallsOwnerType, (call) => (
                  <Select.Option key={call?.value}>{call?.label}</Select.Option>
                ))}
              </Select>
            </div>
            <CommonSelect
              placeholder="Select User by Name"
              className="role-select-in-calls pr-4 pl-4 "
              showSearch
              allowClear
              onChange={handleUser}
              query={LIST_USER_QUERY}
              fetchPolicy="network-only"
              responsePath="listUsers.user"
              valuePath="id"
              labelPath="firstName"
              optionalLabelPath="lastName"
              provider={
                defaultUserOption
                  ? {
                      id: defaultUserOption.value,
                      firstName: defaultUserOption.label,
                    }
                  : null
              }
              useEffectDeps={[variable?.filter?.roles]}
              isDataDependent={[variable?.filter?.roles]}
              value={getQueryParams[COMMON_QUERY_PARAMS.USER_ID] ?? null}
              variables={{
                filter: {
                  verifiedUsers: true,
                  roles: variable?.filter?.roles
                    ? variable?.filter?.roles
                    : [ROLE_KEYS.PREMIUM_CONSULTANT, ROLE_KEYS.ADVISER],
                },
                sort: [
                  {
                    sortOn: 'createdAt',
                    sortBy: SORT_OPTION?.DESC,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={consultationCallsData}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1300, y: `calc(100vh - 388px)` }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${ROUTES.CONSULTATION_CALLS}/view/${record?.id}`);
          },
        })}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: totalCount,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        rowClassName="pointer"
      />
    </>
  );
}

export default ConsultationCallsList;
