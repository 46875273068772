import { useLazyQuery } from '@apollo/client';
import { Button, Table, Typography } from 'antd';
import { filter } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  CONFIG_KEY,
  CONSULTATION_CALL_TYPE,
  LIMIT,
  PAYMENT_TYPE,
  SORT_OPTION,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import useUrlQuery from '../../hooks/useUrlQuery';
import DistributionModal from './components/DistributionModal';
import { GET_PAYMENT_DISTRIBUTION_CONFIG } from './graphql/queries';

const { Title } = Typography;

const fieldToParams = {
  key: COMMON_QUERY_PARAMS.KEY,
  value: COMMON_QUERY_PARAMS.VALUE,
};

function PaymentDistributionList() {
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const [open, setOpen] = useState(false);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';

  const [selectedConfig, setSelectedConfig] = useState(null);
  const [isTypeFixed, setIsTypeFixed] = useState(false);

  const getFilter = Object.keys(getQueryParams || {}).length
    ? prepareFilterFromUrl(
        {
          limit: {
            queryString: COMMON_QUERY_PARAMS.LIMIT,
            defaultValue: LIMIT,
          },
          search: {
            queryString: COMMON_QUERY_PARAMS.SEARCH,
            defaultValue: '',
            value: searchParamsValue?.toString() ?? '',
          },
          skip: {
            queryString: COMMON_QUERY_PARAMS.SKIP,
            defaultValue: 0,
            value:
              getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
              getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
                ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
                  Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
                : 0,
          },
        },
        getQueryParams,
      )
    : {};

  const [getConfig, { data: config, loading }] = useLazyQuery(
    GET_PAYMENT_DISTRIBUTION_CONFIG,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, null);

  function fetchConfig() {
    const prepareVariable = {};
    if (Object.keys(getFilter).length) {
      prepareVariable.filter = getFilter;
    }
    if (Object.keys(getSorter.querySorters).length) {
      prepareVariable.sort = getSorter.querySorters;
    }
    getConfig({
      variables: Object.keys(prepareVariable).length ? prepareVariable : null,
      fetchPolicy: 'network-only',
      onCompleted(res) {
        const data = res?.configs?.data || [];
        const result = filter(
          data,
          (item) => item?.value === CONSULTATION_CALL_TYPE.FIXED.key,
        );
        setIsTypeFixed(result?.length > 0);
      },
      onError() {},
    });
  }

  useEffect(() => {
    fetchConfig();
  }, [location.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;

    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    const prepareSorter = {};
    Object?.keys(fieldToParams)?.forEach((item) => {
      sorterAry?.forEach((filterItem) => {
        if (filterItem?.columnKey && item === filterItem?.columnKey) {
          // eslint-disable-next-line no-nested-ternary
          prepareSorter[
            fieldToParams[filterItem?.columnKey]
            // eslint-disable-next-line no-nested-ternary
          ] = filterItem?.order
            ? filterItem?.order === 'descend'
              ? SORT_OPTION.DESC
              : SORT_OPTION.ASC
            : null;
        }
      });
      prepareSorter[fieldToParams?.[item]] =
        prepareSorter[fieldToParams?.[item]] ?? null;
    });
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
      ...prepareSorter,
    });
  };

  const columns = [
    {
      title: UI_LABELS.DESCRIPTION,
      dataIndex: 'key',
      key: 'key',
      sorter: { multiple: 1 },
      sortOrder: getSorter.sorters.key,
      width: 500,
      render(key) {
        return CONFIG_KEY[key];
      },
    },
    {
      title: UI_LABELS.VALUE,
      dataIndex: 'value',
      key: 'value',
      sorter: { multiple: 2 },
      sortOrder: getSorter.sorters.value,
      align: 'right',
      render(value) {
        if (
          value === CONSULTATION_CALL_TYPE.FIXED.key ||
          value === CONSULTATION_CALL_TYPE.PERCENT.key
        ) {
          return CONSULTATION_CALL_TYPE?.[value]?.label;
        }
        if (
          value === PAYMENT_TYPE.DEFAULT.key ||
          value === PAYMENT_TYPE.SPECIFIC.key
        ) {
          return PAYMENT_TYPE?.[value]?.label;
        }
        return `${value}%`;
      },
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: 'id',
      align: 'center',
      fixed: 'right',
      render: (id, record) => (
        <Button
          type="link"
          onClick={() => {
            setOpen(true);
            setSelectedConfig(record);
          }}
        >
          {UI_LABELS.EDIT}
        </Button>
      ),
    },
  ];

  return (
    <>
      {open && (
        <DistributionModal
          setOpen={setOpen}
          open={open}
          selectedConfig={selectedConfig}
          getConfig={getConfig}
          isTypeFixed={isTypeFixed}
        />
      )}
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.PAYMENT_DISTRIBUTION}
        </Title>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={config?.configs?.data}
        pagination={false}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default PaymentDistributionList;
