import { useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

function useUrlQuery() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const getQueryParams = useMemo(() => {
    const urlValueToObject = {};
    const paramsEntries = Array.from(searchParams.entries());
    paramsEntries?.forEach(([key, value]) => {
      try {
        const parsedValue = JSON.parse(value);
        urlValueToObject[key] = parsedValue;
      } catch (error) {
        urlValueToObject[key] = value;
      }
    });
    return urlValueToObject;
  }, [location.search]);

  const setQueryParams = (updatedValueObj, replace = false) => {
    if (updatedValueObj === null) {
      navigate(location.pathname, {
        replace,
      });
    } else {
      Object.keys(updatedValueObj)?.forEach((key) => {
        if (
          updatedValueObj?.[key] ||
          typeof updatedValueObj?.[key] === 'boolean'
        ) {
          let value = updatedValueObj[key];
          value = typeof value === 'object' ? JSON.stringify(value) : value;
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      });

      navigate(`${location.pathname}?${searchParams.toString()}`, {
        replace,
      });
    }
  };

  return { getQueryParams, setQueryParams };
}

export default useUrlQuery;
