import { gql } from '@apollo/client';

export const LIST_JUDGEMENTS = gql`
  query judgmentsAdmin(
    $sort: [ListJudgmentsSort!]
    $filter: ListJudgmentsFilter
  ) {
    judgmentsAdmin(sort: $sort, filter: $filter) {
      data {
        _id
        id
        appealNumber
        appellant
        category
        court
        judgmentText
        respondent
        dateOfOrder
        metaData
        downloadCounter
        createdAt
        updatedAt
        batchId
        useAiData
        status
      }
      count
      judgementsCount
    }
  }
`;

export const GET_JUDGEMENT_DETAILS = gql`
  query judgementAdmin($where: JudgementIdWhereUniqueInput!) {
    judgementAdmin(where: $where) {
      _id
      appealNumber
      appealRSAnumber
      appealType
      appellant
      appealFiledBy
      detailsURL
      appealFiledOn
      assessmentYear
      dateOfFirstHearing
      dateOfLastHearing
      dateOfNextHearing
      dateOfFinalHearing
      dateOfTribunalOrder
      dateOfRegistration
      dateOfDecision
      benchAllotted
      caseStatus
      orderResult
      addToNewsLetter
      yearOfPronouncement
      durationOfJustice
      resultInFavourOf
      bench {
        _id
        key
        value
        slug
      }
      assessee {
        _id
        id
        name
        slug
        pancard
      }
      judges {
        _id
        name
      }
      keywords {
        _id
        label
      }
      sections {
        _id
        label
      }
      assesseeLawyers {
        _id
        name
      }
      departmentLawyers {
        _id
        name
      }
      aiJudges {
        _id
        name
      }
      aiAssessee {
        _id
        name
      }
      aiKeywords {
        _id
        label
      }
      aiSections {
        _id
        label
      }
      aiAssesseeLawyers {
        _id
        name
      }
      aiDepartmentLawyers {
        _id
        name
      }
      aiBench {
        _id
        key
        value
        slug
      }
      aiRegion
      aiIssuePeriodsV1
      useAiData
      aiPdfProcessStatus
      isRsaGenerated
      aiBatchId
      aiAppealNumber
      aiCourt
      aiAppellant
      aiRespondent
      aiPronouncementDate
      aiResultInFavourOf
      aiTribunalOrderUrl
      aiYearOfPronouncement
      isAiGenerative
      court
      judgmentText
      respondent
      pdfUrl
      errorMessage
      tags
      processPdfStatus
      scrapePdfUrl
      dateOfOrder
      # metaData
      downloadCounter
      category
      dateOfPronouncement
      notes
      slug
      createdAt
      updatedAt
      issuePeriodV1
      region
      summary
      isSummary
      outcome
      facts
      isFacts
      issues
      isIssues
      isFindings
      findings
      others
      isOthers
    }
  }
`;

export const LIST_EXPERT_SUMMARIES = gql`
  query AdminExpertSummaries(
    $filter: AdminExpertSummariesFilter!
    $sort: [ListExpertSummarySort!]
  ) {
    adminExpertSummaries(filter: $filter, sort: $sort) {
      count
      data {
        content
        id
        status
        createdAt
        forNewsletter
        user {
          firstName
          lastName
          username
          profileImage
          profession
        }
      }
    }
  }
`;

export const GET_LAWYERS = gql`
  query LawyersAdmin($filter: ListLawyerFilter, $sort: [ListLawyerSort!]) {
    lawyersAdmin(filter: $filter, sort: $sort) {
      data {
        _id
        name
        slug
      }
      count
    }
  }
`;
export const GET_SECTIONS = gql`
  query SectionsAdmin($filter: ListSectionFilter, $sort: [ListSectionSort!]) {
    sectionsAdmin(filter: $filter, sort: $sort) {
      data {
        _id
        label
        slug
        id
      }
      count
    }
  }
`;

export const GET_KEYWORD = gql`
  query KeywordsAdmin($filter: ListKeywordFilter, $sort: [ListKeywordSort!]) {
    keywordsAdmin(filter: $filter, sort: $sort) {
      data {
        _id
        label
        slug
        regex
      }
      count
    }
  }
`;

export const SEND_TEST_NEWSLETTER = gql`
  query TestNewsletter {
    testNewsletter {
      message
    }
  }
`;

export const GET_JUDGEMENT_SIGNED_URL = gql`
  query GetPdfFileUploadSignedUrl($data: GetPdfFileUploadSignedUrlInput!) {
    getPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;

export const GET_BENCH_LIST = gql`
  query Benches($filter: BenchesFilter!) {
    benches(filter: $filter) {
      id
      value
    }
  }
`;

export const VALIDATE_PDF_URL = gql`
  query ValidateContentUrl($data: ValidateContentUrlInput!) {
    validateContentUrl(data: $data) {
      isValid
      code
    }
  }
`;

export const APPEAL_TYPES = gql`
  query AppealTypeList {
    appealTypeList {
      appealType
    }
  }
`;
