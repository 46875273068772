import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  FilePdfOutlined,
  MinusCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Upload,
} from 'antd';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES,
  LIMIT,
  ROUTES,
  STANDARD_DATE_FORMAT,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  beforeUploadDocFile,
  formValidatorRules,
  getCourtList,
} from '../../common/utils';
import MongoCommonSelect from '../../components/MongoCommonSelect';
import useRouter from '../../hooks/useRouter';
import {
  APPEAL_TYPES,
  GET_BENCH_LIST,
  GET_JUDGEMENT_SIGNED_URL,
  VALIDATE_PDF_URL,
} from '../judgements/graphql/queries';
import { LIST_ASSESSMENT_YEARS } from '../review-appeals/graphql/queries';
import { IMPORT_JUDGEMENT } from './graphql/mutations';

const { RangePicker } = DatePicker;
const { required } = formValidatorRules;

const defaultFormValue = {
  appealNumber: '',
  appellant: '',
  court: null,
  dateOfOrder: '',
  pdfUrl: '',
  respondent: '',
  isPdfUrl: true,
};

const ImportJudgement = () => {
  const [form] = Form.useForm();
  const { navigate, navigateWithBack } = useRouter();
  const [isFieldsChanged, setIsFieldsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [warning, setWarning] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [validationStatus, setValidationStatus] = useState({});
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isPDFUrlValidate, setIsPDFUrlValidate] = useState(true);
  const [appealTypes, setAppealTypes] = useState([]);
  const [assessmentYears, setAssessmentYears] = useState([]);
  const [category, setCategory] = useState(CATEGORIES?.[0]?.value);
  const [courtList, setCourtList] = useState([]);

  const getCourts = async ({ variables }) => {
    const data = await getCourtList({
      variables,
    });
    setCourtList(data);
  };

  useEffect(() => {
    if (category) {
      getCourts({
        variables: {
          category,
        },
      });
    }
  }, [category]);

  const appealTypesList = async () => {
    await mongoClient
      ?.query({
        query: APPEAL_TYPES,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res?.data?.appealTypeList) {
          const options = res?.data?.appealTypeList?.map((item) => ({
            label: item?.appealType,
            value: item?.appealType,
          }));
          setAppealTypes(options);
        }
      });
  };

  const assessmentYearList = async () => {
    await mongoClient
      ?.query({
        query: LIST_ASSESSMENT_YEARS,
        fetchPolicy: 'network-only',
      })
      .then((res) => {
        if (res?.data?.assessmentYearList) {
          const options = res?.data?.assessmentYearList?.map((item) => ({
            label: item?.assessmentYear,
            value: item?.assessmentYear,
          }));
          setAssessmentYears(options);
        }
      });
  };

  useEffect(() => {
    appealTypesList();
    assessmentYearList();
  }, []);

  const importJudgementMutate = async ({ variables }) => {
    setIsLoading(true);
    await mongoClient
      ?.mutate({
        mutation: IMPORT_JUDGEMENT,
        variables,
      })
      ?.then(() => {
        setIsLoading(false);
        navigate(-1);
      })
      ?.catch(() => {
        setIsLoading(false);
      });
  };

  const getSignedUrl = async ({ variables }) => {
    try {
      const res = await mongoClient.query({
        query: GET_JUDGEMENT_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables: { ...variables },
      });
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching signed URL', error);
    }
  };

  const validateUrlCall = async () => {
    try {
      const res = await mongoClient.query({
        query: VALIDATE_PDF_URL,
        fetchPolicy: 'network-only',
        variables: {
          data: {
            url: pdfUrl,
          },
        },
      });
      return res;
    } catch (error) {
      return error;
    }
  };

  const uploadFile = async (url, file) => {
    try {
      await fetch(`${url}`, {
        method: 'PUT',
        body: file,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error upload file', error);
    }
  };

  const onFinish = async (values) => {
    const urls = await Promise.all(
      fileList?.map(async (item) => {
        try {
          const response = await getSignedUrl({
            variables: {
              data: {
                fileName: item?.file?.name,
              },
            },
          });
          return {
            name: item?.key,
            file: item?.file,
            url: response?.data?.getPdfFileUploadSignedUrl?.signedUrl,
            key: response?.data?.getPdfFileUploadSignedUrl?.key,
          };
        } catch (error) {
          return null;
        }
      }),
    );

    await Promise.all(
      urls?.map(async (item) => {
        try {
          return uploadFile(item?.url, item?.file);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching signed URL:', error);
        }
      }),
    );

    const preparedData = values?.judgement?.map((item, i) => ({
      appealNumber: item?.appealNumber,
      appellant: item?.appellant,
      category,
      court: item?.court,
      dateOfOrder: item?.dateOfOrder
        ? item?.dateOfOrder?.toISOString()
        : item?.dateOfOrder,
      scrapePdfUrl: !item?.isPdfUrl
        ? urls?.find((ele) => ele?.name === i)?.key
        : null,
      pdfUrl: item?.isPdfUrl ? item?.pdfUrl : null,
      respondent: item?.respondent,
      ...(category === 'DIRECT_TAX'
        ? { assessmentYear: item?.assessmentYear }
        : {
            startDate: item?.issuePeriodV1?.[0]
              ? dayjs(item?.issuePeriodV1?.[0])
              : null,
            endDate: item?.issuePeriodV1?.[1]
              ? dayjs(item?.issuePeriodV1?.[1])
              : null,
          }),
      appealFiledOn: item?.appealFiledOn
        ? item?.appealFiledOn?.toISOString()
        : item?.appealFiledOn,
      appealFiledBy: item?.appealFiledBy,
      caseStatus: item?.caseStatus,
      orderResult: item?.orderResult,
      appealType: item?.appealType,
      bench: item?.bench,
    }));

    importJudgementMutate({
      variables: {
        data: preparedData,
      },
    });
  };

  const handleFileChange = async (info, name) => {
    const fileObj = info?.file?.originFileObj;
    const preparedData = fileList?.map((item) =>
      item?.key === name ? { key: name, file: fileObj } : item,
    );

    if (!preparedData?.some((item) => item?.key === name)) {
      preparedData?.push({ key: name, file: fileObj });
    }
    setIsPDFUrlValidate(true);
    setFileList(preparedData);
  };

  const handleRemoveFile = (name) => {
    const updatedFileList = fileList?.filter((item) => item?.key !== name);
    setFileList(updatedFileList);
  };

  useEffect(() => {
    const validateUrl = async (name) => {
      const { data } = await validateUrlCall();
      const isValid = data?.validateContentUrl?.isValid;
      const code = data?.validateContentUrl?.code;
      if (isValid) {
        setValidationStatus((prev) => ({ ...prev, [name]: 'success' }));
        setIsPDFUrlValidate(true);
        return Promise.resolve();
      }
      if (!isValid && code === 'INVALID') {
        setValidationStatus((prev) => ({ ...prev, [name]: 'error' }));
        setIsPDFUrlValidate(false);
        return Promise.reject(new Error('Please enter a valid Pdf Url'));
      }
      if (!isValid && code === 'TIMEOUT') {
        setWarning(true);
        setIsPDFUrlValidate(true);
        setValidationStatus((prev) => ({ ...prev, [name]: 'warning' }));
        return Promise.resolve();
      }
    };
    if (pdfUrl) {
      validateUrl(currentIndex);
    }
  }, [pdfUrl]);

  const handlePdfUrlChange = debounce((value, name) => {
    setIsPDFUrlValidate(false);
    setWarning(false);
    setCurrentIndex(name);
    setPdfUrl(value);
    if (!value) return;
    setValidationStatus((prev) => ({ ...prev, [name]: 'validating' }));
  }, 1000);

  const categories = useMemo(
    () =>
      process.env.REACT_APP_IMPORT_CSV_DIRECT_TAX_ONLY === 'true'
        ? CATEGORIES.filter((item) => item.value === 'DIRECT_TAX')
        : CATEGORIES,
    [],
  );

  return (
    <>
      <div className="d-flex align-center justify-between">
        <div className="title">
          <Button
            type="text"
            shape="circle"
            onClick={() => navigateWithBack(ROUTES.IMPORT_CSV)}
            icon={<ArrowLeftOutlined />}
          />
          {UI_LABELS.IMPORT_JUDGEMENT}
        </div>
        <Select
          className="role-select-in-calls"
          placeholder="Please Select Category"
          options={categories}
          value={category}
          onChange={(value) => setCategory(value)}
        />
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form
            form={form}
            name="import-judgement"
            onFinish={onFinish}
            initialValues={{ judgement: [defaultFormValue] }}
            onValuesChange={() => setIsFieldsChanged(true)}
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 20 }}
            layout="vertical"
          >
            <Form.List name="judgement">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Card
                      key={key}
                      title="Judgement"
                      extra={
                        fields?.length > 1 ? (
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        ) : null
                      }
                      className="mb-16"
                    >
                      <Row gutter={16}>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appealNumber']}
                            label="Appeal Number"
                            rules={[
                              {
                                required,
                                message: 'Please enter Appeal Number',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Appeal Number" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appellant']}
                            label="Appellant"
                            rules={[
                              { required, message: 'Please enter Appellant' },
                            ]}
                          >
                            <Input placeholder="Enter Appellant" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'respondent']}
                            label="Respondent"
                            rules={[
                              {
                                required,
                                message: 'Please enter Respondent',
                              },
                            ]}
                          >
                            <Input placeholder="Enter Respondent" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'court']}
                            label="Court"
                          >
                            <Select
                              placeholder="Select Court"
                              options={courtList}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'bench']}
                            label="Bench"
                          >
                            <MongoCommonSelect
                              getPopupContainer={(trigger) =>
                                trigger?.parentNode
                              }
                              placeholder="Select Benches"
                              className="role-select-in-calls"
                              showSearch
                              allowClear
                              query={GET_BENCH_LIST}
                              fetchPolicy="network-only"
                              responsePath="data.benches"
                              valuePath="id"
                              labelPath="value"
                              variables={{
                                filter: {
                                  skip: 0,
                                  limit: LIMIT,
                                  search: '',
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                        {category === 'DIRECT_TAX' ? (
                          <Col xs={24} sm={24} md={12} span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'assessmentYear']}
                              label="Assessment Year"
                            >
                              <Select
                                placeholder="Select Assessment Year"
                                options={assessmentYears}
                                showSearch
                              />
                            </Form.Item>
                          </Col>
                        ) : (
                          <Col xs={24} sm={24} md={12} span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, 'issuePeriodV1']}
                              label="Issue Period"
                            >
                              <RangePicker
                                format={STANDARD_DATE_FORMAT}
                                allowClear
                                placeholder={['Start Date', 'End Date']}
                              />
                            </Form.Item>
                          </Col>
                        )}
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appealType']}
                            label="Appeal Type"
                          >
                            <Select
                              placeholder="Select Appeal Type"
                              options={appealTypes}
                              showSearch
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appealFiledOn']}
                            label="Appeal Filed On"
                          >
                            <DatePicker
                              format={STANDARD_DATE_FORMAT}
                              placeholder="Select Appeal Filed On Date"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'appealFiledBy']}
                            label="Appeal Filed By"
                          >
                            <Input placeholder="Enter Appeal Filed By" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'caseStatus']}
                            label="Case Status"
                          >
                            <Input placeholder="Enter Case Status" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'orderResult']}
                            label="Order Result"
                          >
                            <Input placeholder="Enter Order Result" />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            label="Do you have Pdf Url?"
                            valuePropName="checked"
                            name={[name, 'isPdfUrl']}
                          >
                            <Switch />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            {...restField}
                            name={[name, 'dateOfOrder']}
                            label="Date Of Order"
                          >
                            <DatePicker
                              format={STANDARD_DATE_FORMAT}
                              placeholder="Select Date Of Order"
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                          <Form.Item
                            className="mb-0"
                            shouldUpdate={(prevValues, currentValues) => {
                              const prevIsPdfUrl =
                                prevValues?.judgement?.[name]?.isPdfUrl;
                              const currIsPdfUrl =
                                currentValues?.judgement?.[name]?.isPdfUrl;
                              if (
                                prevIsPdfUrl !== currIsPdfUrl &&
                                currIsPdfUrl
                              ) {
                                handleRemoveFile(name);
                              }
                              return prevIsPdfUrl !== currIsPdfUrl;
                            }}
                          >
                            {() =>
                              form.getFieldValue([
                                'judgement',
                                name,
                                'isPdfUrl',
                              ]) ? (
                                <>
                                  <Form.Item
                                    {...restField}
                                    name={[name, 'pdfUrl']}
                                    label="Pdf Url"
                                    validateStatus={
                                      validationStatus[name] ?? ''
                                    }
                                    hasFeedback
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter Pdf Url',
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder="Enter Pdf Url"
                                      onChange={debounce((e) => {
                                        setValidationStatus((prev) => ({
                                          ...prev,
                                          [name]: null,
                                        }));
                                        handlePdfUrlChange(
                                          e.target.value,
                                          name,
                                        );
                                      }, 1000)}
                                    />
                                  </Form.Item>
                                  {validationStatus[name] === 'warning' &&
                                    warning && (
                                      <Alert
                                        message="Unable to verify Pdf Url at the moment, Proceed anyway?"
                                        type="warning"
                                      />
                                    )}
                                </>
                              ) : (
                                <Form.Item
                                  name={[name, 'scrapePdfUrl']}
                                  label="Upload Pdf"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please Upload Pdf',
                                    },
                                  ]}
                                >
                                  {fileList?.[name]?.file ? (
                                    <div className="file-shower">
                                      <FilePdfOutlined className="file-preview" />
                                      <div className="close-icon">
                                        <Button
                                          icon={<CloseCircleOutlined />}
                                          type="primary"
                                          shape="rounded"
                                          danger
                                          disabled={false}
                                          onClick={() => {
                                            setFileList([]);
                                            form.setFieldsValue({
                                              judgement: {
                                                [name]: {
                                                  scrapePdfUrl: undefined,
                                                },
                                              },
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <Upload
                                      showUploadList={false}
                                      beforeUpload={beforeUploadDocFile}
                                      accept=".pdf"
                                      onChange={(e) =>
                                        handleFileChange(e, name)
                                      }
                                    >
                                      <Button icon={<UploadOutlined />}>
                                        {UI_LABELS.UPLOAD_PDF}
                                      </Button>
                                    </Upload>
                                  )}
                                </Form.Item>
                              )
                            }
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                  <div className="d-flex align-center justify-center">
                    <Button
                      type="dashed"
                      className="mb-16"
                      onClick={() => add(defaultFormValue)}
                      disabled={fields?.length === 10}
                    >
                      + {UI_LABELS.ADD_JUDGEMENT}
                    </Button>
                  </div>
                </>
              )}
            </Form.List>

            <Form.Item>
              <Space size={8}>
                <Button onClick={() => navigate(-1)}>{UI_LABELS.CANCEL}</Button>
                <Button
                  type="primary"
                  disabled={!isFieldsChanged || !isPDFUrlValidate}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {UI_LABELS.SAVE}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </Card>
    </>
  );
};

export default ImportJudgement;
