import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Card, Form, Tag } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { mongoClient } from '../../apollo';
import { IND_DATE_FORMAT, ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { getBase64 } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import ActForm from './components/ActForm';
import { CREATE_ACT, UPDATE_ACT } from './graphql/mutations';
import { ACT_PDF_SIGNED_URL, GET_ACT } from './graphql/queries';

const CreateEditAct = () => {
  const { params, navigateWithBack } = useRouter();
  const { id } = params;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState();
  const [file, setFile] = useState({
    docFileObject: null,
    pdfUrl: null,
    isPdfUrlChange: false,
  });
  const [isPublished, setIsPublished] = useState(false);

  const getAct = async (actId) => {
    await mongoClient
      ?.query({
        query: GET_ACT,
        fetchPolicy: 'network-only',
        variables: { where: { id: actId } },
      })
      .then((res) => {
        const prepareData = {
          title: res?.data?.actAdmin?.title,
          description: res?.data?.actAdmin?.description ?? '',
          pdfUrl: res?.data?.actAdmin?.pdfUrl ?? '',
          region: res?.data?.actAdmin?.region,
          category: res?.data?.actAdmin?.category,
          issuePeriod: [
            dayjs(res?.data?.actAdmin?.startDate),
            dayjs(res?.data?.actAdmin?.endDate),
          ],
        };
        form.setFieldsValue(prepareData);
        setFile({
          ...file,
          pdfUrl: res?.data?.actAdmin?.pdfUrl ?? '',
        });
        setIsPublished(res?.data?.actAdmin?.status === 'PUBLISHED');
      });
  };

  const createUpdateAtc = async ({ variables }) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: id ? UPDATE_ACT : CREATE_ACT,
        fetchPolicy: 'network-only',
        variables,
      })
      .then((res) => {
        if (res) {
          navigateWithBack(ROUTES.ACTS);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const GetSignedUrl = async ({ variables }) => {
    try {
      const data = await mongoClient?.query({
        query: ACT_PDF_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables,
      });
      return data?.data;
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    if (id) {
      getAct(id);
    }
  }, [id]);

  const onFinish = async (values) => {
    setLoading(true);
    const { issuePeriod, pdfUrl, ...rest } = values;
    const variables = {
      data: {
        ...rest,
        startDate: dayjs(values?.issuePeriod?.[0])?.format(IND_DATE_FORMAT),
        endDate: dayjs(values?.issuePeriod?.[1])?.format(IND_DATE_FORMAT),
      },
    };
    if (file.isPdfUrlChange) {
      const fileObj = file.docFileObject;
      const uploadDocPromise = new Promise((resolve, reject) => {
        GetSignedUrl({
          variables: { data: { fileName: fileObj?.name } },
        })
          .then(async ({ getActPdfFileUploadSignedUrl }) => {
            if (getActPdfFileUploadSignedUrl) {
              try {
                const response = await fetch(
                  `${getActPdfFileUploadSignedUrl?.signedUrl}`,
                  {
                    method: 'PUT',
                    body: fileObj,
                  },
                );
                if (response?.status === 200) {
                  variables.data.pdfUrl = getActPdfFileUploadSignedUrl?.key;
                  resolve();
                }
              } catch (error) {
                reject(error);
              }
            }
          })
          .catch((error) => reject(error));
      });
      await uploadDocPromise;
    }
    if (id) {
      variables.where = { id };
    } else {
      variables.data.status = 'DRAFT';
    }
    createUpdateAtc({ variables });
  };

  const handleRemoveAttachDoc = () => {
    setFile({
      ...file,
      pdfUrl: null,
      isPdfUrlChange: false,
      docFileObject: null,
    });
  };

  const handleFileChange = async (e) => {
    const fileObj = e?.file?.originFileObj;
    const previewImg = await getBase64(fileObj);
    setFile({
      ...file,
      pdfUrl: previewImg,
      isPdfUrlChange: true,
      docFileObject: fileObj,
    });
    form.setFieldsValue({
      pdfUrl: previewImg,
    });
  };

  const handlePublish = async () => {
    const variables = {
      data: {
        status: 'PUBLISHED',
      },
      where: {
        id,
      },
    };
    await createUpdateAtc({ variables });
  };

  return (
    <>
      <div className="d-flex align-center justify-between">
        <div className="title">
          <Button
            type="text"
            shape="circle"
            onClick={() => navigateWithBack(ROUTES.ACTS)}
            icon={<ArrowLeftOutlined />}
          />
          {id ? UI_LABELS.EDIT_ACT : UI_LABELS.ADD_ACT}
        </div>
        {id &&
          (isPublished ? (
            <Tag color="success" className="mr-0 pb-6 pl-12 pr-12 pt-6">
              <span className="text-16">{UI_LABELS.PUBLISHED}</span>
            </Tag>
          ) : (
            <Button onClick={handlePublish} loading={loading}>
              {UI_LABELS.PUBLISH}
            </Button>
          ))}
      </div>
      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <ActForm
            form={form}
            onFinish={onFinish}
            id={id}
            loading={loading}
            file={file}
            handleFileChange={handleFileChange}
            handleRemoveAttachDoc={handleRemoveAttachDoc}
          />
        </div>
      </Card>
    </>
  );
};
export default CreateEditAct;
