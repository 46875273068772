import { Button, Card, Col, Form, Input, Row, Select, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CATEGORIES_KEYS, TAX_CATEGORIES } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { formValidatorRules, getCourtList } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';

const { required, pancard } = formValidatorRules;

function AssesseeForm({ form, onFinish, defaultFormValue = null, loading }) {
  const { navigate } = useRouter();
  const { category } = useParams();

  const [courtList, setCourtList] = useState([]);

  const getCourts = async (categoryType) => {
    const data = await getCourtList({
      variables: { category: CATEGORIES_KEYS[categoryType] },
    });
    setCourtList(data);
  };

  useEffect(() => {
    getCourts(category);
  }, [category]);

  return (
    <Card className="ant-body-scroll">
      <div className="card-body-wrapper">
        <Form
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={defaultFormValue}
          layout="vertical"
        >
          <Row gutter={16}>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="name"
                label="Name"
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: 'Please enter Name',
                  },
                ]}
              >
                <Input placeholder="Enter Name" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="slug"
                label="Slug"
                rules={[{ required, message: 'Please enter Slug' }]}
              >
                <Input placeholder="Enter Slug" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="initialOfSurnameOrTitle"
                label="Initial of Surname or Title"
                rules={[
                  {
                    whitespace: true,
                    message: 'Please enter Surname/Title',
                  },
                ]}
              >
                <Input placeholder="Enter Surname or Title" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="pancard"
                label="Pan"
                rules={[
                  pancard,
                  { required: true, message: 'Please enter Pan' },
                ]}
              >
                <Input placeholder="Enter your Pan" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item name="type" label="Type" className="user-type-input">
                <Input placeholder="Enter Type" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={8}>
              <Form.Item
                name="category"
                label="Category"
                className="user-type-input"
                rules={[
                  {
                    required: true,
                    message: 'Please select Category',
                  },
                ]}
              >
                <Select placeholder="Select Category" mode="multiple" disabled>
                  {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                    <Select.Option key={value} value={value}>
                      {label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={12} md={7} span={7}>
              <Form.Item
                name="court"
                label="Court"
                rules={[
                  {
                    required: true,
                    message: 'At least 1 Court must be added',
                  },
                ]}
              >
                <Select placeholder="Select Court" mode="multiple">
                  {courtList?.map((court) => (
                    <Select.Option key={court?.value} value={court?.value}>
                      {court?.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Space size={8}>
              <Button onClick={() => navigate(-1)}>{UI_LABELS.CANCEL}</Button>
              <Button type="primary" htmlType="submit" loading={loading}>
                {UI_LABELS.SAVE}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Card>
  );
}

export default AssesseeForm;
