import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Badge,
  Button,
  Checkbox,
  Radio,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  dateTimeFormat,
  EXCLUDE_ROLES_FOR_TRANSACTION,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  STATUS_FILTER,
  TEMPLATE_STATUS_COLOR,
  USER_ROLES_FILTERS,
  USER_TYPE_LABEL,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import { LIST_USER_QUERY } from './graphql/queries';
import './user.less';

const { Title } = Typography;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  firstName: COMMON_QUERY_PARAMS.SORT_NAME,
};

function UserList() {
  const { navigate } = useRouter();
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();

  const [userList, setUserList] = useState();
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const activeStatus = getQueryParams?.[COMMON_QUERY_PARAMS.ACTIVE_STATUS];
  const isActive = activeStatus ? activeStatus === UI_LABELS.ACTIVE : null;

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      roles: { queryString: COMMON_QUERY_PARAMS.ROLES, defaultValue: [] },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      isActive: {
        queryString: COMMON_QUERY_PARAMS.ACTIVE_STATUS,
        defaultValue: null,
        value: isActive ?? null,
      },
      createdBy: {
        queryString: COMMON_QUERY_PARAMS.CREATED_BY,
        defaultValue: null,
        value: getQueryParams?.[COMMON_QUERY_PARAMS.CREATED_BY],
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [fetchUser] = useLazyQuery(LIST_USER_QUERY);

  function fetchUserCall() {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoading(true);
    fetchUser({
      variables: prepareVariable,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setUserList(res?.listUsers?.user);
        setCount(res?.listUsers?.count ?? 0);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    });
  }

  useEffect(() => {
    fetchUserCall();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.ROLES]: filters.roles,
        [COMMON_QUERY_PARAMS.ACTIVE_STATUS]: filters?.isActive,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const getTypeFilterProps = () => ({
    filterMultiple: true,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {USER_ROLES_FILTERS?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Checkbox
              checked={selectedKeys.includes(option?.value)}
              onChange={(e) => {
                const updatedKeys = e.target.checked
                  ? [...selectedKeys, option.value]
                  : selectedKeys.filter((key) => key !== option.value);
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Checkbox>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
    onFilter: (value, record) => record?.roles?.includes(value),
  });

  const getTypeStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {STATUS_FILTER?.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option.label}
              checked={selectedKeys === option?.label}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.label : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const handleUser = (value, confirm) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.CREATED_BY]: value,
    });
    confirm();
  };

  const getProviderFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        <div>
          <CommonSelect
            placeholder="Select Name"
            className="full-width"
            showSearch
            allowClear
            variant="borderless"
            value={selectedKeys}
            onChange={(value) => {
              setSelectedKeys(value);
              handleUser(value, confirm);
            }}
            query={LIST_USER_QUERY}
            fetchPolicy="network-only"
            responsePath="listUsers.user"
            valuePath="id"
            labelPath="firstName"
            optionalLabelPath="lastName"
            variables={{
              filter: {
                roles: [ROLE_KEYS.SUPER_ADMIN, ROLE_KEYS.ADMIN],
              },
              sort: [
                {
                  sortOn: 'createdAt',
                  sortBy: SORT_OPTION.DESC,
                },
              ],
            }}
          />
        </div>
      </div>
    ),
  });

  return (
    <>
      <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
        {UI_LABELS.USERS} {count ? ` (${count})` : ''}
      </Title>
      <div className="filter-input mb-12">
        <SearchComponent
          className="list-search"
          getData={handleSearchChange}
          value={search}
          handleLiveChange={(e) => setSearch(e)}
        />
        <Button
          className="ml-8"
          icon={<PlusCircleOutlined />}
          key="1"
          type="primary"
          onClick={() => {
            navigate(ROUTES?.ADD_USERS);
          }}
        >
          {UI_LABELS.ADD_USER}
        </Button>
      </div>
      <Table
        columns={[
          {
            title: UI_LABELS.NAME,
            sorter: { multiple: 1 },
            sortOrder: getSorter.sorters.firstName,
            render: (record) => (
              <>
                {`${record?.firstName} ${record?.lastName}`}
                {record?.migratedFrom ? (
                  /* static color is added in badge due to single time use in whole project */
                  <span>
                    {' '}
                    <Badge color="#108ee9" count={record?.migratedFrom} />
                  </span>
                ) : (
                  ''
                )}
              </>
            ),
            width: '15%',
            key: 'firstName',
          },
          {
            title: UI_LABELS.EMAIL,
            width: '20%',
            render: (value) => (
              <div className="temp-email-block">
                <span>{value?.email}</span>
                {value?.tempEmail && (
                  <Tooltip title={`Verification pending : ${value?.tempEmail}`}>
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </div>
            ),
          },
          {
            title: UI_LABELS.PHONE_NUMBER,
            width: '10%',
            dataIndex: 'phoneNumber',
          },
          {
            title: UI_LABELS.CITY,
            render: (item) => <>{item?.city ? item?.city : '-'}</>,
            width: '10%',
          },
          {
            title: UI_LABELS.PROFESSION,
            render: (item) => <>{item?.profession ? item?.profession : '-'}</>,
            width: '10%',
          },
          {
            title: UI_LABELS.ROLE,
            key: 'roles',
            render: (item) => <>{USER_TYPE_LABEL[item?.roles[0]]}</>,
            width: '10%',
            filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.ROLES] ?? null,
            ...getTypeFilterProps(),
          },
          {
            title: `${UI_LABELS.CREATED_BY} (Role)`,
            render: (value) =>
              value?.userCreator?.firstName
                ? `${value?.userCreator?.firstName} ${
                    value?.userCreator?.lastName
                  } (${USER_TYPE_LABEL[value?.userCreator?.roles?.[0]] ?? '-'})`
                : '-',
            key: 'creator',
            width: '10%',
            filteredValue:
              getQueryParams?.[COMMON_QUERY_PARAMS.CREATED_BY] ?? null,
            ...getProviderFilterProps(),
          },
          {
            title: UI_LABELS.CREATED_ON,
            key: 'createdAt',
            width: '10%',
            render: (item) => (
              <span>
                {item ? dayjs(item?.createdAt)?.format(dateTimeFormat) : '-'}
              </span>
            ),
            sorter: { multiple: 2 },
            sortDirections: getSorter.isDefaultSortApplied && [
              'ascend',
              'descend',
              'ascend',
            ],
            sortOrder: getSorter.sorters.createdAt,
          },
          {
            title: UI_LABELS.STATUS,
            render: (item) =>
              item?.isActive ? (
                <Badge
                  count={UI_LABELS.ACTIVE}
                  color={TEMPLATE_STATUS_COLOR.PUBLISHED}
                />
              ) : (
                <Badge
                  count={UI_LABELS.INACTIVE}
                  color={TEMPLATE_STATUS_COLOR.UNPUBLISHED}
                />
              ),
            key: 'isActive',
            width: '10%',
            filteredValue:
              getQueryParams?.[COMMON_QUERY_PARAMS.ACTIVE_STATUS] ?? null,
            ...getTypeStatusFilterProps(),
          },
          {
            title: 'Action',
            width: '10%',
            render: (record) => (
              <>
                <Button
                  type="link"
                  disabled={includes(
                    EXCLUDE_ROLES_FOR_TRANSACTION,
                    record?.roles[0],
                  )}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(
                      `${ROUTES.PAYMENTS}?${COMMON_QUERY_PARAMS.USER_ID}=${record?.id}`,
                    );
                  }}
                >
                  {UI_LABELS.PURCHASE_DETAILS}
                </Button>
              </>
            ),
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              navigate(`${ROUTES?.USERS}/edit/${record?.id}`);
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={userList}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: count,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
    </>
  );
}

export default UserList;
