import {
  ArrowLeftOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Col, Form, message, Row, Select, Upload } from 'antd';
import { startCase } from 'lodash';
import Papa from 'papaparse';
import React, { useEffect, useMemo, useState } from 'react';
import { mongoClient } from '../../../apollo';
import { CATEGORIES, ROUTES } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { extractCsvHeaders } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import { IMPORT_CSV } from '../graphql/mutations';
import {
  GET_COURT_BY_CATEGORY,
  GET_CSV_FILE_UPLOAD_SIGNED_URL,
} from '../graphql/queries';

const initialValues = { category: 'DIRECT_TAX' };

const ExistingFieldsList = [
  'appealNumber',
  'appellant',
  'respondent',
  'assessmentYear',
  'scrapePdfUrl',
  'pdfFileName',
  'pdfFilePath',
  'bench',
  'appealType',
  'appealFiledBy',
  'appealFiledOn',
  'benchAllotted',
  'caseStatus',
  'dateOfOrder',
  'dateOfPronouncement',
  'dateOfFirstHearing',
  'dateOfLastHearing',
  'dateOfNextHearing',
  'dateOfFinalHearing',
  'orderResult',
  'judges',
  'sections',
  'resultInFavourOf',
  'assesseeLawyers',
  'departmentLawyers',
  'keywords',
  'issuePeriod',
  'region',
];

const requiredFieldsList = [
  /* kept for future use */
  // 'category',
  // 'court',
  // 'bench',
  // 'scrapePdfUrl',
  'pdfFileName',
  'pdfFilePath',
  // 'appealNumber',
];

const CsvImportModal = ({ onClose }) => {
  const [form] = Form.useForm();
  const { navigate, navigateWithBack } = useRouter();
  const [fileList, setFileList] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedFields, setSelectedFields] = useState(
    Array(ExistingFieldsList.length).fill(null),
  );
  const [courtList, setCourtList] = useState([]);

  const selectedCategory = Form?.useWatch('category', form);

  const getCourtList = async ({ variables }) => {
    try {
      const data = await mongoClient?.query({
        query: GET_COURT_BY_CATEGORY,
        fetchPolicy: 'network-only',
        variables,
      });
      const prepareData = data?.data?.getCourtsByCategory?.map((item) => ({
        label: item?.name,
        value: item?.courtType,
        key: item?.courtType,
      }));
      setCourtList(prepareData);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('court list error', error);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      getCourtList({
        variables: {
          category: selectedCategory,
        },
      });
    }
  }, [selectedCategory]);

  const validateRow = (row, requiredFields) =>
    requiredFields.every((field) => row[field] && row[field] !== '');

  const GetSignedUrlAndImport = async ({ variables }) => {
    try {
      const data = await mongoClient?.query({
        query: GET_CSV_FILE_UPLOAD_SIGNED_URL,
        fetchPolicy: 'network-only',
        variables,
      });
      setLoading(false);
      return data?.data;
    } catch (error) {
      return error;
    }
  };

  const uploadImportCSV = async ({ variables }) => {
    try {
      mongoClient?.mutate({
        mutation: IMPORT_CSV,
        fetchPolicy: 'network-only',
        variables,
      });
      setTimeout(() => {
        setLoading(false);
        setFileList([]);
        setCsvHeaders([]);
        setSelectedFields([]);
        form.resetFields();
        navigate(ROUTES.IMPORT_CSV);
      }, 2000);
    } catch (error) {
      setLoading(false);
      // eslint-disable-next-line no-console
      console.log('error', error);
    }
  };

  const swapKeysAndValues = (obj) =>
    Object.fromEntries(Object.entries(obj).map(([key, value]) => [value, key]));

  const handleSubmit = async (values) => {
    setLoading(true);
    const { category, court, ...rest } = values;
    const swappedObj = swapKeysAndValues(rest);
    const requiredFields = Object.entries(rest)?.filter(([key]) =>
      requiredFieldsList?.includes(key),
    );
    const requiredCSVFields = requiredFields?.map((e) => e[1]);
    const file = fileList?.[0]?.originFileObj;

    if (file) {
      Papa.parse(file, {
        header: true,
        complete: async ({ data }) => {
          const sampleSize = Math.ceil(data?.length * 0.2);
          const sampledRows = data.slice(0, sampleSize);
          const validRows = [];
          sampledRows.forEach((row) => {
            const isValid = validateRow(row, requiredCSVFields);
            if (isValid) {
              validRows.push(row);
            }
          });
          const isValid = validRows.length === sampleSize;
          if (isValid) {
            const signUrlResponse = await GetSignedUrlAndImport({
              variables: { data: { fileName: file?.name } },
              fieldMappings: swappedObj,
            });
            const signedUrl =
              signUrlResponse?.getCsvFileUploadSignedUrl?.signedUrl;
            if (signedUrl) {
              try {
                setLoading(true);
                const response = await fetch(signedUrl, {
                  method: 'PUT',
                  body: file,
                });
                if (response?.status === 200) {
                  await uploadImportCSV({
                    variables: {
                      data: {
                        filePath:
                          signUrlResponse?.getCsvFileUploadSignedUrl?.key,
                        fieldMappings: swappedObj,
                        court,
                        category,
                      },
                    },
                  });
                }
              } catch (error) {
                // eslint-disable-next-line no-console
                console.log('eror', error);
              }
            }
          } else {
            message.error('Uploaded file is not valid');
            setLoading(false);
          }
        },
        error: (error) => {
          // eslint-disable-next-line no-console
          console.error('Error parsing CSV:', error);
        },
      });
    } else {
      setLoading(false);
    }
  };

  const handleUploadChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      const file = newFileList[0].originFileObj;
      const headers = await extractCsvHeaders(file);
      setCsvHeaders(headers?.filter((item) => item !== ''));
    }
  };

  const handleCancel = () => {
    setFileList([]);
    setCsvHeaders([]);
    setSelectedFields([]);
    form.resetFields();
    onClose();
  };

  const props = {
    onRemove: (file) => {
      setFileList((prev) => {
        const index = prev.indexOf(file);
        const newFileList = prev.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      const isCsv =
        file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
      if (!isCsv) {
        message.error('You can only upload CSV files!');
      }
      return isCsv;
    },
    fileList,
    onChange: handleUploadChange,
    accept: '.csv',
  };

  const handleFieldChange = (index, value) => {
    const newSelectedFields = [...selectedFields];
    newSelectedFields[index] = value;
    setSelectedFields(newSelectedFields);
  };

  const handleClear = (field) => {
    setSelectedFields((prev) => {
      const updated = { ...prev };
      delete updated[field];
      return updated;
    });
  };

  const categories = useMemo(
    () =>
      process.env.REACT_APP_IMPORT_CSV_DIRECT_TAX_ONLY === 'true'
        ? CATEGORIES.filter((item) => item.value === 'DIRECT_TAX')
        : CATEGORIES,
    [],
  );

  const CsvFieldMappingForm = ({ headers }) => (
    <div className="mt-40 mapping-form">
      <Row gutter={16}>
        <Col xs={24} sm={24} md={12} lg={8} span={8}>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true, message: 'Please select Category' }]}
          >
            <Select placeholder="Select Category" options={categories} />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={12} lg={8} span={8}>
          <Form.Item
            name="court"
            label="Court"
            rules={[{ required: true, message: 'Please select Court' }]}
          >
            <Select placeholder="Select Court" options={courtList} />
          </Form.Item>
        </Col>
        {ExistingFieldsList?.filter((item) =>
          selectedCategory === 'DIRECT_TAX'
            ? item !== 'issuePeriod'
            : item !== 'assessmentYear',
        )?.map((header, index) => (
          <Col key={header} xs={24} sm={24} md={12} lg={8} span={8}>
            <Form.Item
              label={startCase(header)}
              name={header}
              rules={[
                {
                  required: requiredFieldsList?.includes(header),
                  message: `Please select ${startCase(header)}`,
                },
              ]}
            >
              <Select
                placeholder={`Select ${startCase(header)}`}
                onChange={(value) => handleFieldChange(index, value)}
                allowClear
                showSearch
                onClear={() => handleClear(header)}
              >
                {headers
                  .filter((csvHeader) => !selectedFields.includes(csvHeader))
                  .map((csvHeader) => (
                    <Select.Option key={csvHeader} value={csvHeader}>
                      {csvHeader}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item className="mt-24">
            <Button className="mr-12" onClick={handleCancel}>
              {UI_LABELS.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {UI_LABELS.VALIDATE_CSV_IMPORT}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );

  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigateWithBack(ROUTES.IMPORT_CSV)}
          icon={<ArrowLeftOutlined />}
        />
        {UI_LABELS.ADD_BATCH}
      </div>

      <Card className="ant-body-scroll">
        <div className="card-body-wrapper">
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            initialValues={initialValues}
          >
            <div className="file-uploaded">
              {fileList?.length === 0 ? (
                <div>
                  <Upload.Dragger {...props} multiple={false}>
                    <p className="ant-upload-drag-icon">
                      <UploadOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Drag & Drop Your CSV File Here
                    </p>
                    <p className="ant-upload-hint">or Click to Select File</p>
                  </Upload.Dragger>
                </div>
              ) : (
                <div className="uploaded-file-view">
                  <div className="file-shower">
                    <FileExcelOutlined className="file-preview" />
                    <div className="close-icon">
                      <Button
                        icon={<CloseCircleOutlined />}
                        type="primary"
                        shape="rounded"
                        danger
                        disabled={false}
                        onClick={() => {
                          setFileList([]);
                          setSelectedFields([]);
                          setCsvHeaders([]);
                          form.resetFields();
                          onClose?.();
                        }}
                      />
                    </div>
                  </div>
                  <div className="body-meta mt-12 file-name">
                    {fileList?.[0]?.name}
                  </div>
                </div>
              )}
            </div>
            {fileList?.length > 0 && (
              <CsvFieldMappingForm headers={csvHeaders} />
            )}
          </Form>
        </div>
      </Card>
    </>
  );
};

export default CsvImportModal;
