import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import { CATEGORIES_KEYS, ROUTES } from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import useRouter from '../../hooks/useRouter';
import AssesseeForm from './components/AssesseeForm';
import { CREATE_ASSESSEE } from './graphql/mutations';

function CreateAssesses() {
  const { navigate, navigateWithBack } = useRouter();
  const { category } = useParams();
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  useEffect(
    () => {
      form.setFieldValue('category', CATEGORIES_KEYS[category]);
    },
    { category },
  );

  const createAssessee = async ({ variables }) => {
    await mongoClient
      ?.mutate({
        mutation: CREATE_ASSESSEE,
        variables,
      })
      ?.then(() => {
        navigate(-1);
        setLoading(false);
      })
      ?.catch(() => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    createAssessee({
      variables: {
        data: { ...values },
      },
    });
  };
  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigateWithBack(`/${category}${ROUTES.ASSESSES}`)}
          icon={<ArrowLeftOutlined />}
        />
        {UI_LABELS.ADD_ASSESSEE}
      </div>
      <AssesseeForm form={form} onFinish={onFinish} loading={loading} />
    </>
  );
}

export default CreateAssesses;
