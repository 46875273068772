import { Form, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../../apollo';
import { CATEGORIES_KEYS, LIMIT } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { formValidatorRules } from '../../../common/utils';
import MongoCommonSelect from '../../../components/MongoCommonSelect';
import { MERGE_DATA } from '../../judges/graphql/mutations';
import { LIST_ASSESSEESS } from '../graphql/queries';

const { pancard } = formValidatorRules;

const MergeDataModal = ({ open, onCancel }) => {
  const { category } = useParams();

  const variable = {
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
      category: CATEGORIES_KEYS[category],
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  };
  const [form] = Form.useForm();
  const ids = Form?.useWatch('ids', form);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const mergeIntoId = values?.ids;
    const dataToPass = {
      panCardNumber: values?.panCardNumber,
      mergeIntoId,
      type: 'ASSESSEE',
    };
    try {
      const data = await mongoClient?.mutate({
        mutation: MERGE_DATA,
        fetchPolicy: 'network-only',
        variables: { data: dataToPass },
      });
      if (data?.data?.mergeData?.message) {
        setLoading(false);
        onCancel();
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        title={UI_LABELS.EDIT_ASSESSEE_DETAILS}
        okText={UI_LABELS.SAVE}
        onOk={form.submit}
        okButtonProps={{ loading }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="ids"
            label="Assessee"
            rules={[{ required: true, message: 'Please select Assessee' }]}
          >
            <MongoCommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Assessee to Edit"
              className="role-select-in-calls"
              showSearch
              allowClear
              query={LIST_ASSESSEESS}
              fetchPolicy="network-only"
              responsePath="data.assesseesAdmin.data"
              valuePath="_id"
              labelPath="name"
              variables={variable}
              onSelectData={(value) => {
                form.setFieldValue('panCardNumber', value?.pancard ?? '');
              }}
              value={ids}
            />
          </Form.Item>
          <Form.Item
            name="panCardNumber"
            label="Pancard"
            rules={[
              pancard,
              { required: true, message: 'Please enter Pancard' },
            ]}
          >
            <Input placeholder="Pancard" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default MergeDataModal;
