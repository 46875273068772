import {
  ExclamationCircleFilled,
  HolderOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Form, Input, List, Modal, Space } from 'antd';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LIMIT, MAX_ORDER_LIMIT, REGEX } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import SearchComponent from '../../../components/SearchComponent';
import {
  CREATE_CATEGORY,
  CREATE_SUB_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_AND_SUB_CATEGORY,
  UPDATE_CATEGORY_ORDER,
} from '../graphql/mutations';
import { GET_CATEGORIES } from '../graphql/queries';

const categoryPopupType = {
  ADD_SUB_CATEGORY: 'ADD_SUB_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  UPDATE_SUB_CATEGORY: 'UPDATE_SUB_CATEGORY',
};

const { confirm } = Modal;

const RowContext = createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};
const SortableRow = ({
  id,
  item,
  selectedCategory,
  handleSelected,
  handleEdit,
  handleDelete,
  setActivatorNodeRef,
  buttonLoader,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS?.Transform?.toString(transform),
    transition,
    pointerEvents: 'auto',
  };

  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );

  return (
    <RowContext.Provider value={contextValue}>
      <List.Item
        ref={setNodeRef}
        style={style}
        {...attributes}
        className={`p-16 ${
          item?.id === selectedCategory ? 'item-selected' : ''
        }`}
      >
        <div onClick={() => handleSelected(item?.id)} className="cat-item">
          <div className="cat-head d-flex align-center gap-12">
            <DragHandle
              setActivatorNodeRef={setActivatorNodeRef}
              listeners={listeners}
            />
            {item?.name}
          </div>
          <div className="cat-body">
            <Space size={16} key={item?.id}>
              <Button
                type="link"
                className="action-btn"
                onClick={(e) => {
                  handleEdit(e, item);
                }}
              >
                {UI_LABELS.EDIT}
              </Button>
              <Button
                type="link"
                className="action-btn"
                onClick={(e) => handleDelete(e, item?.id)}
                danger
                disabled={buttonLoader}
              >
                {UI_LABELS.DELETE}
              </Button>
            </Space>
          </div>
        </div>
      </List.Item>
    </RowContext.Provider>
  );
};

const initialSubCategoryFormValue = {
  isShow: false,
  type: null,
  id: null,
  name: '',
  order: null,
};

function CategoryList({
  title,
  categoryId,
  handleSelectedCategory,
  fetchMainCategory,
}) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [addCategoryFormPopup, setAddCategoryFormPopup] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [Loader, setLoader] = useState(true);
  const [editCategoryFormPopup, setEditCategoryFormPopup] = useState(
    initialSubCategoryFormValue,
  );

  const [categoryList, setCategoryList] = useState([]);
  const [categoryParams, setCategoryParams] = useState({
    filter: {
      limit: LIMIT,
      search: '',
      skip: 0,
    },
    sort: [
      {
        sortBy: 'DESC',
        sortOn: 'order',
      },
    ],
  });

  const [addCategoryFormInstance] = Form.useForm();
  const [categoryNameEditFormInstance] = Form.useForm();

  const [fetchCategories] = useLazyQuery(GET_CATEGORIES);

  const [updateOrder] = useMutation(UPDATE_CATEGORY_ORDER, {
    fetchPolicy: 'network-only',
    onError() {},
  });

  useEffect(() => {
    fetchCategoriesCall();
  }, [categoryId]);

  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom = scrollTop + offsetHeight >= scrollHeight - 5;
    if (scrolledToBottom && hasMore) {
      fetchCategoriesCall();
    }
  };

  function fetchCategoriesCall(filterValue = null) {
    setLoader(true);
    let prepareFilter = filterValue === null ? categoryParams : filterValue;
    if (title === UI_LABELS.SUB_CATEGORIES) {
      prepareFilter = {
        ...prepareFilter,
        where: {
          id: categoryId,
        },
      };
    }
    fetchCategories({
      variables: prepareFilter,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        let listData = res?.templateCategoriesAdmin?.data;
        if (listData?.length < LIMIT) {
          setHasMore(false);
        }
        if (filterValue === null) {
          listData = [...categoryList, ...listData];
        }
        setCategoryParams({
          ...categoryParams,
          filter: {
            ...categoryParams.filter,
            skip: filterValue === null ? listData?.length : 0,
          },
        });
        setCategoryList(listData);
        setLoader(false);
      },
      onError: () => {
        setLoader(false);
      },
    });
  }

  const [createCategoryCall] = useMutation(CREATE_CATEGORY, {
    onCompleted: () => {
      const prepareParams = {
        ...categoryParams,
        filter: { ...categoryParams.filter, skip: 0 },
      };
      addCategoryFormInstance.setFieldsValue({
        categoryName: '',
        subCategory: [{ subCategory: '' }],
      });
      fetchCategoriesCall(prepareParams);
      setAddCategoryFormPopup(false);
      setButtonLoader(false);
    },
    onError: () => {
      setButtonLoader(false);
    },
  });

  const [updateCategoryCall] = useMutation(UPDATE_CATEGORY_AND_SUB_CATEGORY, {
    onCompleted: () => {
      fetchCategoriesCall({
        ...categoryParams,
        filter: { ...categoryParams.filter, skip: 0 },
      });
      setEditCategoryFormPopup(initialSubCategoryFormValue);
      setButtonLoader(false);
    },
    onError: () => {
      setButtonLoader(false);
    },
  });

  const [createSubCategoryCall] = useMutation(CREATE_SUB_CATEGORY, {
    onCompleted: () => {
      fetchCategoriesCall({
        ...categoryParams,
        filter: { ...categoryParams.filter, skip: 0 },
      });
      setEditCategoryFormPopup(initialSubCategoryFormValue);
      setButtonLoader(false);
    },
    onError: () => {
      setButtonLoader(false);
    },
  });

  const [deleteCategoryCall] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      if (categoryList?.length === 1) {
        fetchMainCategory();
      } else {
        fetchCategoriesCall({
          ...categoryParams,
          filter: { ...categoryParams.filter, skip: 0 },
        });
        setButtonLoader(false);
      }
    },
    onError: () => {
      setButtonLoader(false);
    },
  });

  const handleSelected = (id) => {
    if (title === 'Category' && id !== selectedCategory) {
      setSelectedCategory(id);
      handleSelectedCategory(id);
    }
  };

  const handleSearchChange = (e) => {
    const prepareParams = {
      ...categoryParams,
      filter: { ...categoryParams.filter, search: e, skip: 0 },
    };
    setHasMore(true);
    setCategoryParams(prepareParams);
    fetchCategoriesCall(prepareParams);
  };

  const handleEdit = async (e, item) => {
    const { name, id, order } = item;
    e.stopPropagation();
    if (title === 'Category') {
      setEditCategoryFormPopup({
        isShow: true,
        type: categoryPopupType.UPDATE_CATEGORY,
        name,
        id,
      });
    } else {
      setEditCategoryFormPopup({
        isShow: true,
        type: categoryPopupType.UPDATE_SUB_CATEGORY,
        name,
        id,
        order,
      });
    }
    categoryNameEditFormInstance.setFieldsValue({
      subCategoryName: name,
      order,
    });
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    setButtonLoader(true);
    if (categoryList.length === 1) {
      confirm({
        title: 'Deleting the last Sub Category will remove the main Category.',
        icon: <ExclamationCircleFilled />,
        okText: UI_LABELS.YES,
        okType: 'danger',
        cancelText: UI_LABELS.NO,
        onOk() {
          deleteCategoryCall({
            variables: {
              where: {
                ids: [id],
              },
            },
          });
        },
        onCancel() {
          setButtonLoader(false);
        },
      });
    } else {
      confirm({
        title: 'Are you sure you want to delete?',
        icon: <ExclamationCircleFilled />,
        okText: UI_LABELS.YES,
        okType: 'danger',
        cancelText: UI_LABELS.NO,
        onOk() {
          deleteCategoryCall({
            variables: {
              where: {
                ids: [id],
              },
            },
          });
        },
        onCancel() {
          setButtonLoader(false);
        },
      });
    }
  };

  const handleSaveNewCategoryForm = (formData) => {
    setButtonLoader(true);
    createCategoryCall({
      variables: {
        data: {
          name: formData.categoryName,
          subCategories: formData.subCategory.map((item) => item.subCategory),
        },
      },
    });
  };

  const handleEditCategoryNameForm = async (formValue) => {
    setButtonLoader(true);
    if (editCategoryFormPopup.type === categoryPopupType.ADD_SUB_CATEGORY) {
      createSubCategoryCall({
        variables: {
          data: {
            names: [formValue.subCategoryName],
            templateCategoryId: categoryId,
          },
        },
      });
    } else {
      if (categoryNameEditFormInstance?.isFieldTouched('order')) {
        await updateOrder({
          variables: {
            data: {
              order: Number(formValue?.order),
              templateCategoryId: categoryId,
            },
            where: { id: editCategoryFormPopup?.id },
          },
        });
      }
      await updateCategoryCall({
        variables: {
          data: {
            name: formValue.subCategoryName,
          },
          where: { id: editCategoryFormPopup.id },
        },
      });
    }
  };

  const handleAddCategoryForm = () => {
    if (title === 'Category') {
      setAddCategoryFormPopup(true);
    } else {
      setEditCategoryFormPopup({
        ...editCategoryFormPopup,
        isShow: true,
        type: categoryPopupType.ADD_SUB_CATEGORY,
      });
      categoryNameEditFormInstance.setFieldsValue({
        subCategoryName: '',
      });
    }
  };

  const handleClose = () => {
    addCategoryFormInstance.resetFields();
    setAddCategoryFormPopup(false);
  };

  const handleUpdateOrder = (order, id) => {
    if (order && id) {
      updateOrder({
        variables: {
          data: { order, templateCategoryId: categoryId },
          where: { id },
        },
        onCompleted: () => {
          const prepareParams = {
            ...categoryParams,
            filter: { ...categoryParams?.filter, skip: 0 },
          };
          fetchCategoriesCall(prepareParams);
        },
      });
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      const oldIndex = categoryList?.findIndex(
        (item) => item?.order === active?.id,
      );
      const newIndex = categoryList?.findIndex(
        (item) => item?.order === over?.id,
      );
      const prepareData = arrayMove(categoryList, oldIndex, newIndex);
      setCategoryList(prepareData);
      handleUpdateOrder(over?.id, categoryList?.[oldIndex]?.id);
    }
  };

  const orderValidator = (_rule, value, callback) => {
    if (value && Number(value) === 0) {
      callback('Order value cannot be 0');
    } else if (value && Number(value) > MAX_ORDER_LIMIT) {
      callback('Order value cannot be more than 10000');
    } else {
      callback();
    }
  };

  return (
    <div>
      <Card
        title={UI_LABELS.SUB_CATEGORIES}
        bordered={false}
        extra={
          <div className="cat-section">
            <SearchComponent
              className="list-search"
              getData={handleSearchChange}
            />
            {categoryList.length < 15 && (
              <Button
                className="action-btn"
                type="primary"
                onClick={() => handleAddCategoryForm()}
              >
                Add {title}
              </Button>
            )}
          </div>
        }
      >
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={categoryList?.map((i) => i?.order)}
            strategy={verticalListSortingStrategy}
          >
            <div onScroll={handleScroll} className="sub-cat-list">
              <List
                className="demo-loadmore-list"
                loading={Loader}
                itemLayout="horizontal"
                dataSource={categoryList}
                renderItem={(item) => (
                  <SortableRow
                    key={item?.id}
                    id={item?.order}
                    item={item}
                    selectedCategory={selectedCategory}
                    handleSelected={handleSelected}
                    handleEdit={handleEdit}
                    handleDelete={handleDelete}
                    buttonLoader={buttonLoader}
                  />
                )}
              />
            </div>
          </SortableContext>
        </DndContext>
      </Card>
      <Modal
        destroyOnClose
        title={UI_LABELS.ADD_CATEGORY}
        open={addCategoryFormPopup}
        onCancel={handleClose}
        footer={[
          <Button key="submit" onClick={() => setAddCategoryFormPopup(false)}>
            {UI_LABELS.CANCEL}
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => addCategoryFormInstance?.submit()}
          >
            {UI_LABELS.SAVE}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={addCategoryFormInstance}
            onFinish={handleSaveNewCategoryForm}
          >
            <Form.Item
              name="categoryName"
              label="Category"
              rules={[
                { required: true, message: 'Please enter Category' },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
                {
                  pattern: REGEX?.NAME,
                  message: 'Enter a valid Category',
                },
              ]}
            >
              <Input placeholder="Enter Category" />
            </Form.Item>
            <Form.List
              name="subCategory"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('At least 1 Sub Category must be provided'),
                      );
                    }
                  },
                },
                {
                  pattern: REGEX?.NAME,
                  message: 'Enter a valid Sub Category',
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      key={key}
                      className="dynamic-cat-input"
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'subCategory']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Sub Category',
                          },
                          {
                            whitespace: true,
                            message: 'Invalid input',
                          },
                          {
                            pattern: REGEX?.NAME,
                            message: 'Enter a valid Sub Category',
                          },
                        ]}
                      >
                        <Input placeholder="Sub Category" />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {UI_LABELS.ADD_SUB_CATEGORY}
                    </Button>
                  </Form.Item>
                  <Form.ErrorList className="category-error" errors={errors} />
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        title={`${
          editCategoryFormPopup.type === categoryPopupType.ADD_SUB_CATEGORY
            ? 'Add'
            : 'Update'
        } ${
          editCategoryFormPopup.type === categoryPopupType.UPDATE_CATEGORY
            ? 'Category'
            : 'Sub category'
        }`}
        open={editCategoryFormPopup.isShow}
        onCancel={() => setEditCategoryFormPopup(initialSubCategoryFormValue)}
        footer={[
          <Button
            key="submit"
            onClick={() =>
              setEditCategoryFormPopup(initialSubCategoryFormValue)
            }
          >
            Cancel
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => categoryNameEditFormInstance?.submit()}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <Form
            form={categoryNameEditFormInstance}
            onFinish={handleEditCategoryNameForm}
          >
            <Form.Item
              name="subCategoryName"
              label={`${
                editCategoryFormPopup.type === categoryPopupType.UPDATE_CATEGORY
                  ? 'Category'
                  : 'Sub category'
              }`}
              rules={[
                {
                  required: true,
                  message: `Please enter ${
                    editCategoryFormPopup.type ===
                    categoryPopupType.UPDATE_CATEGORY
                      ? 'Category'
                      : 'Sub category'
                  }`,
                },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
                {
                  pattern: REGEX?.NAME,
                  message: `Enter a valid ${
                    editCategoryFormPopup.type ===
                    categoryPopupType.UPDATE_CATEGORY
                      ? 'Category'
                      : 'Sub Category'
                  }`,
                },
              ]}
            >
              <Input
                placeholder={`Enter ${
                  editCategoryFormPopup.type ===
                  categoryPopupType.UPDATE_CATEGORY
                    ? 'Category'
                    : 'Sub category'
                }`}
              />
            </Form.Item>
            {editCategoryFormPopup.type ===
              categoryPopupType.UPDATE_SUB_CATEGORY && (
              <Form.Item
                name="order"
                label="Order"
                rules={[
                  {
                    required: true,
                    message: 'Please enter Order',
                  },
                  { validator: orderValidator },
                ]}
              >
                <Input type="number" placeholder="Enter Order" />
              </Form.Item>
            )}
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default CategoryList;
