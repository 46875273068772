import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CATEGORIES_KEYS,
  JUDGES_TYPE,
  TAX_CATEGORIES,
} from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { formValidatorRules, getCourtList } from '../../../common/utils';
import LoaderComponent from '../../../components/LoaderComponent';
import useRouter from '../../../hooks/useRouter';

const { required } = formValidatorRules;

function JudgeForm({
  form,
  onFinish,
  loading,
  initialValues = null,
  loadingJudgeDetails = true,
  styling = { sm: 12, md: 8, lg: 6 },
  isModalView = false,
  handleCancel,
}) {
  const { navigate } = useRouter();
  const { category } = useParams();

  useEffect(() => {
    form.setFieldsValue({
      isVisible: initialValues?.isVisible,
      category: initialValues?.category ?? CATEGORIES_KEYS[category],
    });
  }, [initialValues]);

  const [courtList, setCourtList] = useState([]);

  const getCourts = async (categoryType) => {
    const data = await getCourtList({
      variables: { category: CATEGORIES_KEYS[categoryType] },
    });
    setCourtList(data);
  };

  useEffect(() => {
    getCourts(category);
  }, [category]);

  if (loadingJudgeDetails) {
    return <LoaderComponent />;
  }

  const JudgeFormContent = () => (
    <div className="card-body-wrapper">
      <Form
        form={form}
        name="register"
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <Row gutter={16}>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter Name',
                },
              ]}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="noSpaceName"
              label="No Space Name"
              rules={[
                {
                  whitespace: true,
                  message: 'Please enter No Space Name',
                },
              ]}
            >
              <Input placeholder="Enter No Space Name" />
            </Form.Item>
          </Col>

          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item name="type" label="Type" className="user-type-input">
              <Select
                placeholder="Select Type"
                allowClear
                onChange={(val) => form.setFieldValue('type', val ?? null)}
              >
                {Object.entries(JUDGES_TYPE).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="category"
              label="Category"
              className="user-type-input"
              rules={[
                {
                  required: true,
                  message: 'Please select Category',
                },
              ]}
            >
              <Select placeholder="Select Category" mode="multiple" disabled>
                {Object.entries(TAX_CATEGORIES).map(([value, label]) => (
                  <Select.Option key={value} value={value}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required, message: 'Please enter Slug' }]}
            >
              <Input placeholder="Enter Slug" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <p className="mt-0 mb-8 feature-label">Merged No Space Names</p>
            <Form.List
              name="mergedNoSpaceNames"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names?.length < 1) {
                      return Promise.reject(
                        new Error(
                          'At least 1 Merged No Space Name must be added',
                        ),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields?.map(({ key, name, ...restField }) => (
                    <Space
                      className="dynamic-cat-input"
                      key={key}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name]}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Merged No Space Names',
                          },
                        ]}
                      >
                        <Input
                          className="input-cat"
                          placeholder="Enter Merged No Space Names"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {UI_LABELS.ADD_ITEM}
                    </Button>
                  </Form.Item>
                  <Form.ErrorList className="category-error" errors={errors} />
                </>
              )}
            </Form.List>
          </Col>

          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item
              name="court"
              label="Court"
              rules={[
                {
                  required: true,
                  message: 'At least 1 Court must be added',
                },
              ]}
            >
              <Select placeholder="Select Court" mode="multiple">
                {courtList?.map((court) => (
                  <Select.Option key={court?.value} value={court?.value}>
                    {court?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* kept for future use */}
          {/* <Col xs={24} sm={24} md={24} lg={24}>
            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  whitespace: true,
                  message: 'Please enter Description',
                },
              ]}
            >
              <TextArea type="text" placeholder="Enter Description" />
            </Form.Item>
          </Col> */}
          <Col xs={24} sm={styling.sm} md={styling.md} lg={styling.lg}>
            <Form.Item name="isVisible" valuePropName="checked">
              <Checkbox defaultChecked={initialValues?.isVisible}>
                Is Visible
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item className={isModalView ? 'text-end' : null}>
          <Space size={8}>
            <Button
              onClick={() => (!isModalView ? navigate(-1) : handleCancel?.())}
            >
              {UI_LABELS.CANCEL}
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              {UI_LABELS.SAVE}
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );

  return (
    <>
      {isModalView ? (
        <JudgeFormContent />
      ) : (
        <Card className="ant-body-scroll">
          <JudgeFormContent />
        </Card>
      )}
    </>
  );
}

export default JudgeForm;
