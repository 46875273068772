import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Descriptions, Form } from 'antd';
import dayjs from 'dayjs';
import { React, useEffect, useState } from 'react';
import {
  dateTimeFormat,
  ROUTES,
  TRANSACTION_TYPE,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { noticeBoardPlanTitle } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import TransactionForm from './components/TransactionForm';
import { EDIT_TRANSACTION } from './graphql/mutations';
import { GET_TRANSACTION } from './graphql/queries';

const initialValues = {
  userId: {},
  type: '',
  itemId: {},
  status: '',
  amount: '',
  billing: {},
};

export default function EditTransaction() {
  const { params, navigateWithBack } = useRouter();
  const { id, userId } = params;
  const [role, setRole] = useState(null);

  const [form] = Form.useForm();
  const [isPaymentCanceled, setIsPaymentCanceled] = useState(false);

  const getBillingFrom = ({ transactionDetail, type }) => {
    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return transactionDetail?.template?.title;
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return transactionDetail?.subscriptionPlan?.title;
      case TRANSACTION_TYPE.CALL:
        return transactionDetail?.consultationCall?.title;
      default:
        break;
    }
  };

  const [getTransactionDetails, { data }] = useLazyQuery(GET_TRANSACTION, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const transactionDetail = res?.transactionLogAdmin;
      if (transactionDetail?.reason) {
        setIsPaymentCanceled(true);
      }
      const formValues = {
        userId: transactionDetail?.user?.firstName
          ? `${transactionDetail?.user?.firstName} ${transactionDetail?.user?.lastName}`
          : `${transactionDetail?.billing?.firstName} ${transactionDetail?.billing?.lastName}`,
        itemId: getBillingFrom({
          transactionDetail,
          type: transactionDetail?.type,
        }),
        status: transactionDetail?.status,
        type: transactionDetail?.type,
        amount: transactionDetail?.amount,
        reason: transactionDetail?.reason,
        billing: transactionDetail?.billing,
      };
      setRole(transactionDetail?.user?.roles);
      form.setFieldsValue(formValues);
    },
    onError() {},
  });

  const [editTransaction, { loading: updateLoading }] = useMutation(
    EDIT_TRANSACTION,
    {
      fetchPolicy: 'network-only',
      onCompleted: () => {
        navigateWithBack(ROUTES?.TRANSACTION_LOGS);
      },
      onError() {},
    },
  );

  useEffect(() => {
    getTransactionDetails({
      variables: {
        data: {
          id,
          userId,
        },
      },
    });
  }, [id, userId]);

  const onFinish = (values) => {
    if (id) {
      editTransaction({
        variables: {
          where: { id },
          data: {
            status: values?.status,
            reason: values?.status === 'CANCELED' ? values?.reason : '',
          },
        },
      });
    }
  };

  const getTransactionType = (type) => {
    switch (type) {
      case TRANSACTION_TYPE.TEMPLATE:
        return 'template';
      case TRANSACTION_TYPE.SUBSCRIPTION:
        return 'subscriptionPlan';
      case TRANSACTION_TYPE.CALL:
        return 'consultationCall';
      default:
        break;
    }
  };

  const itemData = data?.transactionLogAdmin;
  const getPlanDetails = () => {
    const planData = itemData?.[getTransactionType(itemData?.type)];
    if (itemData?.type === TRANSACTION_TYPE.SUBSCRIPTION) {
      return noticeBoardPlanTitle(planData);
    }
    return <span>{itemData?.[getTransactionType(itemData?.type)]?.title}</span>;
  };
  return (
    <>
      <div className="title">
        <Button
          type="text"
          shape="circle"
          onClick={() => navigateWithBack(ROUTES.TRANSACTION_LOGS)}
          icon={<ArrowLeftOutlined />}
        />
        {UI_LABELS.EDIT_TRANSACTION}
      </div>
      {id && (
        <Descriptions
          className="mb-12"
          title="Transaction Details"
          bordered
          size="small"
          items={[
            {
              key: 'details',
              label: UI_LABELS.ITEM_DETAILS,
              children: getPlanDetails(),
            },
            {
              key: 'createdAt',
              label: UI_LABELS.TRANSACTION_DATE,
              children: (
                <span>
                  <span>
                    {itemData?.createdAt
                      ? dayjs(itemData?.createdAt)?.format(dateTimeFormat)
                      : '-'}
                  </span>
                </span>
              ),
            },
            {
              key: 'settledDate',
              label: UI_LABELS.SETTLED_DATE,
              children: (
                <span>
                  {itemData?.settledDate
                    ? dayjs(itemData?.settledDate)?.format(dateTimeFormat)
                    : '-'}
                </span>
              ),
            },
          ]}
        />
      )}
      {data && (
        <TransactionForm
          role={role}
          form={form}
          id={id}
          onFinish={onFinish}
          loading={updateLoading}
          initialValues={initialValues}
          setIsPaymentCanceled={setIsPaymentCanceled}
          isPaymentCanceled={isPaymentCanceled}
        />
      )}
    </>
  );
}
