import {
  AccountBookOutlined,
  AuditOutlined,
  BellOutlined,
  BookOutlined,
  ContactsOutlined,
  ContainerOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileSyncOutlined,
  FileZipOutlined,
  FolderOpenOutlined,
  GoldOutlined,
  HomeOutlined,
  IdcardOutlined,
  LoginOutlined,
  ProfileOutlined,
  SettingOutlined,
  SnippetsOutlined,
  TeamOutlined,
  UserOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import { Avatar, Badge, Menu } from 'antd';
import { React, useContext, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { ROLE_KEYS, ROUTES, USER } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { avtarPlaceholder, defaultRangePickerURL } from '../../../common/utils';
import useRouter from '../../../hooks/useRouter';
import NotificationDrawerDesktop from './NotificationDrawerDesktop';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

function Sidebar() {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const showDrawer = () => {
    setNotificationOpen(true);
  };
  const onClose = () => {
    setNotificationOpen(false);
  };

  // eslint-disable-next-line no-undef
  const user = JSON.parse(localStorage.getItem(USER));
  const {
    navigate,
    location: { pathname },
  } = useRouter();
  const onMenuSelect = (e) => {
    let targetRoute = e?.key;
    const routesWithQueryParams = [
      ROUTES.REVIEW_APPEALS,
      ROUTES.REPORTS,
      ROUTES.MAIN,
    ];

    if (routesWithQueryParams.includes(e?.key)) {
      const queryParams = new URLSearchParams(defaultRangePickerURL());
      targetRoute = `${e?.key}?${queryParams.toString()}`;
    }
    navigate(targetRoute);
  };

  const { getCurrentUser, currentUserData } = useContext(AppContext);
  const { firstName = '', lastName = '' } = getCurrentUser() || {};
  const menuItems = useMemo(() => {
    const baseItems = [
      getItem(UI_LABELS.DASHBOARD, ROUTES?.MAIN, <HomeOutlined />),
      getItem(UI_LABELS.USERS, ROUTES.USERS, <TeamOutlined />),
      getItem(
        UI_LABELS.TEMPLATE_CATEGORIES,
        ROUTES.TEMPLATE_CATEGORY,
        <SnippetsOutlined />,
      ),
      getItem(UI_LABELS.TEMPLATES, ROUTES.TEMPLATE, <FileSyncOutlined />),
      getItem(UI_LABELS.DIRECT_TAX, ROUTES.DIRECT_TAX, <AuditOutlined />, [
        getItem(
          UI_LABELS.JUDGEMENTS,
          `${ROUTES.DIRECT_TAX}${ROUTES.JUDGEMENTS}`,
        ),
        getItem(UI_LABELS.JUDGES, `${ROUTES.DIRECT_TAX}${ROUTES.JUDGES}`),
        getItem(UI_LABELS.ASSESSEES, `${ROUTES.DIRECT_TAX}${ROUTES.ASSESSES}`),
        getItem(UI_LABELS.LAWYERS, `${ROUTES.DIRECT_TAX}${ROUTES.LAWYERS}`),
        getItem(UI_LABELS.KEYWORDS, `${ROUTES.DIRECT_TAX}${ROUTES.KEYWORDS}`),
        getItem(UI_LABELS.SECTIONS, `${ROUTES.DIRECT_TAX}${ROUTES.SECTIONS}`),
      ]),
      getItem(UI_LABELS.INDIRECT_TAX, ROUTES.INDIRECT_TAX, <UserOutlined />, [
        getItem(
          UI_LABELS.JUDGEMENTS,
          `${ROUTES.INDIRECT_TAX}${ROUTES.JUDGEMENTS}`,
        ),
        getItem(UI_LABELS.JUDGES, `${ROUTES.INDIRECT_TAX}${ROUTES.JUDGES}`),
        getItem(
          UI_LABELS.ASSESSEES,
          `${ROUTES.INDIRECT_TAX}${ROUTES.ASSESSES}`,
        ),
        getItem(UI_LABELS.LAWYERS, `${ROUTES.INDIRECT_TAX}${ROUTES.LAWYERS}`),
        getItem(UI_LABELS.KEYWORDS, `${ROUTES.INDIRECT_TAX}${ROUTES.KEYWORDS}`),
        getItem(UI_LABELS.SECTIONS, `${ROUTES.INDIRECT_TAX}${ROUTES.SECTIONS}`),
      ]),
      getItem(UI_LABELS.CORPORATE_LAW, ROUTES.CORPORATE_LAW, <BookOutlined />, [
        getItem(
          UI_LABELS.JUDGEMENTS,
          `${ROUTES.CORPORATE_LAW}${ROUTES.JUDGEMENTS}`,
        ),
        getItem(UI_LABELS.JUDGES, `${ROUTES.CORPORATE_LAW}${ROUTES.JUDGES}`),
        getItem(
          UI_LABELS.ASSESSEES,
          `${ROUTES.CORPORATE_LAW}${ROUTES.ASSESSES}`,
        ),
        getItem(UI_LABELS.LAWYERS, `${ROUTES.CORPORATE_LAW}${ROUTES.LAWYERS}`),
        getItem(
          UI_LABELS.KEYWORDS,
          `${ROUTES.CORPORATE_LAW}${ROUTES.KEYWORDS}`,
        ),
        getItem(
          UI_LABELS.SECTIONS,
          `${ROUTES.CORPORATE_LAW}${ROUTES.SECTIONS}`,
        ),
      ]),
      getItem(UI_LABELS.JUDGEMENT_AI, '', <ContainerOutlined />, [
        getItem(UI_LABELS.ACTS, ROUTES.ACTS),
        getItem(UI_LABELS.NOTIFICATIONS_CIRCULARS, ROUTES.NOTIFICATIONS),
        getItem(UI_LABELS.SUBMISSIONS, ROUTES.SUBMISSIONS),
      ]),
      getItem(
        UI_LABELS.CONSULTATION_CALLS,
        ROUTES.CONSULTATION_CALLS,
        <ContactsOutlined />,
      ),
      getItem(UI_LABELS.QUOTATIONS, ROUTES.QUOTATIONS, <FolderOpenOutlined />),
      getItem(
        UI_LABELS.SUBSCRIPTION_PLANS,
        ROUTES.SUBSCRIPTION_PLANS,
        <GoldOutlined />,
      ),
      getItem(
        UI_LABELS.PAYMENT_DISTRIBUTION,
        ROUTES.PAYMENT_DISTRIBUTION,
        <AccountBookOutlined />,
      ),
      process.env.REACT_APP_IS_SHOW_CONFIGURATION === 'true' &&
        getItem(
          UI_LABELS.CONFIGURATION,
          ROUTES.CONFIGURATION,
          <SettingOutlined />,
        ),
      getItem(
        UI_LABELS.TRANSACTION_LOGS,
        ROUTES.TRANSACTION_LOGS,
        <ProfileOutlined />,
      ),
      getItem(UI_LABELS.REPORTS, ROUTES.REPORTS, <FileZipOutlined />),
      getItem(
        UI_LABELS.REVIEW_APPEALS,
        ROUTES.REVIEW_APPEALS,
        <FileDoneOutlined />,
      ),
      getItem(UI_LABELS.PAYMENTS, ROUTES.PAYMENTS, <WalletOutlined />),
      process.env.REACT_APP_ALLOW_IMPORT_CSV === 'true' &&
        getItem(UI_LABELS.IMPORT_CSV, ROUTES.IMPORT_CSV, <FileExcelOutlined />),
    ];

    if (user?.roles?.[0] === ROLE_KEYS.SUPER_ADMIN) {
      return [...baseItems];
    }
    if (user?.roles?.[0] === ROLE_KEYS.ADMIN) {
      return [...baseItems];
    }
    if (user?.roles?.[0] === ROLE_KEYS.STAFF) {
      return [
        getItem(UI_LABELS.DASHBOARD, ROUTES.MAIN, <HomeOutlined />),
        getItem(UI_LABELS?.TEMPLATES, ROUTES?.TEMPLATE, <FileSyncOutlined />),
        getItem(UI_LABELS.DIRECT_TAX, ROUTES.DIRECT_TAX, <AuditOutlined />, [
          getItem(
            UI_LABELS.JUDGEMENTS,
            `${ROUTES.DIRECT_TAX}${ROUTES.JUDGEMENTS}`,
          ),
        ]),
        getItem(UI_LABELS.INDIRECT_TAX, ROUTES.INDIRECT_TAX, <UserOutlined />, [
          getItem(
            UI_LABELS.JUDGEMENTS,
            `${ROUTES.INDIRECT_TAX}${ROUTES.JUDGEMENTS}`,
          ),
        ]),
        getItem(
          UI_LABELS.CORPORATE_LAW,
          ROUTES.CORPORATE_LAW,
          <BookOutlined />,
          [
            getItem(
              UI_LABELS.JUDGEMENTS,
              `${ROUTES.CORPORATE_LAW}${ROUTES.JUDGEMENTS}`,
            ),
          ],
        ),
        getItem(
          UI_LABELS.REVIEW_APPEALS,
          ROUTES.REVIEW_APPEALS,
          <FileDoneOutlined />,
        ),
      ];
    }
    return baseItems;
  }, [user]);
  const items = [
    {
      key: 'notification',
      label: (
        <span>
          Notification{' '}
          <Badge
            size="small"
            count={currentUserData?.unreadNotifications ?? ''}
            overflowCount={9}
          />{' '}
        </span>
      ),
      icon: <BellOutlined className="text-20" />,
      onClick: showDrawer,
    },
    {
      key: 'user',
      label: `${firstName} ${lastName}`,
      icon: (
        <Avatar alt="Avatar">{avtarPlaceholder(firstName, lastName)}</Avatar>
      ),
      children: [
        {
          key: 'profile',
          label: <Link to={ROUTES?.PROFILE}>My Profile</Link>,
          icon: <IdcardOutlined />,
        },
        {
          key: 'logout',
          label: <Link to={ROUTES?.LOGOUT}>Logout</Link>,
          icon: <LoginOutlined />,
        },
      ],
    },
    {
      key: 'footer',
      label: <span> Counselvise © {new Date()?.getFullYear()}</span>,
    },
  ];

  return (
    <div className="side-bar">
      <Menu
        theme="light"
        mode="inline"
        selectedKeys={
          pathname.split('/').length > 2
            ? [`/${pathname?.split('/')?.[1]}`]
            : [pathname]
        }
        defaultOpenKeys={
          pathname.split('/').length > 2 && [`/${pathname?.split('/')?.[1]}`]
        }
        onSelect={onMenuSelect}
        onClick={onMenuSelect}
        items={menuItems}
      />
      <div className="m-hide">
        <Menu theme="light" mode="vertical" items={items} />
        {notificationOpen && (
          <NotificationDrawerDesktop
            open={notificationOpen}
            onClose={onClose}
          />
        )}
      </div>
    </div>
  );
}

export default Sidebar;
