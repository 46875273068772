import { useLazyQuery } from '@apollo/client';
import { Card, Col, DatePicker, Row, Select, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ActiveUsersIcon,
  InActiveUsersIcon,
  UnverifiedUserIcon,
  UsersIcon,
  VerifiedUsersIcon,
} from '../../assets/svg';
import {
  COMMON_QUERY_PARAMS,
  STANDARD_DATE_FORMAT,
  USER_ROLES_FILTERS,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { disableFutureDate, prepareFilterFromUrl } from '../../common/utils';
import LoaderComponent from '../../components/LoaderComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import './dashboard.less';
import USER_ANALYTICS from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function Dashboard() {
  const [userCounts, setUserCounts] = useState(null);
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();

  const getFilter = prepareFilterFromUrl(
    {
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS?.START]
          ? getQueryParams[COMMON_QUERY_PARAMS?.START]
          : null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS?.END]
          ? getQueryParams[COMMON_QUERY_PARAMS?.END]
          : null,
      },
      roles: {
        queryString: COMMON_QUERY_PARAMS.ROLES,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS.ROLES] ?? null,
      },
    },
    getQueryParams,
  );

  const [fetchAnalyticsCall, { loading }] = useLazyQuery(USER_ANALYTICS);

  const getUserAnalytics = () => {
    const prepareVariable = { filter: getFilter };
    fetchAnalyticsCall({
      variables: prepareVariable,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setUserCounts(res?.userCounts);
      },
      onError: () => {},
    });
  };

  useEffect(() => {
    getUserAnalytics();
  }, [location.search]);

  const handleDatePicker = (date) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.START]:
        date && dayjs(date?.[0])?.startOf('day').toISOString(),
      [COMMON_QUERY_PARAMS.END]:
        date && dayjs(date?.[1])?.endOf('day').toISOString(),
    });
  };

  const handleUserTypeChange = (value) => {
    const prepareValue = value && value?.length ? value : null;
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.ROLES]: prepareValue,
    });
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.DASHBOARD}
        </Title>
        <div className="d-flex gap-12 flex-wrap filter-input mb-12 justify-end">
          <div className="d-flex gap-12 flex-wrap">
            <RangePicker
              onChange={handleDatePicker}
              value={[
                // eslint-disable-next-line no-nested-ternary
                getQueryParams?.[COMMON_QUERY_PARAMS.START]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.START])
                  : null,
                // eslint-disable-next-line no-nested-ternary
                getQueryParams?.[COMMON_QUERY_PARAMS.END]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.END])
                  : null,
              ]}
              format={STANDARD_DATE_FORMAT}
              disabledDate={disableFutureDate}
              placeholder={['Start Date', 'End Date']}
              allowClear
            />
            <Select
              placeholder="Select User Type"
              onChange={handleUserTypeChange}
              mode="multiple"
              value={getQueryParams[COMMON_QUERY_PARAMS.ROLES] ?? null}
              className="role-select-in-calls pr-8 pl-8"
              options={[...USER_ROLES_FILTERS]}
            />
          </div>
        </div>
      </div>
      <LoaderComponent spinning={loading}>
        <div>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <UsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">
                      {UI_LABELS.TOTAL_USERS}
                    </div>
                    <div className="analytics-card-description">
                      {userCounts?.totalUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <VerifiedUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">
                      {UI_LABELS.VERIFIED_USERS}
                    </div>
                    <div className="analytics-card-description">
                      {userCounts?.phoneAndEmailVerifiedUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <InActiveUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">
                      {UI_LABELS.INACTIVE_USERS}
                    </div>
                    <div className="analytics-card-description">
                      {userCounts?.inactiveUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <ActiveUsersIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">
                      {UI_LABELS.ACTIVE_USERS}
                    </div>
                    <div className="analytics-card-description">
                      {userCounts?.activeUsers ?? 0}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={24} sm={12} md={12} lg={8}>
              <Card className="full-height" hoverable>
                <div className="d-flex w-full h-full flex-horizontal align-center">
                  <div className="p-8">
                    <UnverifiedUserIcon />
                  </div>
                  <div className="d-flex flex-vertical p-8 w-full h-full">
                    <div className="analytics-card-title">
                      {UI_LABELS.UNVERIFIED_USERS}
                    </div>
                    <div className="analytics-card-description">
                      {(userCounts?.totalUsers ?? 0) -
                        (userCounts?.phoneAndEmailVerifiedUsers ?? 0)}
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </LoaderComponent>
    </>
  );
}

export default Dashboard;
