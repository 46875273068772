import { useLazyQuery } from '@apollo/client';
import { Button, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT_OPTION,
  SUBSCRIPTION_TYPE,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import { LIST_SUBSCRIPTION_PLANS } from './graphql/queries';

const { Title } = Typography;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  title: COMMON_QUERY_PARAMS.TITLE,
  price: COMMON_QUERY_PARAMS.PRICE,
};

function SubscriptionPlansList() {
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [listPlans, { data: plansData, loading }] = useLazyQuery(
    LIST_SUBSCRIPTION_PLANS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    listPlans({
      variables: prepareVariable,
    });
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    const prepareSorter = {};
    Object?.keys(fieldToParams)?.forEach((item) => {
      sorterAry?.forEach((filterItem) => {
        if (filterItem?.columnKey && item === filterItem?.columnKey) {
          // eslint-disable-next-line no-nested-ternary
          prepareSorter[
            fieldToParams[filterItem?.columnKey]
            // eslint-disable-next-line no-nested-ternary
          ] = filterItem?.order
            ? filterItem?.order === 'descend'
              ? SORT_OPTION.DESC
              : SORT_OPTION.ASC
            : null;
        }
      });
      prepareSorter[fieldToParams?.[item]] =
        prepareSorter[fieldToParams?.[item]] ?? null;
    });
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
      ...prepareSorter,
    });
  };

  const handleSearchChange = (e) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
      [COMMON_QUERY_PARAMS.PAGE]: null,
    });
  };

  const columns = [
    {
      title: UI_LABELS.TITLE,
      sorter: { multiple: 1 },
      sortOrder: getSorter.sorters.title,
      dataIndex: 'title',
      key: 'title',
    },

    {
      title: UI_LABELS.FEATURES,
      render: (item) =>
        item?.featureList?.length > 0 ? item?.featureList?.join(', ') : '-',
    },
    {
      title: UI_LABELS.TYPE,
      dataIndex: 'type',
      render: (item) => SUBSCRIPTION_TYPE[item] ?? '-',
    },
    {
      title: UI_LABELS.PRICE,
      sorter: { multiple: 2 },
      sortOrder: getSorter.sorters.price,
      dataIndex: 'price',
      render: (item) => `₹ ${item}`,
      key: 'price',
    },
    {
      title: UI_LABELS.STATUS,
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      render: (item) => (
        <Button type="link">
          <Link to={`${ROUTES.SUBSCRIPTION_PLANS}/edit/${item?.id}`}>
            {UI_LABELS.EDIT}
          </Link>
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.SUBSCRIPTION_PLANS}
          {plansData?.subscriptionPlansAdmin?.count
            ? ` (${plansData?.subscriptionPlansAdmin?.count})`
            : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={plansData?.subscriptionPlansAdmin?.data}
        pagination={{
          total: plansData?.subscriptionPlansAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
    </>
  );
}

export default SubscriptionPlansList;
