import { ExportOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  Badge,
  Button,
  DatePicker,
  Divider,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { capitalize, debounce, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  BOOKED_FROM_SELECTION,
  COMMON_QUERY_PARAMS,
  DISTRIBUTION_STATUS_FILTER_OPTIONS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  PAYMENT_MODE_FILTER_OPTIONS,
  ROLE_KEYS,
  ROUTES,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
  TRANSACTION_LOGS_FILTER,
  TRANSACTION_STATUS_COLOR,
  TRANSACTION_STATUS_LABELS,
  TRANSACTION_STATUS_SELECTION,
  TRANSACTION_TYPE,
  USER_TYPE_LABEL,
  dateTimeFormat,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  noticeBoardPlanTitle,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import { EXPORT_REPORTS } from '../reports/graphql/queries';
import {
  FETCH_USER_BY_ID_QUERY,
  LIST_USER_QUERY,
} from '../users/graphql/queries';
import TransactionDetails from './components/TransactionDetails';
import { REGENERATE_INVOICE, TRANSACTION_PAYOUT } from './graphql/mutations';
import { GET_CONSULTANT_AMOUNT, GET_TRANSACTION_LOGS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
};
function TransactionLogsList() {
  const { navigate } = useRouter();

  const [defaultUserOption, setDefaultUserOption] = useState(null);
  const [transactionLogsData, setTransactionLogsData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const location = useLocation();

  const [showAmount, setShowAmount] = useState({
    pendingAmount: null,
    TotalAmount: null,
  });
  const [modal, contextHolder] = Modal.useModal();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const userId = getQueryParams[COMMON_QUERY_PARAMS.USER_ID];
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [fetchUserDataCall] = useLazyQuery(FETCH_USER_BY_ID_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const userData = res?.user;
      setDefaultUserOption({
        label: `${userData?.firstName} ${userData?.lastName}`,
        value: userData?.id,
      });
    },
  });

  useEffect(() => {
    if (userId && !defaultUserOption) {
      fetchUserDataCall({ variables: { where: { id: userId } } });
    }
  }, [userId]);

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      bookedFrom: {
        queryString: COMMON_QUERY_PARAMS.BOOKED_FROM,
        defaultValue: 'WEB',
      },
      providerId: {
        queryString: COMMON_QUERY_PARAMS.USER_ID,
        defaultValue: null,
      },
      status: {
        queryString: COMMON_QUERY_PARAMS.STATUS,
        defaultValue: null,
      },
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS?.START]
          ? getQueryParams[COMMON_QUERY_PARAMS?.START]
          : null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: null,
        value: getQueryParams[COMMON_QUERY_PARAMS?.END]
          ? getQueryParams[COMMON_QUERY_PARAMS?.END]
          : null,
      },
      type: {
        queryString: COMMON_QUERY_PARAMS.TYPE,
        defaultValue: null,
      },
      paymentMode: {
        queryString: COMMON_QUERY_PARAMS.PAYMENT_MODE,
        defaultValue: null,
      },
      distributionStatus: {
        queryString: COMMON_QUERY_PARAMS.DISTRIBUTION_STATUS,
        defaultValue: null,
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const [listTransaction, { data: transactionData, loading }] = useLazyQuery(
    GET_TRANSACTION_LOGS,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: (res) => {
        setTransactionLogsData(res?.transactionLogsAdmin?.data);
      },
    },
  );

  const listTransactionCall = () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    listTransaction({
      variables: prepareVariable,
    });
  };

  const [regenerateInvoice, { loading: invoiceLoading }] = useMutation(
    REGENERATE_INVOICE,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: () => {
        listTransactionCall();
        setShowModal(false);
      },
    },
  );

  const handleRegenerateInvoice = () => {
    regenerateInvoice({ variables: { where: { id: orderDetails?.id } } });
  };

  const [transactionPayoutCall, { loading: payOutLoading }] = useMutation(
    TRANSACTION_PAYOUT,
    {
      fetchPolicy: 'network-only',
      onError() {},
      onCompleted: () => {
        setShowAmount({ pendingAmount: null, TotalAmount: null });
        listTransactionCall();
      },
    },
  );

  const [generateReport, { loading: preparingToExport }] = useLazyQuery(
    EXPORT_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  const [consultantAmount] = useLazyQuery(GET_CONSULTANT_AMOUNT, {
    fetchPolicy: 'network-only',
    onError() {},
    onCompleted: (res) => {
      const TotalAmount = res?.consultantAmount?.totalAmount
        ? `Rs.${res?.consultantAmount?.totalAmount?.toFixed(2)}`
        : 0;
      const pendingAmount = res?.consultantAmount?.pendingAmount
        ? `Rs.${res?.consultantAmount?.pendingAmount?.toFixed(2)}`
        : 0;
      setShowAmount({ TotalAmount, pendingAmount });
    },
  });

  useEffect(() => {
    listTransactionCall();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  useEffect(() => {
    if (
      getQueryParams?.[COMMON_QUERY_PARAMS.START] &&
      getQueryParams?.[COMMON_QUERY_PARAMS.END] &&
      getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID]
    ) {
      consultantAmount({
        variables: {
          filter: {
            startDate: getQueryParams?.[COMMON_QUERY_PARAMS.START],
            endDate: getQueryParams?.[COMMON_QUERY_PARAMS.END],
            where: {
              id: getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID],
            },
          },
        },
      });
    } else {
      setShowAmount({ pendingAmount: null, TotalAmount: null });
    }
  }, [location.search]);

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.STATUS]: filters?.status ?? null,
        [COMMON_QUERY_PARAMS.TYPE]: filters?.type ?? null,
        [COMMON_QUERY_PARAMS.PAYMENT_MODE]: filters?.paymentMode,
        [COMMON_QUERY_PARAMS.DISTRIBUTION_STATUS]: filters?.distributionStatus,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = debounce((value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  }, 2000);

  const handleDatePicker = (date) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.START]: date && dayjs(date?.[0])?.startOf('day'),
      [COMMON_QUERY_PARAMS.END]: date && dayjs(date?.[1])?.endOf('day'),
      [COMMON_QUERY_PARAMS.PAGE]: null,
    });
  };

  const getStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TRANSACTION_STATUS_SELECTION?.map((option) => (
          <div key={option?.value}>
            <Radio
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            disabled={!selectedKeys.length > 0}
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getTypeFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {TRANSACTION_LOGS_FILTER?.map((option) => (
          <div key={option?.value}>
            <Radio
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            disabled={!selectedKeys.length > 0}
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getTypePaymentModeFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {PAYMENT_MODE_FILTER_OPTIONS?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const getDistributionFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {DISTRIBUTION_STATUS_FILTER_OPTIONS?.map((option) => (
          <div key={option?.value} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e.target.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const handleUser = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.USER_ID]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const getTransactionType = (type) => {
    if (type === TRANSACTION_TYPE.TEMPLATE) {
      return 'template';
    }
    if (type === TRANSACTION_TYPE.SUBSCRIPTION) {
      return 'subscriptionPlan';
    }
    if (type === TRANSACTION_TYPE.CALL) {
      return 'consultationCall';
    }
  };

  const handleExportLogs = () => {
    generateReport({
      variables: {
        data: {
          startTime: getQueryParams?.[COMMON_QUERY_PARAMS.START],
          endTime: getQueryParams?.[COMMON_QUERY_PARAMS.END],
        },
        where: {
          consultantId: getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID],
        },
      },
    });
  };

  const columns = [
    {
      title: UI_LABELS.TRANSACTION_DATE,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: { multiple: 1 },
      sortDirections: ['ascend', 'descend', 'ascend'],
      sortOrder: getSorter.sorters.createdAt,
      width: 150,
      render: (value) => (
        <span>{value ? dayjs(value)?.format(dateTimeFormat) : '-'}</span>
      ),
    },
    {
      title: UI_LABELS.TYPE,
      dataIndex: 'type',
      key: 'type',
      width: 100,
      render: (value) => <span>{capitalize(value)}</span>,
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.TYPE] ?? null,
      ...getTypeFilterProps(),
    },
    {
      title: UI_LABELS.PURCHASER,
      key: 'user',
      width: 150,
      render: (value, record) => (
        <span>
          {record?.user
            ? `${record?.user?.firstName} ${record?.user?.lastName} (${
                USER_TYPE_LABEL[record?.user?.roles?.[0]] ?? '-'
              })`
            : `${record?.billing?.firstName} ${record?.billing?.lastName}`}
        </span>
      ),
    },
    {
      title: UI_LABELS.PURCHASER_PHONE_NUMBER,
      key: 'user',
      width: 100,
      render: (value, record) => (
        <span>
          {record?.user ? record?.user?.phoneNumber : record?.billing?.phone}
        </span>
      ),
    },
    {
      title: `${UI_LABELS.PROVIDER} (Role)`,
      key: 'user',
      width: 130,
      render: (value, record) => (
        <span>
          {record?.provider
            ? `${record?.provider?.firstName} ${record?.provider?.lastName} (${
                USER_TYPE_LABEL[record?.provider?.roles?.[0]]
              })`
            : '-'}
        </span>
      ),
    },
    {
      title: UI_LABELS.ITEM_DETAILS,
      dataIndex: 'details',
      width: 150,
      render: (value, record) => {
        const planData = record?.[getTransactionType(record?.type)];
        if (record?.type === TRANSACTION_TYPE.SUBSCRIPTION) {
          return noticeBoardPlanTitle(planData);
        }
        return <span>{record?.[getTransactionType(record?.type)]?.title}</span>;
      },
    },
    {
      title: UI_LABELS.SETTLED_DATE,
      dataIndex: 'settledDate',
      align: 'center',
      width: 150,
      render: (value) => (
        <span>{value ? dayjs(value)?.format(dateTimeFormat) : '-'}</span>
      ),
    },
    {
      title: `${UI_LABELS.PRICE} (+Taxes)`,
      dataIndex: 'amount',
      width: 100,
      render: (price) => `₹ ${price.toFixed(2)}`,
    },
    {
      title: UI_LABELS.PAID,
      width: 90,
      key: 'distributionStatus',
      filteredValue:
        getQueryParams?.[COMMON_QUERY_PARAMS.DISTRIBUTION_STATUS] ?? null,
      render: (item) => (
        <>
          {item.ledgers?.length ? (
            <>
              {item?.paidOutAt ? (
                <Badge
                  count={UI_LABELS.PAID}
                  color={TEMPLATE_STATUS_COLOR.PUBLISHED}
                />
              ) : (
                <Badge
                  count={UI_LABELS.UNPAID}
                  color={TEMPLATE_STATUS_COLOR.DRAFT}
                />
              )}
            </>
          ) : (
            '-'
          )}
        </>
      ),
      ...getDistributionFilterProps(),
    },
    {
      title: UI_LABELS.PAYMENT_MODE,
      dataIndex: 'paymentMode',
      align: 'center',
      width: 100,
      key: 'paymentMode',
      render: (mode) => (
        <Tag color={mode === 'OFFLINE' ? 'orange' : 'green'}>
          {capitalize(mode)}
        </Tag>
      ),
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.PAYMENT_MODE] ?? null,
      ...getTypePaymentModeFilterProps(),
    },
    {
      title: UI_LABELS.STATUS,
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <Tag color={TRANSACTION_STATUS_COLOR?.[status]}>
          {TRANSACTION_STATUS_LABELS?.[status]}
        </Tag>
      ),
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.STATUS] ?? null,
      ...getStatusFilterProps(),
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 120,
      render: (record) => (
        <>
          <Button
            type="link"
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `${ROUTES.EDIT_TRANSACTION}/${record?.id}/${record?.user?.id}`,
              );
            }}
          >
            {UI_LABELS.EDIT}
          </Button>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={(e) => {
              e?.stopPropagation();
              setShowModal(true);
              setOrderDetails(record);
            }}
          >
            {UI_LABELS.VIEW}
          </Button>
        </>
      ),
    },
  ];

  const handleBookedFrom = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.BOOKED_FROM]: value,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const handleMarkAsPaid = async () => {
    const popup = await modal.confirm({
      title: 'Are you sure you want to make Transactions as Paid?',
      content: '',
    });
    if (popup) {
      transactionPayoutCall({
        variables: {
          filter: {
            where: {
              id: getQueryParams[COMMON_QUERY_PARAMS.USER_ID],
            },
            startDate: getQueryParams?.[COMMON_QUERY_PARAMS.START],
            endDate: getQueryParams?.[COMMON_QUERY_PARAMS.END],
          },
        },
      });
    }
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.TRANSACTION_LOGS}
          {transactionData?.transactionLogsAdmin?.count
            ? ` (${transactionData.transactionLogsAdmin.count})`
            : ''}
        </Title>
        <div className="d-flex gap-12 filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <div className="filter-input transaction-filters">
            <Button
              icon={<PlusCircleOutlined />}
              className="ml-8"
              type="primary"
              onClick={() => {
                navigate(ROUTES?.ADD_TRANSACTION);
              }}
            >
              {UI_LABELS.ADD_TRANSACTION}
            </Button>
            <Tooltip
              title={
                // eslint-disable-next-line no-nested-ternary
                !getQueryParams?.[COMMON_QUERY_PARAMS.START]
                  ? 'Please select Start Date and End Date'
                  : // eslint-disable-next-line no-nested-ternary
                  !getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID]
                  ? 'Please select Provider'
                  : getQueryParams?.[COMMON_QUERY_PARAMS.BOOKED_FROM] ===
                    BOOKED_FROM_SELECTION.MICROSITE.value
                  ? 'Report Generation on Microsite Not Available'
                  : ''
              }
            >
              <Button
                icon={<ExportOutlined />}
                className="ml-8"
                type="primary"
                onClick={handleExportLogs}
                loading={preparingToExport}
                disabled={
                  (!getQueryParams?.[COMMON_QUERY_PARAMS.START] &&
                    !getQueryParams?.[COMMON_QUERY_PARAMS.END]) ||
                  !getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID] ||
                  getQueryParams?.[COMMON_QUERY_PARAMS.BOOKED_FROM] ===
                    BOOKED_FROM_SELECTION.MICROSITE.value
                }
              >
                {UI_LABELS.GENERATE_REPORT}
              </Button>
            </Tooltip>
            {showAmount?.TotalAmount ? (
              <Tooltip title="Total amount of filtered transactions">
                <Button loading={payOutLoading} disabled className="ml-8">
                  {showAmount?.TotalAmount}
                </Button>
              </Tooltip>
            ) : (
              ''
            )}
            {showAmount?.pendingAmount ? (
              <Tooltip title="Pending amount of filtered transactions">
                <Button
                  loading={payOutLoading}
                  disabled={payOutLoading}
                  onClick={handleMarkAsPaid}
                  className="ml-8"
                >
                  {showAmount?.pendingAmount}
                </Button>
              </Tooltip>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <div className="d-flex mb-8 gap-8 flex-wrap justify-end">
        <RangePicker
          allowClear
          onChange={handleDatePicker}
          value={[
            getQueryParams?.[COMMON_QUERY_PARAMS.START]
              ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.START])
              : '',
            getQueryParams?.[COMMON_QUERY_PARAMS.END]
              ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.END])
              : '',
          ]}
          placeholder={['Start Date', 'End Date']}
        />
        <div>
          <CommonSelect
            value={getQueryParams?.[COMMON_QUERY_PARAMS.USER_ID]}
            defaultValue={getQueryParams?.[COMMON_QUERY_PARAMS.SELECTED_ID]}
            placeholder="Select Provider"
            className="full-width provider-select-filter"
            showSearch
            allowClear
            onChange={handleUser}
            query={LIST_USER_QUERY}
            fetchPolicy="network-only"
            responsePath="listUsers.user"
            valuePath="id"
            labelPath="firstName"
            optionalLabelPath="lastName"
            variables={{
              filter: {
                roles: [
                  ROLE_KEYS.SUPER_ADMIN,
                  ROLE_KEYS.ADMIN,
                  ROLE_KEYS.STAFF,
                  ROLE_KEYS.PREMIUM_CONSULTANT,
                ],
              },
              sort: [
                {
                  sortOn: 'createdAt',
                  sortBy: SORT_OPTION.DESC,
                },
              ],
            }}
            provider={
              defaultUserOption
                ? {
                    id: defaultUserOption.value,
                    firstName: defaultUserOption.label,
                  }
                : null
            }
          />
        </div>
        <Select
          placeholder="Transactions From"
          onChange={handleBookedFrom}
          defaultValue="WEB"
          className="role-select-in-calls ml-8"
          options={map(BOOKED_FROM_SELECTION)}
          value={getQueryParams?.[COMMON_QUERY_PARAMS.BOOKED_FROM] ?? 'WEB'}
        />
      </div>
      {contextHolder}
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={transactionLogsData}
        loading={loading}
        onChange={handleTableChange}
        scroll={{ x: 1600, y: `calc(100vh - 365px)` }}
        onRow={(record) => ({
          onClick: () => {
            navigate(
              `${ROUTES.EDIT_TRANSACTION}/${record?.id}/${record?.user?.id}`,
            );
          },
        })}
        pagination={{
          total: transactionData?.transactionLogsAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        expandable={{
          expandedRowRender: (record) => (
            <Table
              size="small"
              columns={[
                {
                  title: UI_LABELS.TITLE,
                  dataIndex: 'label',
                  render: (label) => capitalize(label),
                },
                {
                  title: `${UI_LABELS.AMOUNT} (Base Price)`,
                  dataIndex: 'amount',
                  align: 'right',
                  render: (price) => `₹ ${price?.toFixed(2)}`,
                },
                {
                  title: UI_LABELS.PERCENTAGE,
                  dataIndex: 'percentage',
                  align: 'center',
                  render: (percentage) => `${percentage}%`,
                },
              ]}
              rowKey={record?.id}
              dataSource={record?.ledgers}
              pagination={false}
            />
          ),
          expandRowByClick: true,
          rowExpandable: (record) => record.ledgers?.length > 0,
        }}
      />
      <TransactionDetails
        setShowModal={setShowModal}
        showModal={showModal}
        orderDetails={orderDetails}
        getTransactionType={getTransactionType}
        handleRegenerateInvoice={handleRegenerateInvoice}
        loading={invoiceLoading}
      />
    </>
  );
}

export default TransactionLogsList;
