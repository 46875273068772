import { DownloadOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { DatePicker, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  REPORT_STATUS_COLOR,
  SORT_OPTION,
  transactionDate,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import useUrlQuery from '../../hooks/useUrlQuery';
import { ADMIN_REPORTS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

function Reports() {
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      startDate: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS?.START]
          ? getQueryParams[COMMON_QUERY_PARAMS?.START]
          : null,
      },
      endDate: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS?.END]
          ? getQueryParams[COMMON_QUERY_PARAMS?.END]
          : null,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(
    {},
    {
      sortOn: 'createdAt',
      sortBy: SORT_OPTION.DESC,
    },
  );

  const [fetchReports, { data: reports, loading }] = useLazyQuery(
    ADMIN_REPORTS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    fetchReports({
      variables: prepareVariable,
    });
  }, [location.search]);

  const handleTableChange = (pagination) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
    });
  };

  const handleDatePicker = (date) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.START]: date && dayjs(date?.[0])?.startOf('day'),
      [COMMON_QUERY_PARAMS.END]: date && dayjs(date?.[1])?.endOf('day'),
    });
  };

  const columns = [
    {
      title: UI_LABELS.JOB_FILTERS,
      dataIndex: 'filter',
      align: 'center',
      render: (_, record) => (
        <>
          <div>
            {dayjs(record?.filter?.data?.startTime).format(transactionDate)} -{' '}
            {dayjs(record?.filter?.data?.endTime).format(transactionDate)}
          </div>
          <span>
            <strong>
              {record?.filter?.consultant?.firstName}{' '}
              {record?.filter?.consultant?.lastName}
            </strong>
          </span>
        </>
      ),
    },
    {
      title: UI_LABELS.STATUS,
      dataIndex: 'status',
      align: 'center',
      render: (status) => (
        <Tag color={REPORT_STATUS_COLOR?.[status]}>{capitalize(status)}</Tag>
      ),
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      align: 'center',
      render: (record) =>
        record?.status !== 'FAILED' ? (
          <a href={record?.fileKey} aria-label="Download file">
            <DownloadOutlined />
          </a>
        ) : null,
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.REPORTS}
          {reports?.adminReports?.count
            ? ` (${reports?.adminReports?.count})`
            : ''}
        </Title>
        <div className="d-flex gap-12 filter-input mb-12">
          <div className="d-flex gap-12">
            <RangePicker
              onChange={handleDatePicker}
              value={[
                // eslint-disable-next-line no-nested-ternary
                getQueryParams?.[COMMON_QUERY_PARAMS.START]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.START])
                  : null,
                // eslint-disable-next-line no-nested-ternary
                getQueryParams?.[COMMON_QUERY_PARAMS.END]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.END])
                  : null,
              ]}
              placeholder={['Start Date', 'End Date']}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={reports?.adminReports?.data}
        onChange={handleTableChange}
        scroll={{ y: `calc(100vh - 365px)` }}
        loading={loading}
        pagination={{
          total: reports?.adminReports?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
    </>
  );
}

export default Reports;
