import { gql } from '@apollo/client';

const FETCH_TEMPLATE = gql`
  query TemplateAdmin($where: TemplateWhereUniqueInput!) {
    templateAdmin(where: $where) {
      description
      price
      status
      title
      type
      slug
      aiCredit
      updatedAt
      createdAt
      creator {
        id
        firstName
        lastName
        phoneNumber
        email
        roles
      }
      showOnWeb {
        JUDGEMENT {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        HOME {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
        BLOG {
          INDIRECT_TAX {
            visible
          }
          DIRECT_TAX {
            visible
          }
          COMPANY_LAW {
            visible
          }
        }
      }
      category {
        id
        name
        slug
      }
      subCategory {
        id
        name
        slug
      }
      download {
        url
        title
        info
        metaData
      }
      preview {
        url
        title
        info
        contentType
        metaData
      }
    }
  }
`;

const FETCH_TEMPLATE_LIST = gql`
  query TemplatesAdmin(
    $filter: ListTemplateFilterAdmin
    $sort: [ListTemplateSort!]
  ) {
    templatesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        title
        price
        status
        type
        category {
          id
          key
          name
        }
        creator {
          lastName
          firstName
          roles
        }
        preview {
          url
        }
        updatedAt
        createdAt
      }
    }
  }
`;

const GET_TEMPLATE_IMAGE_SIGNED_URL = gql`
  query GetTemplatePreviewSignedPutUrl($data: GetTemplateSignedPutUrlInput!) {
    getTemplatePreviewSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

const GET_TEMPLATE_DOC_SIGNED_URL = gql`
  query GetTemplateDocSignedPutUrl($data: GetTemplateSignedPutUrlInput!) {
    getTemplateDocSignedPutUrl(data: $data) {
      fileName
      signedUrl
    }
  }
`;

const GET_TEMPLATE_DOC_DOWNLOAD_URL = gql`
  query DownloadTemplate($where: TemplateWhereUniqueInput!) {
    downloadTemplate(where: $where) {
      url
    }
  }
`;
export {
  FETCH_TEMPLATE,
  FETCH_TEMPLATE_LIST,
  GET_TEMPLATE_DOC_DOWNLOAD_URL,
  GET_TEMPLATE_DOC_SIGNED_URL,
  GET_TEMPLATE_IMAGE_SIGNED_URL,
};
