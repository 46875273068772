import { useMutation } from '@apollo/client';
import { Form, Input, Modal, Select } from 'antd';
import { map } from 'lodash';
import React, { useState } from 'react';
import {
  CONFIG_KEY,
  CONSULTATION_CALL_TYPE,
  PAYMENT_TYPE,
} from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import { UPDATE_CONFIG } from '../graphql/mutations';

function CreditConfigModal({
  open = false,
  setOpen,
  selectedConfig = null,
  getConfig,
}) {
  const [form] = Form.useForm();
  const [inputValue, setInputValue] = useState(undefined);
  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    onCompleted: () => {
      setOpen(false);
      getConfig();
    },
    onError: () => {},
  });
  const handleSubmit = (values) => {
    updateConfig({
      variables: {
        data: {
          value: String(values?.value),
        },
        where: {
          id: selectedConfig?.id,
        },
      },
    });
  };

  const initialValues = {
    label: selectedConfig?.key,
    value: selectedConfig?.value,
  };

  const showSelect = ['CONSULTATION_CALL_TYPE', 'TYPE'].includes(
    selectedConfig?.key,
  );

  const paymentTypeOptions = selectedConfig?.key === 'TYPE';

  const handleInputChange = (e) => {
    const { value } = e?.target;
    setInputValue(value);
  };

  const validateNumber = (_rule, value, callback) => {
    if (showSelect) {
      return callback();
    }
    if (value === undefined || value === '') {
      callback('Please Enter Value');
    } else if (Number(value) < 0) {
      callback('Value Should Be Zero or More Than Zero');
    } else {
      form.setFieldValue('value', Number(value ?? 0));
      callback();
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => setOpen(false)}
      onOk={form.submit}
      title={UI_LABELS.EDIT}
      width={350}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        initialValues={initialValues}
      >
        <Form.Item
          label={CONFIG_KEY?.[initialValues?.label]}
          name="value"
          rules={[{ validator: validateNumber }]}
          className="full-width"
        >
          {showSelect ? (
            <Select placeholder="Select Value">
              {paymentTypeOptions ? (
                <>
                  {map(PAYMENT_TYPE, (item) => (
                    <Select.Option value={item.key}>{item.label}</Select.Option>
                  ))}
                </>
              ) : (
                <>
                  {map(CONSULTATION_CALL_TYPE, (item) => (
                    <Select.Option value={item.key}>{item.label}</Select.Option>
                  ))}
                </>
              )}
            </Select>
          ) : (
            <Input
              type="number"
              className="full-width"
              value={inputValue}
              onChange={handleInputChange}
            />
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreditConfigModal;
