import { gql } from '@apollo/client';

export const IMPORT_CSV = gql`
  mutation ImportCsv($data: CreateImportInput!) {
    importCsv(data: $data) {
      message
    }
  }
`;

export const UPDATE_CSV_STATUS = gql`
  mutation UpdateImportStatus(
    $data: updateImportStatus!
    $where: BatchIdWhereUniqueInput!
  ) {
    updateImportStatus(data: $data, where: $where) {
      message
    }
  }
`;

export const DELETE_BATCH = gql`
  mutation DeleteImportBatch($where: BatchIdWhereUniqueInput!) {
    deleteImportBatch(where: $where) {
      message
    }
  }
`;

export const RETRY_BATCH_PROCESS = gql`
  mutation RetryFailedJudgements($where: RetryFailedAppealsWhereInput!) {
    retryFailedJudgements(where: $where) {
      message
    }
  }
`;

export const IMPORT_JUDGEMENT = gql`
  mutation ImportJudgement($data: [importJudgementData!]!) {
    importJudgement(data: $data) {
      message
    }
  }
`;
