import {
  ExclamationCircleFilled,
  HolderOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Form, Input, Modal, Space, Table, Typography } from 'antd';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  LIMIT,
  MAX_ORDER_LIMIT,
  PAGE_SIZE_OPTIONS,
  REGEX,
  SORT_OPTION,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  formValidatorRules,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import './category.less';
import CategoryList from './component/CategoryList';
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  UPDATE_CATEGORY_AND_SUB_CATEGORY,
  UPDATE_CATEGORY_ORDER,
} from './graphql/mutations';
import { GET_CATEGORIES } from './graphql/queries';

const { Title } = Typography;
const { name: validateName } = formValidatorRules;

const fieldToParams = {
  order: COMMON_QUERY_PARAMS.SORT_ORDER,
  name: COMMON_QUERY_PARAMS.SORT_NAME,
};

const RowContext = createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: 'move',
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    // eslint-disable-next-line react/destructuring-assignment
    id: props['data-row-key'],
  });
  const style = {
    // eslint-disable-next-line react/destructuring-assignment
    ...props.style,
    transform: CSS?.Translate?.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const initialCategoryValue = {
  isShow: false,
  id: null,
  name: '',
  order: null,
};

function Category() {
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();

  const [buttonLoader, setButtonLoader] = useState(false);
  const [loader, setLoader] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [count, setCount] = useState(0);
  const [addCategoryFormPopup, setAddCategoryFormPopup] = useState(false);
  const [isShowDeletePopup, setIsShowDeletePopup] = useState(null);
  const [editCategoryFormPopup, setEditCategoryFormPopup] = useState(
    initialCategoryValue,
  );
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? (Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE]) - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortBy: SORT_OPTION.DESC,
    sortOn: COMMON_QUERY_PARAMS.SORT_ORDER,
  });

  const [fetchCategories] = useLazyQuery(GET_CATEGORIES);

  const [updateOrder] = useMutation(UPDATE_CATEGORY_ORDER, { onError() {} });

  const [addCategoryFormInstance] = Form.useForm();
  const [categoryNameEditFormInstance] = Form.useForm();

  useEffect(() => {
    fetchCategoriesCall();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  function fetchCategoriesCall() {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoader(true);
    fetchCategories({
      variables: prepareVariable,
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        setCount(res?.templateCategoriesAdmin?.count ?? 0);
        const listData = res?.templateCategoriesAdmin?.data;
        setCategoryList(listData);
        setLoader(false);
      },
      onError: () => {
        setLoader(false);
      },
    });
  }

  const [createCategoryCall] = useMutation(CREATE_CATEGORY, {
    onCompleted: () => {
      setQueryParams(null);
      addCategoryFormInstance.setFieldsValue({
        categoryName: '',
        subCategory: [{ subCategory: '' }],
      });
      setAddCategoryFormPopup(false);
      setButtonLoader(false);
    },
    onError: () => {
      setButtonLoader(false);
    },
  });
  const handleSaveNewCategoryForm = async (formData) => {
    setButtonLoader(true);
    await createCategoryCall({
      variables: {
        data: {
          name: formData?.categoryName,
          subCategories: formData?.subCategory.map((item) => item?.subCategory),
        },
      },
    });
  };

  const [updateCategoryCall] = useMutation(UPDATE_CATEGORY_AND_SUB_CATEGORY, {
    onCompleted: () => {
      setEditCategoryFormPopup(initialCategoryValue);
      setButtonLoader(false);
    },
    onError: () => {
      setButtonLoader(false);
    },
  });

  const [deleteCategoryCall] = useMutation(DELETE_CATEGORY, {
    onCompleted: () => {
      setButtonLoader(false);
      setIsShowDeletePopup(null);
    },
    onError: () => {
      setIsShowDeletePopup(null);
      setButtonLoader(false);
    },
  });

  const handleDelete = () => {
    setButtonLoader(true);
    deleteCategoryCall({
      variables: {
        where: {
          ids: [isShowDeletePopup],
        },
      },
    });
  };

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const handleEdit = async (item) => {
    setEditCategoryFormPopup({
      isShow: true,
      name: item?.name,
      id: item?.id,
      order: item?.order,
    });
    categoryNameEditFormInstance.setFieldsValue({
      categoryName: item?.name,
      order: item?.order,
    });
  };

  const handleEditCategoryNameForm = async (formValue) => {
    setButtonLoader(true);
    if (categoryNameEditFormInstance?.isFieldTouched('order')) {
      await updateOrder({
        variables: {
          data: {
            order: Number(formValue?.order),
          },
          where: { id: editCategoryFormPopup?.id },
        },
      });
    }
    await updateCategoryCall({
      variables: {
        data: {
          name: formValue.categoryName,
        },
        where: { id: editCategoryFormPopup.id },
      },
    });
  };

  const handleAddCategoryForm = (type, id = null) => {
    if (type === 'CATEGORY') {
      setAddCategoryFormPopup(true);
    } else {
      setEditCategoryFormPopup({
        ...editCategoryFormPopup,
        id,
        name: '',
        order: null,
        isShow: true,
      });
      categoryNameEditFormInstance?.setFieldsValue({
        categoryName: '',
      });
    }
  };

  const handleTableChange = (pagination, _filters, sorter) => {
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    const pageSizeValue = pagination?.pageSize || LIMIT;
    const prepareSorter = {};
    Object?.keys(fieldToParams)?.forEach((item) => {
      sorterAry?.forEach((filterItem) => {
        if (filterItem?.columnKey && item === filterItem?.columnKey) {
          // eslint-disable-next-line no-nested-ternary
          prepareSorter[
            fieldToParams[filterItem?.columnKey]
            // eslint-disable-next-line no-nested-ternary
          ] = filterItem?.order
            ? filterItem?.order === 'descend'
              ? SORT_OPTION.DESC
              : SORT_OPTION.ASC
            : null;
        }
      });
      prepareSorter[fieldToParams?.[item]] =
        prepareSorter[fieldToParams?.[item]] ?? null;
    });
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
      ...prepareSorter,
    });
  };

  const handleClose = () => {
    addCategoryFormInstance.resetFields();
    setAddCategoryFormPopup(false);
  };

  const handleUpdateOrder = (order, id) => {
    if (order && id) {
      updateOrder({
        variables: { data: { order }, where: { id } },
        onCompleted: () => {
          fetchCategoriesCall();
        },
      });
    }
  };

  const onDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      const oldIndex = categoryList?.findIndex(
        (item) => item?.order === active?.id,
      );
      const newIndex = categoryList?.findIndex(
        (item) => item?.order === over?.id,
      );
      const prepareData = arrayMove(categoryList, oldIndex, newIndex);
      setCategoryList(prepareData);
      handleUpdateOrder(over?.id, categoryList?.[oldIndex]?.id);
    }
  };

  const orderValidator = (_rule, value, callback) => {
    if (value && Number(value) === 0) {
      callback('Order value cannot be 0');
    } else if (value && Number(value) > MAX_ORDER_LIMIT) {
      callback('Order value cannot be more than 10000');
    } else {
      callback();
    }
  };
  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.TEMPLATE_CATEGORIES}
          {count ? ` (${count})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            placeholder="Search Category"
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <Button
            className="ml-8"
            key="1"
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              handleAddCategoryForm('CATEGORY');
            }}
          >
            {UI_LABELS.ADD_CATEGORY}
          </Button>
        </div>
      </div>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          items={categoryList?.map((i) => i?.order)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            loading={loader}
            rowKey="order"
            columns={[
              {
                key: 'sort',
                align: 'center',
                width: 80,
                render: () => <DragHandle />,
              },
              {
                title: UI_LABELS.CATEGORIES,
                dataIndex: 'name',
                key: 'name',
                width: '75%',
                sorter: { multiple: 1 },
                sortOrder: getSorter.sorters.name,
              },
              {
                title: UI_LABELS.ACTION,
                dataIndex: '',
                key: 'action',
                render: (item) => (
                  <div className="category-action">
                    <Button
                      type="link"
                      className="action-btn"
                      onClick={() => handleEdit(item)}
                    >
                      {UI_LABELS.EDIT}
                    </Button>
                    <Button
                      type="link"
                      className="action-btn"
                      onClick={() => setIsShowDeletePopup(item?.id)}
                      danger
                    >
                      {UI_LABELS.DELETE}
                    </Button>
                  </div>
                ),
              },
            ]}
            expandable={{
              expandedRowRender: (record, _index, _indent, isExpanded) => (
                <div>
                  {isExpanded && (
                    <>
                      <CategoryList
                        fetchMainCategory={() => fetchCategoriesCall()}
                        key={record?.id}
                        title={UI_LABELS.SUB_CATEGORIES}
                        categoryId={record?.id}
                      />
                    </>
                  )}
                </div>
              ),
            }}
            components={{
              body: {
                row: Row,
              },
            }}
            dataSource={categoryList}
            scroll={{ y: `calc(100vh - 365px)` }}
            pagination={{
              current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
              pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
              total: count,
              pageSizeOptions: PAGE_SIZE_OPTIONS,
            }}
            onChange={handleTableChange}
          />
        </SortableContext>
      </DndContext>
      <Modal
        destroyOnClose
        title={UI_LABELS.ADD_CATEGORY}
        open={addCategoryFormPopup}
        onCancel={handleClose}
        footer={[
          <Button key="submit" onClick={handleClose}>
            {UI_LABELS.CANCEL}
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => addCategoryFormInstance?.submit()}
          >
            {UI_LABELS.SAVE}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={addCategoryFormInstance}
            onFinish={handleSaveNewCategoryForm}
            layout="vertical"
          >
            <Form.Item
              name="categoryName"
              label="Category"
              rules={[
                { required: true, message: 'Please enter Category' },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
                {
                  pattern: REGEX?.NAME,
                  message: 'Enter a valid Category',
                },
              ]}
            >
              <Input placeholder="Enter Category" />
            </Form.Item>
            <Form.List
              name="subCategory"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error('Please provide at least one Sub Category.'),
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Space
                      className="dynamic-cat-input"
                      key={key}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, 'subCategory']}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter Sub Category',
                          },
                          {
                            whitespace: true,
                            message: 'Invalid input',
                          },
                          {
                            pattern: REGEX?.NAME,
                            message: 'Enter a valid Sub Category',
                          },
                        ]}
                      >
                        <Input
                          className="input-cat"
                          placeholder="Enter Sub Category"
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {UI_LABELS.ADD_SUB_CATEGORY}
                    </Button>
                  </Form.Item>
                  <Form.ErrorList className="category-error" errors={errors} />
                </>
              )}
            </Form.List>
          </Form>
        </div>
      </Modal>
      <Modal
        destroyOnClose
        title={UI_LABELS.EDIT_CATEGORY}
        open={editCategoryFormPopup.isShow}
        onCancel={() => setEditCategoryFormPopup(initialCategoryValue)}
        footer={[
          <Button
            key="submit"
            onClick={() => setEditCategoryFormPopup(initialCategoryValue)}
          >
            {UI_LABELS.CANCEL}
          </Button>,
          <Button
            key="back"
            type="primary"
            loading={buttonLoader}
            onClick={() => categoryNameEditFormInstance?.submit()}
          >
            {UI_LABELS.SAVE}
          </Button>,
        ]}
      >
        <div>
          <Form
            form={categoryNameEditFormInstance}
            onFinish={handleEditCategoryNameForm}
            layout="vertical"
          >
            <Form.Item
              name="categoryName"
              label="Category"
              rules={[
                {
                  required: true,
                  message: 'Please enter Category',
                },
                {
                  whitespace: true,
                  message: 'Invalid input',
                },
                validateName,
              ]}
            >
              <Input placeholder="Enter Category" />
            </Form.Item>
            <Form.Item
              name="order"
              label="Order"
              rules={[
                {
                  required: true,
                  message: 'Please enter Order',
                },
                { validator: orderValidator },
              ]}
            >
              <Input type="number" placeholder="Enter Order" />
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        open={Boolean(isShowDeletePopup)}
        onOk={() => handleDelete()}
        onCancel={() => setIsShowDeletePopup(null)}
        okText={UI_LABELS.DELETE}
        okType="danger"
        cancelText="No"
      >
        <div className="delete-poppup">
          <ExclamationCircleFilled className="popup-icon" />
          <span>Are you sure you want to delete?</span>
        </div>
      </Modal>
    </>
  );
}

export default Category;
