const UI_LABELS = {
  /* Headings */
  DASHBOARD: 'Dashboard',
  TOTAL_USERS: 'Total Users',
  VERIFIED_USERS: 'Verified Users',
  INACTIVE_USERS: 'Inactive Users',
  ACTIVE_USERS: 'Active Users',
  UNVERIFIED_USERS: 'Unverified Users',
  USERS: 'Users',
  ADD_USER: 'Add User',
  EDIT_USER: 'Edit User',
  USER_DETAILS: 'User Details',
  TEMPLATES: 'Templates',
  ADD_TEMPLATE: 'Add Template',
  EDIT_TEMPLATE: 'Edit Template',
  TEMPLATE_STATUS: 'Template Status',
  TEMPLATE_CATEGORIES: 'Template Categories',
  ADD_CATEGORY: 'Add Category',
  EDIT_CATEGORY: 'Edit Category',
  SUB_CATEGORIES: 'Sub Categories',
  ADD_SUB_CATEGORY: 'Add Sub Category',
  JUDGEMENTS: 'Judgements',
  IMPORT_JUDGEMENT: 'Import Judgment',
  ADD_JUDGEMENT: 'Add Judgement',
  EDIT_JUDGEMENT: 'Edit Judgement',
  VIEW_EXPERT_SUMMARY: 'View Expert Summary',
  EDIT_EXPERT_SUMMARY: 'Edit Expert Summary',
  TEST_NEWSLETTER: 'Test Newsletter',
  KEYWORDS: 'Keywords',
  CONFIGURATION: 'Configuration',
  ADD_KEYWORD: 'Add Keyword',
  EDIT_KEYWORD: 'Edit Keyword',
  SECTIONS: 'Sections',
  ADD_SECTION: 'Add Section',
  EDIT_SECTION: 'Edit Section',
  JUDGES: 'Judges',
  ADD_JUDGE: 'Add Judge',
  EDIT_JUDGE: 'Edit Judge',
  ASSESSEES: 'Assessees',
  ADD_ASSESSEE: 'Add Assessee',
  EDIT_ASSESSEE: 'Edit Assessee',
  EDIT_ASSESSEE_DETAILS: 'Edit Assessee Details',
  ASSESSEE_DETAILS: 'Assessee Details',
  MERGE_OR_EDIT_JUDGES: 'Merge or Edit Judges',
  ADD_NEW_SKILL: 'Add New Skill',
  ADD_SKILL: 'Add Skill',
  LAWYERS: 'Lawyers',
  ADD_LAWYER: 'Add Lawyer',
  EDIT_LAWYER: 'Edit Lawyer',
  MERGE_OR_EDIT_COUNSELS: 'Merge or Edit Counsels',
  ACTS: 'Acts',
  ADD_ACT: 'Add Act',
  EDIT_ACT: 'Edit Act',
  NOTIFICATIONS_CIRCULARS: 'Notifications/circulars',
  ADD_NOTIFICATION: 'Add Notifications/circulars',
  EDIT_NOTIFICATION: 'Edit Notifications/circulars',
  SUBMISSIONS: 'Submissions/arguments',
  ADD_SUBMISSION: 'Add Submissions/arguments',
  EDIT_SUBMISSION: 'Edit Submissions/arguments',
  CONSULTATION_CALLS: 'Consultation Calls',
  CONSULTATION_CALL: 'Consultation Call',
  BOOKING_DETAILS: 'Booking Details',
  RECORDED_VIDEO: 'Recorded Video',
  QUOTATIONS: 'Quotations',
  QUOTATION_DETAILS: 'Quotation Details',
  PAYMENT_DISTRIBUTION: 'Payment Distribution',
  TRANSACTION_LOGS: 'Transaction Logs',
  TRANSACTION_LOG: 'Transaction Log',
  ORDER_DETAILS: 'Order Details',
  REPORTS: 'Reports',
  REVIEW_APPEALS: 'Review Appeals',
  APPEAL_LIST: 'Appeal List',
  APPEAL_DETAILS: 'Appeal Details',
  IMPORT_CSV: 'Import CSV',
  FORGOT_YOUR_PASSWORD: 'Forgot Your Password?',
  SET_PASSWORD: 'Set Password',
  SIGN_UP: 'Sign Up',
  DIRECT_TAX: 'Direct Tax',
  INDIRECT_TAX: 'Indirect Tax',
  CORPORATE_LAW: 'Corporate Law',
  JUDGEMENT_AI: 'Reference Docs',
  PAYMENTS: 'Payments',

  /* Button Labels */
  APPLY: 'Apply',
  RESET: 'Reset',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  EDIT: 'Edit',
  PURCHASE_DETAILS: 'Purchase Details',
  DELETE: 'Delete',
  VIEW: 'View',
  REVIEW: 'Review',
  VIEW_TEMPLATE: 'View Template',
  SELECT_STATUS: 'Select Status',
  ADD_PREVIEW_IMAGE: 'Add Preview Image',
  UPLOAD_DOCUMENT: 'Upload Document',
  UPLOAD_PDF: 'Upload Pdf',
  PAGES_TO_SHOW: 'Pages to Show',
  YES: 'Yes',
  NO: 'No',
  ADD_REGEX: 'Add Regex',
  ADD_ITEM: 'Add Item',
  SAVE_ASSESSEE_DETAILS: 'Save Assessee Details',
  MERGE_DATA: 'Merge Data',
  ADD_SKILLS: 'Add Skills',
  ADD_SPACE_NAME: 'Add Space Name',
  VIEW_DETAILS: 'View Details',
  SUBSCRIPTION_PLANS: 'Subscription Plans',
  EDIT_SUBSCRIPTION_PLAN: 'Edit Subscription Plan',
  ADD_MORE_FEATURE: 'Add More Feature',
  ADD_TRANSACTION: 'Add Transaction',
  EDIT_TRANSACTION: 'Edit Transaction',
  GENERATE_REPORT: 'Generate Report',
  DOWNLOAD_INVOICE: 'Download Invoice',
  REGENERATE_INVOICE: 'Regenerate Invoice',
  CREATE_BOOKING: 'Create Booking',
  ADD_CSV: 'Add CSV',
  DOWNLOAD: 'Download',
  ERROR_LOG: 'Error Log',
  BATCH_STATUS: 'Batch Status',
  VALIDATE_CSV_IMPORT: 'Validate CSV & Import',
  ADD_BATCH: 'Add Batch',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  SET_NEW_PASSWORD: 'Set New Password',
  DONE: 'Done',
  READ_FULL_SUMMARY: 'Read Full Summary',

  /* Table Column Heading */
  NAME: 'Name',
  EMAIL: 'Email',
  PHONE_NUMBER: 'Phone Number',
  CITY: 'City',
  PROFESSION: 'Profession',
  ROLE: 'Role',
  CREATED_BY: 'Created By',
  CREATED_ON: 'Created On',
  TRANSACTION_DATE: 'Transaction Date',
  UPDATED_ON: 'Updated On',
  STATUS: 'Status',
  ACTION: 'Action',
  TITLE: 'Title',
  CALL_TITLE: 'Call Title',
  TEMPLATE_TYPE: 'Template Type',
  PRICE: 'Price',
  CATEGORIES: 'Categories',
  AI_FIELD: 'AI Field',
  VISIBILITY_STATUS: 'Visibility Status',
  APPEAL_NUMBER: 'Appeal Number',
  APPELLANT: 'Appellant',
  COURT: 'Court',
  JUDGEMENT_TEXT: 'Judgement Text',
  CREATED_AT: 'Created At',
  UPDATED_AT: 'Updated At',
  DESCRIPTION: 'Description',
  TYPE: 'Type',
  IS_VISIBLE: 'Is Visible',
  INITIAL_OF_SURNAME_OR_TITLE: 'Initial of Surname or Title',
  SLUG: 'Slug',
  REGEX: 'Regex',
  CONSIDER_THIS_LABEL_FOR_SUFIX_PREFIX_SECTION_MATCH:
    'Consider This Label for Sufix/prefix Section Match',
  REGION: 'Region',
  ISSUE_PERIOD: 'Issue Period',
  CATEGORY: 'Category',
  CREATOR: 'Creator',
  CREATOR_NAME_ROLE: 'Creator Name (Role)',
  NO_OF_BOOKINGS: 'No. of Bookings',
  MEETING_TYPE: 'Meeting Type',
  DURATION: 'Duration',
  FEATURED: 'Featured',
  PURCHASER_NAME: 'Purchaser Name',
  TIME_SLOT: 'Time Slot',
  VIEW_RECORDING: 'View Recording',
  TIME: 'Time',
  CONSULTANT: 'Consultant',
  SERVICE_NAME: 'Service Name',
  FEATURES: 'Features',
  VALUE: 'Value',
  CREDIT_VALUE: 'Credit Value',
  PURCHASER: 'Purchaser',
  PURCHASER_PHONE_NUMBER: 'Purchaser Phone Number',
  PROVIDER: 'Provider',
  DETAILS: 'Details',
  ITEM_DETAILS: 'Item Details',
  SETTLED_DATE: 'Settled Date',
  PAYMENT_MODE: 'Payment Mode',
  AMOUNT: 'Amount',
  PERCENTAGE: 'Percentage',
  ORDERED_BY: 'Ordered By',
  ITEM: 'Item',
  ORDER_PLACED_ON: 'Order Placed On',
  BILLING_DETAILS: 'Billing Details',
  JOB_FILTERS: 'Job Filters',
  PLAN: 'Plan',
  PURCHASE_DATE: 'Purchase Date',
  EXPIRY_DATE: 'Expiry Date',
  DOWNLOADS_USED: 'Downloads/Used',
  COUNT_CREDIT: 'Count/Credit',
  TEMPLATE_TITLE: 'Template Title',
  JUDGEMENT_STATUS: 'Judgement Status',
  BATCH_ID: 'Batch Id',
  FILE_SIZE: 'File Size',
  TOTAL_RECORDS: 'Total Records',
  DUPLICATE_COUNT: 'Duplicate Count',
  SKIP_JUDGEMENT_COUNT: 'Skip Judgement Count',
  AI_PROCESS_FAILED_COUNT: ' Ai Process Failed Count',
  EXTRACT_JUDGEMENT_TEXT_FAILED_COUNT: 'Extract Judgement Text Failed Count',
  PDF_PROCESS_FAILED_COUNT: 'Pdf Process Failed Count',
  PROCESSING_COUNT: 'Processing Count',
  COMPLETED_COUNT: 'Completed Count',
  QUOTATION_DATE: 'Quotation Date',
  TEMPLATE_UPDATED_ON: 'Template Updated On',
  TEMPLATE_CREATED_ON: 'Template Created On',
  /* Status Labels */
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  FREE: 'Free',
  PAID: 'Paid',
  UNPAID: 'Unpaid',
  PREMIUM: 'Premium',
  DRAFT: 'Draft',
  PUBLISHED: 'Published',
  UNPUBLISHED: 'Unpublished',
  REJECTED: 'Rejected',
  VISIBLE: 'Visible',
  NOT_VISIBLE: 'Not Visible',
  PUBLISH: 'Publish',
  TRUE: 'True',
  FALSE: 'False',

  /* User Type Labels */
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Admin',
  STAFF: 'Staff',
  PREMIUM_CONSULTANT: 'Premium Consultant',
  ADVISOR: 'Advisor',
  ADVISE_SEEKER: 'Advise Seeker',
};

export default UI_LABELS;
