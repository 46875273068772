import { CloseCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Result, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { AppContext } from './AppContext';
import Error404 from './Error404';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import App from './app/App';
import {
  ERROR_PAGE_SUBTITLE,
  ERROR_PAGE_TITLE,
  PAYMENT_TAB_KEYS,
  ROUTES,
  USER_TYPE,
} from './common/constants';
import LoaderComponent from './components/LoaderComponent';
import MaintenancePage from './components/MaintenancePage';
import history from './historyData';
import ActsWrapper from './modules/acts';
import AiConfigWrapper from './modules/ai-config';
import AssessesWrapper from './modules/assesses';
import ForgetPassword from './modules/auth/ForgetPassword';
import Login from './modules/auth/Login';
import Logout from './modules/auth/Logout';
import RefreshToken from './modules/auth/RefreshToken';
import SetPassword from './modules/auth/SetPassword';
import Signup from './modules/auth/Signup';
import VerifyEmail from './modules/auth/VerifyEmail';
import { GET_CURRENT_USER } from './modules/auth/graphql/queries';
import Category from './modules/category/Category';
import ConsultationCallWrapper from './modules/consultation-calls';
import Dashboard from './modules/dashboard/dashboard';
import ImportCSVWrapper from './modules/import-csv';
import JudgementWrapper from './modules/judgements';
import JudgesWrapper from './modules/judges';
import KeywordWrapper from './modules/keywords';
import LawyerWrapper from './modules/lawyers';
import NotificationsWrapper from './modules/notifications';
import PaymentDistributionWrapper from './modules/payment-distribution';
import PaymentList from './modules/payments/PaymentList';
import Profile from './modules/profile/Profile';
import QuotationWrapper from './modules/quotation';
import ReportsWrapper from './modules/reports';
import ReviewAppealsWrapper from './modules/review-appeals';
import SectionWrapper from './modules/sections';
import SubmissionsWrapper from './modules/submissions';
import SubscriptionPlansWrapper from './modules/subscription-plans';
import EditTemplate from './modules/template/EditTemplate';
import TemplateList from './modules/template/TemplateList';
import TransactionLogsWrapper from './modules/transaction-logs';
import EditUser from './modules/users/EditUser';
import UserList from './modules/users/UserList';

const { Paragraph } = Typography;

const MyFallbackComponent = ({ error, componentStack }) => (
  <Result
    status="error"
    title={ERROR_PAGE_TITLE}
    subTitle={ERROR_PAGE_SUBTITLE}
  >
    <div className="desc">
      <Paragraph>
        <Typography.Title level={4}> Error:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {error?.message?.toString()}
      </Paragraph>
      <Paragraph>
        <Typography.Title level={4}> Stacktrace:</Typography.Title>
      </Paragraph>
      <Paragraph>
        <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
        {componentStack}
      </Paragraph>
    </div>
  </Result>
);

const RoutesCollection = () => {
  const { getCurrentUserRole } = useContext(AppContext);
  const roles = getCurrentUserRole() || {};

  const AUTH_MODULES = [
    {
      path: ROUTES?.LOGIN,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.LOGIN, element: <Login /> }],
    },
    {
      path: ROUTES?.SET_PASSWORD,
      element: <SetPassword />,
    },
    {
      path: ROUTES?.RESET_PASSWORD,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.RESET_PASSWORD, element: <SetPassword /> }],
    },
    {
      path: ROUTES?.FORGET_PASSWORD,
      element: <PublicRoute />,
      children: [
        { path: ROUTES?.FORGET_PASSWORD, element: <ForgetPassword /> },
      ],
    },
    {
      path: ROUTES?.SIGNUP,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.SIGNUP, element: <Signup /> }],
    },
    {
      path: ROUTES?.LOGOUT,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.LOGOUT, element: <Logout /> }],
    },
    {
      path: ROUTES?.AUTHENTICATION,
      element: <PrivateRoute />,
      children: [{ path: ROUTES?.AUTHENTICATION, element: <RefreshToken /> }],
    },
    {
      path: ROUTES?.VERIFY_EMAIL,
      element: <PublicRoute />,
      children: [{ path: ROUTES?.VERIFY_EMAIL, element: <VerifyEmail /> }],
    },
  ];

  const DASHBOARD_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [{ path: ROUTES?.MAIN, element: <Dashboard /> }],
        },
      ],
    },
  ];

  const USER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.USERS,
              children: [
                // multiple children modules inside movies
                { path: ROUTES?.USERS, element: <UserList /> },
                { path: ROUTES?.ADD_USERS, element: <EditUser /> },
                { path: ROUTES?.EDIT_USER, element: <EditUser /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const CATEGORY_MODULE = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.TEMPLATE_CATEGORY,
              children: [
                { path: ROUTES?.TEMPLATE_CATEGORY, element: <Category /> },
              ],
            },
          ],
        },
      ],
    },
  ];

  const TEMPLATE_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.TEMPLATE,
              children: [
                { path: ROUTES?.TEMPLATE, element: <TemplateList /> },
                {
                  path: ROUTES?.ADD_TEMPLATE,
                  element:
                    roles !== USER_TYPE.ADMIN ? <EditTemplate /> : <Error404 />,
                },
                {
                  path: ROUTES?.EDIT_TEMPLATE,
                  element:
                    roles !== USER_TYPE.STAFF ? <EditTemplate /> : <Error404 />,
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  const REVIEW_APPEALS_MODULE = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.REVIEW_APPEALS}/*`,
              element: <ReviewAppealsWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const IMPORT_CSV_MODULE = [
    process.env.REACT_APP_ALLOW_IMPORT_CSV === 'true' && {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.IMPORT_CSV}/*`,
              element: <ImportCSVWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const SUBSCRIPTION_PLANS_MODULE = [
    {
      path: ROUTES.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.SUBSCRIPTION_PLANS}/*`,
              element: <SubscriptionPlansWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const USER_PROFILE_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PROFILE,
              element: <Profile />,
            },
          ],
        },
      ],
    },
  ];

  const JUDGES_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `:category${ROUTES?.JUDGES}/*`,
              element: <JudgesWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const ASSESSES_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `:category${ROUTES?.ASSESSES}/*`,
              element: <AssessesWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const JUDGEMENTS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `:category${ROUTES?.JUDGEMENTS}/*`,
              element: <JudgementWrapper />,
            },
            {
              path: `:category${ROUTES?.LAWYERS}/*`,
              element: <LawyerWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const JUDGEMENT_AI_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.ACTS}/*`,
              element: <ActsWrapper />,
            },
            {
              path: `${ROUTES?.NOTIFICATIONS}/*`,
              element: <NotificationsWrapper />,
            },
            {
              path: `${ROUTES?.SUBMISSIONS}/*`,
              element: <SubmissionsWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const KEYWORDS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `:category${ROUTES?.KEYWORDS}/*`,
              element: <KeywordWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const SECTIONS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `:category${ROUTES?.SECTIONS}/*`,
              element: <SectionWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const LAWYERS_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.LAWYERS}/*`,
              element: <LawyerWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const CONSULTATION_CALLS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.CONSULTATION_CALLS}/*`,
              element: <ConsultationCallWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const TRANSACTION_LOGS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.TRANSACTION_LOGS}/*`,
              element: <TransactionLogsWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const QUOTATIONS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.QUOTATIONS}/*`,
              element: <QuotationWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const REPORTS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.REPORTS}/*`,
              element: <ReportsWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const PAYMENT_DISTRIBUTION = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.PAYMENT_DISTRIBUTION}/*`,
              element: <PaymentDistributionWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const AI_CREDIT_CONFIGS = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: `${ROUTES?.CONFIGURATION}/*`,
              element: <AiConfigWrapper />,
            },
          ],
        },
      ],
    },
  ];

  const PAYMENT_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: ROUTES?.PAYMENTS,
              element: (
                <Navigate
                  to={`${ROUTES.PAYMENTS}/${PAYMENT_TAB_KEYS.PLANS}`}
                  replace
                />
              ),
            },
            {
              path: `${ROUTES.PAYMENTS}/:tab`,
              element: <PaymentList />,
            },
          ],
        },
      ],
    },
  ];

  const OTHER_MODULES = [
    {
      path: ROUTES?.MAIN,
      element: <PrivateRoute />,
      children: [
        {
          path: ROUTES?.MAIN,
          element: <App />,
          children: [
            {
              path: '*',
              element: <Error404 />,
            },
          ],
        },
      ],
    },
  ];

  const routesForAdminAndSuperAdmin = [
    ...USER_MODULES,
    ...CATEGORY_MODULE,
    ...JUDGES_MODULES,
    ...ASSESSES_MODULES,
    ...JUDGEMENTS_MODULES,
    ...JUDGEMENT_AI_MODULES,
    ...LAWYERS_MODULES,
    ...SECTIONS_MODULES,
    ...KEYWORDS_MODULES,
    ...CONSULTATION_CALLS,
    ...QUOTATIONS,
    ...TRANSACTION_LOGS,
    ...PAYMENT_DISTRIBUTION,
    ...SUBSCRIPTION_PLANS_MODULE,
    ...REPORTS,
    ...REVIEW_APPEALS_MODULE,
    ...PAYMENT_MODULES,
    ...IMPORT_CSV_MODULE,
    ...(process.env.REACT_APP_IS_SHOW_CONFIGURATION === 'true'
      ? AI_CREDIT_CONFIGS
      : []),
  ];

  const getModluesAsPerRole = (role) => {
    switch (role) {
      case USER_TYPE.SUPER_ADMIN:
        return routesForAdminAndSuperAdmin;
      case USER_TYPE.ADMIN:
        return routesForAdminAndSuperAdmin;
      case USER_TYPE.STAFF:
        return [
          ...JUDGES_MODULES,
          ...ASSESSES_MODULES,
          ...JUDGEMENTS_MODULES,
          ...LAWYERS_MODULES,
          ...SECTIONS_MODULES,
          ...KEYWORDS_MODULES,
          ...REVIEW_APPEALS_MODULE,
        ];
      default:
        return [];
    }
  };

  const element = useRoutes([
    ...AUTH_MODULES,
    ...DASHBOARD_MODULES,
    ...USER_PROFILE_MODULES,
    ...TEMPLATE_MODULES,
    ...OTHER_MODULES,
    ...getModluesAsPerRole(roles),
  ]);
  return element;
};

const RoutesWrapper = () => {
  const { initializeAuth, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const path = history?.location?.pathname;
  const idToken = getToken();

  const [getCurrentUser] = useLazyQuery(GET_CURRENT_USER, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth(idToken, res?.getCurrentUser);
      setLoading(false);
    },
    onError: () => {
      history?.push(ROUTES?.LOGOUT);
      setLoading(false);
    },
  });

  useEffect(() => {
    if (path === ROUTES?.LOGOUT || idToken) {
      getCurrentUser();
    } else {
      setLoading(false);
    }

    // Below line is disabling Eslint auto fix we don't want any value in use effect array
    // We want to call initializeAuth once. Please add this line while you working with hooks and you want to call it once.
    // eslint-disable-next-line
  }, []);

  // use this variable from envs so that we can able to run maintenance page on runtime.
  const maintenance = process.env.REACT_APP_MAINTENANCE_ENABLE;

  if (loading) return <LoaderComponent />;
  return (
    <Sentry.ErrorBoundary fallback={MyFallbackComponent}>
      <Router>
        {maintenance === 'true' ? <MaintenancePage /> : <RoutesCollection />}
      </Router>
    </Sentry.ErrorBoundary>
  );
};
export default RoutesWrapper;
