import { Button, Checkbox, Form, Modal, Tabs } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import React from 'react';
import UI_LABELS from '../../../common/uiLables';

const SummaryTabs = ['Summary', 'Facts', 'Issues', 'Findings', 'Other'];

const SummaryModal = ({ open, setOpen, judgement, form }) => (
  <Modal open={open} footer={null} width={800} closeIcon={null} centered>
    <Form form={form}>
      <Tabs defaultActiveKey="summary">
        {SummaryTabs.map((tab) => (
          <Tabs.TabPane key={tab.toLowerCase()} tab={tab}>
            {tab === 'Summary' && (
              <>
                <Form.Item name="summary" label="AI Summary">
                  <TextArea placeholder="Enter AI Summary" rows={5} />
                </Form.Item>
                <Form.Item name="isSummary" valuePropName="checked">
                  <Checkbox>{UI_LABELS.IS_VISIBLE}</Checkbox>
                </Form.Item>
              </>
            )}

            {tab === 'Facts' && (
              <>
                <ul className="mt-0 pl-24 list">
                  {judgement?.facts?.length > 0 ? (
                    judgement?.facts?.map((item, key) => (
                      <li key={`facts-${key + 1}`}>{item}</li>
                    ))
                  ) : (
                    <p className="d-flex align-center justify-center error-red">
                      No Facts Found
                    </p>
                  )}
                </ul>
                <Form.Item name="isFacts" valuePropName="checked">
                  <Checkbox>{UI_LABELS.IS_VISIBLE}</Checkbox>
                </Form.Item>
              </>
            )}

            {tab === 'Issues' && (
              <>
                <ul className="mt-0 pl-24 list">
                  {judgement?.issues?.length > 0 ? (
                    judgement?.issues?.map((item, key) => (
                      <li key={`issue-${key + 1}`}>{item}</li>
                    ))
                  ) : (
                    <p className="d-flex align-center justify-center error-red">
                      No Issues Found
                    </p>
                  )}
                </ul>
                <Form.Item name="isIssues" valuePropName="checked">
                  <Checkbox>{UI_LABELS.IS_VISIBLE}</Checkbox>
                </Form.Item>
              </>
            )}

            {tab === 'Findings' && (
              <>
                <ul className="mt-0 pl-24 list">
                  {judgement?.findings?.length > 0 ? (
                    judgement?.findings?.map((item, key) => (
                      <li key={`findings-${key + 1}`}>{item}</li>
                    ))
                  ) : (
                    <p className="d-flex align-center justify-center error-red">
                      No Findings Found
                    </p>
                  )}
                </ul>
                <Form.Item name="isFindings" valuePropName="checked">
                  <Checkbox>{UI_LABELS.IS_VISIBLE}</Checkbox>
                </Form.Item>
              </>
            )}

            {tab === 'Other' && (
              <>
                <ul className="mt-0 pl-0">
                  {Array.isArray(judgement?.others) &&
                    judgement.others.map((item, outerIndex) =>
                      Object.entries(item).map(([key, val], innerIndex) => (
                        <li
                          key={`${outerIndex + 1}-${innerIndex + 1}`}
                          className="mb-8 d-flex"
                        >
                          <div className="body-l text-dark min-w-fit">
                            {key}:
                          </div>
                          <div className="body-l ml-8">{val}</div>
                        </li>
                      )),
                    )}
                  {judgement?.outcome && (
                    <li className="d-flex">
                      <div className="body-l text-dark min-w-fit">
                        Outcome :
                      </div>
                      <div className="body-l ml-8">{judgement.outcome}</div>
                    </li>
                  )}
                  {!judgement?.others?.length > 0 &&
                    !judgement?.outcome?.length > 0 && (
                      <p className="d-flex align-center justify-center error-red">
                        No Others Found
                      </p>
                    )}
                </ul>
                <Form.Item name="isOthers" valuePropName="checked">
                  <Checkbox>{UI_LABELS.IS_VISIBLE}</Checkbox>
                </Form.Item>
              </>
            )}
          </Tabs.TabPane>
        ))}
      </Tabs>
      <div className="d-flex align-center justify-end">
        <Button type="primary" onClick={() => setOpen(false)}>
          {UI_LABELS.DONE}
        </Button>
      </div>
    </Form>
  </Modal>
);

export default SummaryModal;
