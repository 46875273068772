import { PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Radio, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  SORT_OPTION,
  TEMPLATE_STATUS_COLOR,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import SectionsModal from './components/SectionModal';
import GET_SECTIONS from './graphql/queries';

const { Title } = Typography;
const CHECK_ARRAY_FILTER = [
  {
    label: UI_LABELS.YES,
    value: true,
  },
  {
    label: UI_LABELS.NO,
    value: false,
  },
];
const initialFormValue = { label: '', considerCheckArray: false };

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  label: COMMON_QUERY_PARAMS.SORT_NAME,
};
function SectionsList() {
  const { category } = useParams();
  const location = useLocation();
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [initialFormData, setInitialFormData] = useState(initialFormValue);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());
  const activeStatus = getQueryParams?.[COMMON_QUERY_PARAMS.IS_ACTIVE];

  const isActive = activeStatus ? activeStatus === UI_LABELS.YES : null;

  const getFilter = prepareFilterFromUrl(
    {
      limit: {
        queryString: COMMON_QUERY_PARAMS.LIMIT,
        defaultValue: LIMIT,
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
        value: CATEGORIES_KEYS[category] ?? null,
      },
      considerCheckArray: {
        queryString: COMMON_QUERY_PARAMS.IS_ACTIVE,
        defaultValue: null,
        value: isActive ?? null,
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });
  const fetchData = async () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_SECTIONS,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      });
      setData(listData?.data?.sectionsAdmin?.data);
      setCount(listData?.data?.sectionsAdmin?.count ?? 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData({
      filter: {
        skip: 0,
        limit: LIMIT,
        search: '',
      },
      sort: { sortBy: 'DESC', sortOn: 'createdAt' },
    });
    setSearch(searchParamsValue?.toString());
  }, [location.search, category]);

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const handleTableChange = (paginationValue, filters, sorter, extra) => {
    const pageSizeValue = paginationValue?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: null,
        [COMMON_QUERY_PARAMS.IS_ACTIVE]:
          filters?.considerCheckArray && filters?.considerCheckArray?.length
            ? filters?.considerCheckArray
            : null,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: paginationValue?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleForm = async () => {
    try {
      if (formPopup.id === 'new') {
        setQueryParams(null);
      }
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
    }
  };
  const getCheckArrayStatusFilterProps = () => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {CHECK_ARRAY_FILTER?.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.label}
              checked={selectedKeys.includes(option?.label)}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? option?.label : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.SECTIONS}
          {count ? ` (${count})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            key={category}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              setFormPopup({
                isOpen: true,
                id: 'new',
              });
              setInitialFormData(initialFormValue);
            }}
          >
            {UI_LABELS.ADD_SECTION}
          </Button>
        </div>
      </div>
      <Table
        columns={[
          {
            title: UI_LABELS.NAME,
            width: '40%',
            dataIndex: 'label',
            key: 'label',
            sorter: { multiple: 1 },
            sortOrder: getSorter.sorters.label,
          },
          {
            title: UI_LABELS.CONSIDER_THIS_LABEL_FOR_SUFIX_PREFIX_SECTION_MATCH,
            render: (item) =>
              // eslint-disable-next-line no-nested-ternary
              item?.considerCheckArray === true ? (
                <Badge count="Yes" color={TEMPLATE_STATUS_COLOR.PUBLISHED} />
              ) : item?.considerCheckArray === false ? (
                <Badge count="No" color={TEMPLATE_STATUS_COLOR.UNPUBLISHED} />
              ) : (
                '-'
              ),
            key: 'considerCheckArray',
            filteredValue:
              getQueryParams?.[COMMON_QUERY_PARAMS.IS_ACTIVE] ?? null,
            ...getCheckArrayStatusFilterProps(),
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                id: record?.id,
              });
              setInitialFormData({
                label: record?.label,
                considerCheckArray: Boolean(record?.considerCheckArray),
              });
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={data}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: count,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        scroll={{ y: `calc(100vh - 365px)` }}
        loading={loading}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <SectionsModal
        formPopup={formPopup}
        handleCloseModal={handleClose}
        handleModalForm={handleForm}
        initialFormData={initialFormData}
        category={category}
      />
    </>
  );
}

export default SectionsList;
