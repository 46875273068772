import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORIES_KEYS,
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  SORT_OPTION,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import KeywordsModal from './components/KeywordsModal';
import GET_KEYWORD from './graphql/queries';

const { Title, Text } = Typography;

const initialFormValue = { label: '', regex: [] };
const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  label: COMMON_QUERY_PARAMS.SORT_NAME,
  slug: COMMON_QUERY_PARAMS.SORT_SLUG,
};
function KeywordsList() {
  const { category } = useParams();
  const location = useLocation();

  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formPopup, setFormPopup] = useState({
    isOpen: false,
    id: null,
  });
  const [initialFormData, setInitialFormData] = useState(initialFormValue);
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: {
        queryString: COMMON_QUERY_PARAMS.LIMIT,
        defaultValue: LIMIT,
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
        value: CATEGORIES_KEYS[category],
      },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const fetchData = async () => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    setLoading(true);
    try {
      const listData = await mongoClient?.query({
        query: GET_KEYWORD,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      });
      setData(listData?.data?.keywordsAdmin?.data);
      setCount(listData?.data?.keywordsAdmin?.count ?? 0);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    setSearch(searchParamsValue?.toString());
  }, [location.search, category]);

  const handleSearchChange = (e) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const handleTableChange = (paginationValue, filters, sorter) => {
    const pageSizeValue = paginationValue?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    const prepareSorter = {};
    Object?.keys(fieldToParams)?.forEach((item) => {
      sorterAry?.forEach((filterItem) => {
        if (filterItem?.columnKey && item === filterItem?.columnKey) {
          // eslint-disable-next-line no-nested-ternary
          prepareSorter[
            fieldToParams[filterItem?.columnKey]
            // eslint-disable-next-line no-nested-ternary
          ] = filterItem?.order
            ? filterItem?.order === 'descend'
              ? SORT_OPTION.DESC
              : SORT_OPTION.ASC
            : null;
        }
      });
      prepareSorter[fieldToParams?.[item]] =
        prepareSorter[fieldToParams?.[item]] ?? null;
    });

    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: paginationValue?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
      ...prepareSorter,
    });
  };

  const handleClose = () => {
    setFormPopup({
      isOpen: false,
      id: null,
    });
  };

  const handleForm = async () => {
    try {
      if (formPopup.id === 'new') {
        setQueryParams(null);
      }
      await fetchData();
    } catch (error) {
      return;
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.KEYWORDS}
          {count ? ` (${count})` : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            key={category}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              setFormPopup({
                isOpen: true,
                id: 'new',
              });
              setInitialFormData(initialFormValue);
            }}
          >
            {UI_LABELS.ADD_KEYWORD}
          </Button>
        </div>
      </div>
      <Table
        columns={[
          {
            title: UI_LABELS.NAME,
            width: '40%',
            dataIndex: 'label',
            key: 'label',
            sorter: { multiple: 1 },
            sortOrder: getSorter.sorters.label,
          },
          {
            title: UI_LABELS.REGEX,
            width: '40%',
            key: 'slug',
            sorter: { multiple: 2 },
            sortOrder: getSorter.sorters.slug,
            render(item) {
              return (
                <Text
                  ellipsis={{
                    rows: 3,
                  }}
                >
                  {item?.regex?.length ? item?.regex?.join(',') : '-'}
                </Text>
              );
            },
          },
        ]}
        // eslint-disable-next-line arrow-body-style
        onRow={(record) => {
          return {
            onClick: () => {
              setFormPopup({
                isOpen: true,
                // eslint-disable-next-line no-underscore-dangle
                id: record?._id,
              });
              setInitialFormData({
                label: record?.label,
                regex: record?.regex?.length
                  ? record?.regex?.map((item) => ({ reg: item }))
                  : [],
              });
            },
          };
        }}
        rowKey={(record) => record?.id}
        dataSource={data}
        pagination={{
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          total: count,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
        rowClassName="pointer"
      />
      <KeywordsModal
        formPopup={formPopup}
        handleCloseModal={handleClose}
        handleModalForm={handleForm}
        initialFormData={initialFormData}
        category={category}
      />
    </>
  );
}

export default KeywordsList;
