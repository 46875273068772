import { useLazyQuery } from '@apollo/client';
import { Select } from 'antd';
import { debounce, get, map, trim, uniqBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AI_CREDIT_PLANS,
  AI_PLAN_NAME,
  FEATURE_NOTICE_BOARD_DIRECT_TAX,
  FEATURE_NOTICE_BOARD_INDIRECT_TAX,
  LIMIT,
  NOTICE_BOARD_NAME,
  TAX_CATEGORIES,
} from '../../../common/constants';

const { Option } = Select;
let searchDebounce = null;

const TransactionCommonSelect = (props) => {
  const {
    placeholder = 'Search',
    query,
    fetchPolicy = 'network-only',
    variables = {},
    responsePath,
    valuePath,
    labelPath,
    optionalLabelPath = '',
    showSearch,
    conditionToCheckBeforeQuery = true,
    isDataDependent = [],
    customOptions = [],
    useEffectDeps = [],
    inputRef = null,
    provider = null,
    onSelect,
    isReviewAppealStaff,
    value,
    onChange,
    ...rest
  } = props;
  const [listData, setListData] = useState([...customOptions] || []);
  const [hasMore, setHasMore] = useState(true);
  const [skipData, setSkipData] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [isClear, setIsClear] = useState(true);

  const [fetchListData] = useLazyQuery(query, {
    fetchPolicy,
    onCompleted: (res) => {
      const data = get(res, responsePath, []);

      const list = isClear ? [...data] : [...listData, ...data];
      setListData(list);
      setHasMore(data?.length >= LIMIT);
    },
    onError() {},
  });

  useEffect(() => {
    if (isDataDependent) {
      setListData([]);
      setSkipData(0);
    }
  }, isDataDependent);

  useEffect(() => {
    if (conditionToCheckBeforeQuery) {
      fetchListData({
        fetchPolicy,
        variables: {
          ...variables,
          filter: {
            ...(variables?.filter || {}),
            skip: 0,
            limit: LIMIT,
          },
        },
      });
    }
  }, useEffectDeps);

  const searchQuery = (search) => {
    setSearchText(trim(search));
    fetchListData({
      fetchPolicy: 'network-only',
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          skip: 0,
          limit: LIMIT,
          search: trim(search),
        },
      },
    });
  };

  const handleSearch = (val) => {
    setSkipData(0);
    if (searchDebounce) {
      searchDebounce.cancel();
      searchDebounce = null;
    }
    searchDebounce = debounce(searchQuery, 500);
    searchDebounce(trim(val));
  };

  const handleClear = () => {
    if (searchText) {
      searchQuery();
    }
  };
  const handleScroll = async (event) => {
    const { target } = event;
    const { scrollTop, clientHeight, scrollHeight } = target || {};
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight - 5;
    if (scrolledToBottom && hasMore) {
      const newSkip = skipData + LIMIT;
      setIsClear(false);
      await fetchListData({
        variables: {
          ...variables,
          filter: {
            ...variables?.filter,
            skip: newSkip,
            limit: LIMIT,
            search: trim(searchText),
          },
        },
      });
      setSkipData(newSkip);
    }
  };

  const afterSearch = () => {
    setSearchText('');
    setIsClear(true);
    fetchListData({
      fetchPolicy,
      variables: {
        ...variables,
        filter: {
          ...(variables?.filter || {}),
          skip: 0,
          limit: LIMIT,
        },
      },
    });
  };
  return (
    <Select
      ref={inputRef}
      placeholder={placeholder}
      showSearch={showSearch}
      onSearch={showSearch && handleSearch}
      onClear={handleClear}
      onBlur={handleClear}
      onPopupScroll={handleScroll}
      filterOption={(inputValue, option) =>
        option?.children?.toLowerCase()?.includes(inputValue?.toLowerCase())
      }
      onSelect={(val, record) => {
        if (searchText) afterSearch();
        onSelect?.(val, record);
      }}
      onChange={(val, record) => {
        if (searchText) afterSearch();
        onChange?.(val, record);
      }}
      value={
        // eslint-disable-next-line no-nested-ternary
        isReviewAppealStaff
          ? listData?.length === 1
            ? listData?.[0]?.benchId
            : value
          : value
      }
      {...rest}
    >
      <>
        {map(
          uniqBy(provider ? [provider, ...listData] : [...listData], valuePath),
          (item) => {
            const getTaxLabel = (features, matchValue, category, title) =>
              features?.includes(matchValue)
                ? `${NOTICE_BOARD_NAME} - ${category} - ${title}`
                : null;
            const aiPlans = item?.features?.includes(AI_CREDIT_PLANS)
              ? `${AI_PLAN_NAME} - ${item?.title}`
              : item?.title;

            const indirectTaxLabel =
              getTaxLabel(
                item?.features,
                FEATURE_NOTICE_BOARD_INDIRECT_TAX,
                TAX_CATEGORIES.INDIRECT_TAX,
                item?.title,
              ) || aiPlans;

            const plansLabel =
              getTaxLabel(
                item?.features,
                FEATURE_NOTICE_BOARD_DIRECT_TAX,
                TAX_CATEGORIES.DIRECT_TAX,
                item?.title,
              ) || indirectTaxLabel;

            const optionValue = get(item, valuePath);
            const optionLabel = useEffectDeps?.includes('SUBSCRIPTION')
              ? plansLabel
              : get(item, labelPath);
            const optionalLabel = get(item, optionalLabelPath);
            return (
              <Option
                key={`option-key:${optionValue}`}
                value={optionValue}
                label={optionLabel}
              >
                {optionalLabel
                  ? `${optionLabel} ${optionalLabel}`
                  : `${optionLabel}`}
              </Option>
            );
          },
        )}
      </>
    </Select>
  );
};

export default TransactionCommonSelect;
