import { useLazyQuery } from '@apollo/client';
import { Button, DatePicker, Table, Tag, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  COMMON_QUERY_PARAMS,
  LIMIT,
  PAGE_SIZE_OPTIONS,
  SORT_OPTION,
  STANDARD_DATE_FORMAT,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import {
  disableFutureDate,
  prepareFilterFromUrl,
  prepareSorterFromUrl,
} from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useUrlQuery from '../../hooks/useUrlQuery';
import QuotationDetails from './components/QuotationDetails';
import { GET_QUOTATIONS } from './graphql/queries';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
};

function QuotationList() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const [orderDetails, setOrderDetails] = useState();

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
      startTime: {
        queryString: COMMON_QUERY_PARAMS.START,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS.START] ?? null,
      },
      endTime: {
        queryString: COMMON_QUERY_PARAMS.END,
        defaultValue: '',
        value: getQueryParams[COMMON_QUERY_PARAMS.END] ?? null,
      },
      type: {
        queryString: COMMON_QUERY_PARAMS.CALL_TYPE,
        defaultValue: 'ONE_TO_ONE',
      },
    },
    getQueryParams,
  );

  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const [fetchQuotations, { data: quotations, loading }] = useLazyQuery(
    GET_QUOTATIONS,
    {
      fetchPolicy: 'network-only',
      onError() {},
    },
  );

  useEffect(() => {
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    fetchQuotations({
      variables: prepareVariable,
    });
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const handleSearchChange = (e) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.SEARCH]: e.trim(),
      [COMMON_QUERY_PARAMS.PAGE]: null,
    });
  };

  const handleTableChange = (pagination) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
      [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
    });
  };

  const handleDatePicker = (date) => {
    setQueryParams({
      [COMMON_QUERY_PARAMS.PAGE]: null,
      [COMMON_QUERY_PARAMS.START]:
        date?.[0] && dayjs(date?.[0])?.startOf('day').format(),
      [COMMON_QUERY_PARAMS.END]:
        date?.[1] && dayjs(date?.[1])?.endOf('day').format(),
    });
  };

  const columns = [
    {
      title: UI_LABELS.TIME,
      dataIndex: 'Time',
      render: (text, record) =>
        `${dayjs(record?.booking?.startTime).format('HH:mm')} to ${dayjs(
          record?.booking?.endTime,
        ).format('HH:mm')}`,
    },
    {
      title: UI_LABELS.CONSULTANT,
      dataIndex: 'consultant',
      render: (text, record) =>
        `${record?.booking?.consultationCall?.creator?.firstName} ${record?.booking?.consultationCall?.creator?.lastName}`,
    },
    {
      title: UI_LABELS.SERVICE_NAME,
      dataIndex: 'serviceName',
      width: '15%',
    },
    {
      title: UI_LABELS.DESCRIPTION,
      dataIndex: 'description',
      ellipsis: true,
    },
    {
      title: UI_LABELS.PRICE,
      dataIndex: 'price',
      render: (price) => `₹ ${price?.toFixed(2)}`,
    },
    {
      title: UI_LABELS.QUOTATION_DATE,
      dataIndex: 'quotationDate',
      render: (date) => dayjs(date).format(STANDARD_DATE_FORMAT),
    },
    {
      title: UI_LABELS.TYPE,
      dataIndex: 'type',
      align: 'center',
      render: (text, record) => (
        <Tag color="processing">
          {record?.booking?.consultationCall?.type === 'ONE_TO_ONE'
            ? '1-to-1'
            : 'Webinar'}
        </Tag>
      ),
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            setShowModal(true);
            setOrderDetails(record);
          }}
        >
          {UI_LABELS.VIEW_DETAILS}
        </Button>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {UI_LABELS.QUOTATIONS}
          {quotations?.quotationsAdmin?.count
            ? ` (${quotations?.quotationsAdmin?.count})`
            : ''}
        </Title>
        <div className="d-flex gap-12 filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <div className="d-flex gap-12">
            <RangePicker
              onChange={handleDatePicker}
              disabledDate={disableFutureDate}
              placeholder={['Start Date', 'End Date']}
              value={[
                getQueryParams?.[COMMON_QUERY_PARAMS.START]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.START])
                  : null,
                getQueryParams?.[COMMON_QUERY_PARAMS.END]
                  ? dayjs(getQueryParams[COMMON_QUERY_PARAMS.END])
                  : null,
              ]}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={quotations?.quotationsAdmin?.data}
        onChange={handleTableChange}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        pagination={{
          total: quotations?.quotationsAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
      />
      <QuotationDetails
        setShowModal={setShowModal}
        showModal={showModal}
        orderDetails={orderDetails}
      />
    </>
  );
}

export default QuotationList;
