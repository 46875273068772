import { gql } from '@apollo/client';

const FETCH_PLAN_PURCHASED_LIST = gql`
  query SubscriptionPlanPurchasesAdmin(
    $filter: SubscriptionPlanPurchasesAdminFilter!
    $sort: [ListSubscriptionPlanPurchasesSort!]
  ) {
    subscriptionPlanPurchasesAdmin(filter: $filter, sort: $sort) {
      data {
        id
        featureConfig
        features
        createdAt
        expireDate
        features
        invoiceShortUrl
        purchaser {
          id
          firstName
          lastName
          phoneNumber
        }
        status
        usageCount
        subscriptionPlan {
          creator {
            firstName
          }
          title
          type
          price
          credit
        }
        billing {
          price
          transactionLog {
            id
          }
        }
      }
      count
    }
  }
`;

const FETCH_TEMPLATES_PURCHASED_LIST = gql`
  query Billing(
    $filter: TemplatePurchasesAdminFilter!
    $sort: [TemplatePurchasesSort!]
  ) {
    templatePurchasesAdmin(filter: $filter, sort: $sort) {
      count
      data {
        createdAt
        expireDate
        billing {
          price
          transactionLog {
            id
          }
        }
        id
        purchaser {
          id
          firstName
          lastName
          phoneNumber
        }
        status
        template {
          price
          title
          category {
            name
            id
          }
          subCategory {
            name
            id
          }
        }
      }
    }
  }
`;

const FETCH_CONSULTATION_CALL_PURCHASED_LIST = gql`
  query BookingsPurchaseAdmin(
    $filter: ListBookingsAdminFilter!
    $sort: [ListRecordingAdminSort!]
  ) {
    bookingsPurchaseAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        meetingType
        createdAt
        purchaser {
          id
          firstName
          lastName
          phoneNumber
        }
        consultationCall {
          title
          creator {
            firstName
            lastName
          }
          duration
          type
          category
          createdAt
          price
        }
        billing {
          phone
          firstName
          lastName
          price
          transactionLog {
            id
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line import/prefer-default-export
export {
  FETCH_CONSULTATION_CALL_PURCHASED_LIST,
  FETCH_PLAN_PURCHASED_LIST,
  FETCH_TEMPLATES_PURCHASED_LIST,
};
