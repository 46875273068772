import { ExclamationCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Modal, Radio, Table, Typography } from 'antd';
import dayjs from 'dayjs';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { mongoClient } from '../../apollo';
import {
  CATEGORY_OPTIONS,
  COMMON_QUERY_PARAMS,
  JUDGEMENT_AI_STATUS_SELECTION,
  LIMIT,
  MODULES,
  PAGE_SIZE_OPTIONS,
  ROUTES,
  SORT_OPTION,
  TAX_CATEGORIES,
  TEMPLATE_STATUS_COLOR,
  dateTimeFormat,
} from '../../common/constants';
import UI_LABELS from '../../common/uiLables';
import { prepareFilterFromUrl, prepareSorterFromUrl } from '../../common/utils';
import SearchComponent from '../../components/SearchComponent';
import useRouter from '../../hooks/useRouter';
import useUrlQuery from '../../hooks/useUrlQuery';
import { DELETE_SUBMISSION } from './graphql/mutations';
import { GET_SUBMISSIONS } from './graphql/queries';

const { Title } = Typography;
const { confirm: deleteConfirm } = Modal;
const fieldToParams = {
  createdAt: COMMON_QUERY_PARAMS.SORT_CREATED_AT,
  title: COMMON_QUERY_PARAMS.SORT_NAME,
};

const SubmissionsList = () => {
  const { navigate } = useRouter();
  const location = useLocation();

  const [submissionsData, setSubmissionsData] = useState([]);
  const [loading, setLoading] = useState(true);

  const { getQueryParams, setQueryParams } = useUrlQuery();
  const searchParamsValue = getQueryParams?.[COMMON_QUERY_PARAMS.SEARCH] ?? '';
  const [search, setSearch] = useState(searchParamsValue?.toString());

  const getFilter = prepareFilterFromUrl(
    {
      limit: { queryString: COMMON_QUERY_PARAMS.LIMIT, defaultValue: LIMIT },
      search: {
        queryString: COMMON_QUERY_PARAMS.SEARCH,
        defaultValue: '',
        value: searchParamsValue?.toString() ?? '',
      },
      category: {
        queryString: COMMON_QUERY_PARAMS.CATEGORY,
        defaultValue: null,
      },
      status: { queryString: COMMON_QUERY_PARAMS.STATUS, defaultValue: null },
      skip: {
        queryString: COMMON_QUERY_PARAMS.SKIP,
        defaultValue: 0,
        value:
          getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] &&
          getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT]
            ? Number(getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] - 1) *
              Number(getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT])
            : 0,
      },
    },
    getQueryParams,
  );
  const getSorter = prepareSorterFromUrl(fieldToParams, {
    sortOn: 'createdAt',
    sortBy: SORT_OPTION.DESC,
  });

  const listSubmissions = async () => {
    setLoading(true);
    const prepareVariable = { filter: getFilter, sort: getSorter.querySorters };
    await mongoClient
      ?.query({
        query: GET_SUBMISSIONS,
        fetchPolicy: 'network-only',
        variables: prepareVariable,
      })
      .then((res) => {
        setSubmissionsData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteSubmission = async (id) => {
    setLoading(true);
    await mongoClient
      ?.mutate({
        mutation: DELETE_SUBMISSION,
        variables: {
          where: {
            id,
          },
        },
      })
      .then(() => {
        listSubmissions();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listSubmissions();
    setSearch(searchParamsValue?.toString());
  }, [location.search]);

  const handleDelete = (id) => {
    deleteConfirm({
      centered: true,
      title: 'Are you sure you want to delete?',
      icon: <ExclamationCircleFilled />,
      okText: UI_LABELS.YES,
      okType: 'danger',
      cancelText: UI_LABELS.NO,
      onOk() {
        deleteSubmission(id);
      },
      onCancel() {},
    });
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    const pageSizeValue = pagination?.pageSize || LIMIT;
    let sorterAry = sorter;
    if (sorterAry && !Array.isArray(sorterAry)) {
      sorterAry = [sorterAry];
    }
    if (extra.action === 'filter') {
      setQueryParams({
        [COMMON_QUERY_PARAMS.CATEGORY]: filters?.category ?? null,
        [COMMON_QUERY_PARAMS.STATUS]: filters?.status ?? null,
        [COMMON_QUERY_PARAMS.PAGE]: null,
      });
    } else {
      const prepareSorter = {};
      Object?.keys(fieldToParams)?.forEach((item) => {
        sorterAry?.forEach((filterItem) => {
          if (filterItem?.columnKey && item === filterItem?.columnKey) {
            // eslint-disable-next-line no-nested-ternary
            prepareSorter[
              fieldToParams[filterItem?.columnKey]
              // eslint-disable-next-line no-nested-ternary
            ] = filterItem?.order
              ? filterItem?.order === 'descend'
                ? SORT_OPTION.DESC
                : SORT_OPTION.ASC
              : null;
          }
        });
        prepareSorter[fieldToParams?.[item]] =
          prepareSorter[fieldToParams?.[item]] ?? null;
      });
      setQueryParams({
        [COMMON_QUERY_PARAMS.PAGE]: pagination?.current,
        [COMMON_QUERY_PARAMS.LIMIT]: pageSizeValue,
        ...prepareSorter,
      });
    }
  };

  const handleSearchChange = (value) => {
    setQueryParams(
      {
        [COMMON_QUERY_PARAMS.SEARCH]: value.trim(),
        [COMMON_QUERY_PARAMS.PAGE]: null,
      },
      true,
    );
  };

  const getFilterProps = (options) => ({
    filterMultiple: false,
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
      <div className="custom-filter-dropdown">
        {options?.map((option) => (
          <div key={option?.label} className="mt-6 mb-6">
            <Radio
              value={option?.value}
              checked={selectedKeys === option?.value}
              onChange={(e) => {
                const updatedKeys = e?.target?.checked ? option?.value : null;
                setSelectedKeys(updatedKeys);
              }}
            >
              {option?.label}
            </Radio>
          </div>
        ))}
        <div className="control-panel">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              confirm();
            }}
            className="ok-button"
          >
            {UI_LABELS.APPLY}
          </Button>
          <Button
            className="ml-8 reset-button"
            size="small"
            onClick={() => {
              setSelectedKeys([]);
              confirm();
            }}
          >
            {UI_LABELS.RESET}
          </Button>
        </div>
      </div>
    ),
  });

  const columns = [
    {
      title: UI_LABELS.TITLE,
      key: 'title',
      sorter: { multiple: 2 },
      sortOrder: getSorter.sorters.title,
      dataIndex: 'title',
      width: '20%',
    },
    /* kept for future use */
    /* {
      title: UI_LABELS.DESCRIPTION,
      dataIndex: 'description',
      render(description) {
        return description ?? '-';
      },
    }, */
    {
      title: UI_LABELS.CATEGORY,
      dataIndex: 'category',
      render(category) {
        return TAX_CATEGORIES[category] ?? '-';
      },
      width: '10%',
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.CATEGORY] ?? null,
      ...getFilterProps(CATEGORY_OPTIONS),
    },
    {
      title: UI_LABELS.REGION,
      dataIndex: 'region',
      width: '10%',
    },
    {
      title: UI_LABELS.ISSUE_PERIOD,
      dataIndex: 'issuePeriod',
      width: '10%',
      render(issuePeriod) {
        return issuePeriod?.join(', ') ?? '-';
      },
    },
    {
      title: UI_LABELS.CREATED_ON,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: { multiple: 2 },
      sortDirections: getSorter.isDefaultSortApplied && [
        'ascend',
        'descend',
        'ascend',
      ],
      sortOrder: getSorter.sorters.createdAt,
      render: (value) => (
        <span>{value ? dayjs(value)?.format(dateTimeFormat) : '-'}</span>
      ),
    },
    {
      title: UI_LABELS.UPDATED_ON,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render(date) {
        return date ? dayjs(date).format(dateTimeFormat) : '-';
      },
    },
    {
      title: UI_LABELS.STATUS,
      dataIndex: 'status',
      width: '10%',
      render(status) {
        return (
          <Badge
            count={capitalize(status)}
            color={TEMPLATE_STATUS_COLOR?.[status]}
          />
        );
      },
      filteredValue: getQueryParams?.[COMMON_QUERY_PARAMS.STATUS] ?? null,
      ...getFilterProps(JUDGEMENT_AI_STATUS_SELECTION),
    },
    {
      title: UI_LABELS.ACTION,
      dataIndex: '',
      key: 'action',
      render: (_, { id } = {}) => (
        <div className="category-action">
          <Link to={`${ROUTES.EDIT_SUBMISSIONS}/${id}`}>{UI_LABELS.EDIT}</Link>
          <Button
            type="link"
            className="action-btn"
            onClick={() => handleDelete(id)}
            danger
          >
            {UI_LABELS.DELETE}
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="site-page-header-wrapper">
        <Title className="site-page-header p-0 mb-8 mt-0" level={3}>
          {MODULES.SUBMISSIONS}
          {submissionsData?.submissionsAdmin?.count
            ? ` (${submissionsData?.submissionsAdmin?.count})`
            : ''}
        </Title>
        <div className="filter-input mb-12">
          <SearchComponent
            className="list-search"
            getData={handleSearchChange}
            value={search}
            handleLiveChange={(e) => setSearch(e)}
          />
          <Button
            icon={<PlusCircleOutlined />}
            className="ml-8"
            key="1"
            type="primary"
            onClick={() => {
              navigate(ROUTES?.CREATE_SUBMISSIONS);
            }}
          >
            {UI_LABELS.ADD_SUBMISSION}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.id}
        dataSource={submissionsData?.submissionsAdmin?.data}
        pagination={{
          total: submissionsData?.submissionsAdmin?.count,
          current: getQueryParams?.[COMMON_QUERY_PARAMS.PAGE] ?? 1,
          pageSize: getQueryParams?.[COMMON_QUERY_PARAMS.LIMIT],
          pageSizeOptions: PAGE_SIZE_OPTIONS,
        }}
        loading={loading}
        scroll={{ y: `calc(100vh - 365px)` }}
        onChange={handleTableChange}
      />
    </>
  );
};
export default SubmissionsList;
