import { gql } from '@apollo/client';

// eslint-disable-next-line import/prefer-default-export
export const GET_QUOTATIONS = gql`
  query QuotationsAdmin(
    $filter: ListQuotationsFilter!
    $sort: [ListQuotationsSort!]!
  ) {
    quotationsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        id
        bookingId
        companyName
        upi
        contact
        defaultNote
        description
        email
        firstName
        lastName
        price
        profession
        serviceName
        quotationDate
        booking {
          id
          bookedFrom
          consultationCall {
            creator {
              lastName
              firstName
              email
            }
            type
            title
          }
          purchaser {
            firstName
            email
            lastName
            phoneNumber
            profession
            profileImage
          }
          user {
            firstName
            lastName
            phoneNumber
            profession
            profileImage
            email
          }
          status
          payerName
          cancellationReason
          calendarBookingId
          startTime
          endTime
          converted
          email
          meetingLink
          invoiceShortUrl
          quotation
          createdAt
        }
      }
    }
  }
`;
