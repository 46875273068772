import { Form, Input, Modal, Select } from 'antd';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { mongoClient } from '../../../apollo';
import {
  CATEGORIES_KEYS,
  JUDGES_TYPE_OPTIONS,
  LIMIT,
} from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import MongoCommonSelect from '../../../components/MongoCommonSelect';
import { MERGE_DATA } from '../graphql/mutations';
import { LIST_JUDGES } from '../graphql/queries';

const MergeDataModal = ({ open, onCancel, fetchJudgeCall }) => {
  const { category } = useParams();
  const variable = {
    filter: {
      skip: 0,
      limit: LIMIT,
      search: '',
      category: CATEGORIES_KEYS[category],
    },
    sort: { sortBy: 'DESC', sortOn: 'createdAt' },
  };

  const [form] = Form.useForm();
  const ids = Form?.useWatch('ids', form);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    setLoading(true);
    const mergeIntoId = values?.ids?.[0];
    const dataToPass = {
      judgeType: values?.judgeType,
      mergeIntoId,
      ids: values?.ids?.filter((_data, i) => i !== 0),
      name: values?.name ?? null,
      type: 'JUDGE',
    };
    try {
      const data = await mongoClient?.mutate({
        mutation: MERGE_DATA,
        fetchPolicy: 'network-only',
        variables: { data: dataToPass },
      });
      if (data?.data?.mergeData?.message) {
        setLoading(false);
        fetchJudgeCall();
        onCancel();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        onCancel={onCancel}
        title={UI_LABELS.MERGE_OR_EDIT_JUDGES}
        okText={UI_LABELS.SAVE}
        onOk={form.submit}
        okButtonProps={{ loading }}
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            name="ids"
            label="Judges"
            rules={[{ required: true, message: 'Please select Judge' }]}
          >
            <MongoCommonSelect
              getPopupContainer={(trigger) => trigger?.parentNode}
              placeholder="Select Judges to Merge or Edit"
              className="role-select-in-calls"
              showSearch
              allowClear
              query={LIST_JUDGES}
              fetchPolicy="network-only"
              responsePath="data.judgesAdmin.data"
              valuePath="_id"
              labelPath="name"
              variables={variable}
              value={ids}
              onChange={(_value, record) => {
                form.setFieldValue(
                  'name',
                  record?.[record?.length - 1]?.label ?? '',
                );
              }}
              mode="multiple"
            />
          </Form.Item>
          <Form.Item
            name="judgeType"
            label="Judge Type"
            rules={[{ required: true, message: 'Please select Judge Type' }]}
          >
            <Select placeholder="Select Judge Type">
              {JUDGES_TYPE_OPTIONS?.map((item) => (
                <Select.Option key={item?.key} value={item?.key}>
                  {item?.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="name" label="New Name">
            <Input placeholder="New Name" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default MergeDataModal;
