import { PercentageOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import React, { useState } from 'react';
import { CONSULTATION_CALL_TYPE } from '../../../common/constants';
import UI_LABELS from '../../../common/uiLables';
import useRouter from '../../../hooks/useRouter';
import { ADD_PAYMENT_DISTRIBUTION } from '../../payment-distribution/graphql/mutations';

function PaymentDistributionForm({ data }) {
  const { params } = useRouter();
  const [disableButton, setDisableButton] = useState(true);
  const { __typename, ...restData } = data || {};

  const [addPaymentDistribution] = useMutation(ADD_PAYMENT_DISTRIBUTION, {
    onCompleted() {
      setDisableButton(true);
    },
    onError() {},
  });

  const toFloat = (value) => parseFloat(value);

  const onFinish = (values) => {
    const { ...restValues } = values || {};
    const newFormValues = {
      ...restValues,
      consultationCall: {
        type: CONSULTATION_CALL_TYPE.PERCENT.key,
        value: toFloat(values?.consultationCall?.value),
      },
      service: toFloat(values?.service),
      paidPremiumBasedCommission: toFloat(values?.paidPremiumBasedCommission),
      subscriptionBasedCommission: toFloat(values?.subscriptionBasedCommission),
      referral: toFloat(values?.referral),
    };
    addPaymentDistribution({
      variables: {
        data: newFormValues,
        where: {
          id: params?.id,
        },
      },
    });
  };

  const handleValueChanges = () => {
    setDisableButton(false);
  };

  const validateNumber = (_rule, value, callback) => {
    const pattern = /^\d+$/;
    if (!value || pattern?.test(value)) {
      if (parseInt(value, 10) > 100) {
        callback('Value cannot be greater than 100');
      } else {
        callback();
      }
    } else {
      callback('Please enter numbers only');
    }
  };

  return (
    <Form
      onFinish={onFinish}
      initialValues={restData}
      layout="vertical"
      onValuesChange={handleValueChanges}
    >
      <Row gutter={[16, 0]}>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            name="paidPremiumBasedCommission"
            label="Paid/Premium Template"
            rules={[
              { required: true, message: 'Please enter Value' },
              { validator: validateNumber },
            ]}
          >
            <Input
              placeholder="Add Value"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            name="subscriptionBasedCommission"
            label="Template for Subscribed User"
            rules={[
              { required: true, message: 'Please enter Value' },
              { validator: validateNumber },
            ]}
          >
            <Input
              placeholder="Add Value"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            name="referral"
            label="Service Referrals"
            rules={[
              { required: true, message: 'Please enter Value' },
              { validator: validateNumber },
            ]}
          >
            <Input
              placeholder="Add Value"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            name="service"
            label="Service Fulfillment"
            rules={[
              { required: true, message: 'Please enter Value' },
              { validator: validateNumber },
            ]}
          >
            <Input
              placeholder="Add Value"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12} md={12} lg={6}>
          <Form.Item
            label="Consultation Call"
            name={['consultationCall', 'value']}
            rules={[
              { required: true, message: 'Please enter Value' },
              { validator: validateNumber },
            ]}
          >
            <Input
              placeholder="Add Value"
              addonAfter={<PercentageOutlined />}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Space size={12}>
              <Button htmlType="reset" disabled={disableButton}>
                {UI_LABELS.CANCEL}
              </Button>
              <Button type="primary" htmlType="submit" disabled={disableButton}>
                {UI_LABELS.SAVE}
              </Button>
            </Space>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default PaymentDistributionForm;
