import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS = gql`
  query NotificationsAdmin(
    $filter: ListNotificationsFilter
    $sort: [ListNotificationsSort!]
  ) {
    notificationsAdmin(filter: $filter, sort: $sort) {
      count
      data {
        category
        id
        description
        createdAt
        updatedAt
        issuePeriod
        pdfUrl
        region
        slug
        status
        title
        startDate
        endDate
      }
    }
  }
`;

export const GET_NOTIFICATION = gql`
  query NotificationAdmin($where: NotificationIdWhereUniqueInput!) {
    notificationAdmin(where: $where) {
      category
      createdAt
      description
      id
      issuePeriod
      pdfUrl
      region
      slug
      status
      title
      startDate
      endDate
    }
  }
`;

export const NOTIFICATION_PDF_SIGNED_URL = gql`
  query GetNotificationPdfFileUploadSignedUrl(
    $data: GetPdfFileUploadSignedUrlInput!
  ) {
    getNotificationPdfFileUploadSignedUrl(data: $data) {
      key
      signedUrl
    }
  }
`;
